import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//iconos
import Dashboard from '@material-ui/icons/Dashboard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InsertChart from '@material-ui/icons/InsertChart';
import Settings from '@material-ui/icons/Settings';
import Notifications from '@material-ui/icons/Notifications';
import Modal from '@material-ui/core/Modal';
//Taps
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { apiPOST } from '../../../../services/service_base';
import { LibraryBooks } from '@material-ui/icons';

function getModalStyle() {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }

const VerticalTabs = withStyles(theme => ({
    flexContainer: {
      flexDirection: "column",
      maxHeight: 350,
      overflow: 'auto',
      '& button':{
        textAlign: 'left',
        minHeight: 50,
        maxWidth: 230,
        '& span':{
          flexDirection: 'row',
          justifyContent: 'flex-start',
        },
      },
    },
    indicator: {
      display: "none"
    }
  }))(Tabs);
  
  const MyTab = withStyles(theme => ({
    selected: {
      color: theme.palette.primary.main,
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      padding: '0 5px',
      background: '#ededed',
    }
  }))(Tab);

const PERMISSION_ACERVO = `{"name":"Catálogo de producción","attr":"chkProductionGeneralView"},{"name":"Catálogo completo","attr":"chkSalesGeneralView"},{"name":"Marcar como favorito","attr":"chkFavorite"}`;
//AHDA 30/04/2019
//Added permission for view scripts
const PERMISSION_WORKDETAIL =`{"name":"Ver obra bloqueada", "attr": "chkViewWorkBlock"},{"name":"Solicitar Material","attr":"chkApplyForMaterial"},{"name":"Compartir material","attr":"chkShareMaterial"},{"name": "Ver Libretos", "attr":"chkViewScripts"},{"name":"Ver Sinopsis","attr":"chkViewSinopsis"},{"name":"Ver Personajes","attr":"chkViewPersonaje"},{"name":"Ver Evaluación","attr":"chkViewEval"},{"name":"Ver Material","attr":"chkViewMaterial"},{"name":"Ver Vigencias","attr":"chkViewVigencia"},{"name":"Ver Equipos Literarios","attr":"chkViewEquiposLit"},{"name":"Descargar ficha técnica","attr":"chkDownloadFT"},{"name":"Ver Más Información","attr":"chkViewMoreInfo"}`;
const PERMISSION_PROFILE =`{"name":"Ver","attr":"chkViewProfile"}`;
const PERMISSION_REPORTS =`{"name":"Bitácora de movimientos","attr":"chkShowMovementHistory"},{"name":"Composición Acervo Literario","attr":"chkCompAcervoLiterario"},{"name":"Obras más consultadas","attr":"chkWorkMoreView"},{"name":"Favoritos","attr":"chkViewFavorite"},{"name":"Prospectos","attr":"chkViewProspect"},{"name":"Proyectos","attr":"chkViewProject"},{"name":"Control de materiales","attr":"chkViewControlMat"},{"name":"Usuarios","attr":"chkViewUsers"}`;
const PERMISSION_CATALOG =`{"name":"Ver","attr":"chkViewCatalog"},{"name":"Editar","attr":"chkEditCatalog"},{"name":"Crear","attr":"chkNewCatalog"},{"name":"Eliminar","attr":"chkDeleteCatalog"}`;
const PERMISSION_WORKADMIN =`{"name":"Ver","attr":"chkViewWorkAdmin"},{"name":"Crear","attr":"chkNewWorkAdmin"},{"name":"Editar","attr":"chkEditWorkAdmin"},{"name":"Sincronizar Dashboard","attr":"chkSyncWorkAdmin"},{"name":"Sinopsis","attr":"chkViewAdminSinopsis"},{"name":"Personaje","attr":"chkViewAdminPersonaje"},{"name":"Evaluación","attr":"chkEvaluaWorkAdmin"},{"name":"Material","attr":"chkMaterial"},{"name":"Vigencia","attr":"chkVigencia"},{"name":"Equipos Literarios","attr":"chkEquipoLit"},{"name":"Más Información","attr":"chkMoreInfo"},{"name":"Marcar como prospecto","attr":"chkProspect"},{"name":"Asignar como proyecto","attr":"chkAssignProject"},{"name":"Desbloquear obra","attr":"chkUnlockWork"},{"name":"Finalizar Obra","attr":"chkEndWork"}`;
const PERMISSION_USERADMIN =`{"name":"Ver","attr":"chkViewUser"},{"name":"Editar","attr":"chkEditUser"},{"name":"Bloquear","attr":"chkBlockUser"},{"name":"Desbloquear","attr":"chkUnlockUser"}`;
const PERMISSION_PROFILEADMIN =`{"name":"Ver","attr":"chkViewProfileAdmin"},{"name":"Editar","attr":"chkEditProfileAdmin"},{"name":"Crear","attr":"chkCreateProfileAdmin"},{"name":"Eliminar","attr":"chkDeleteProfileAdmin"},{"name":"Ver Privilegios","attr":"chkShowPrivileges"},{"name":"Editar Privilegios","attr":"chkEditPrivileges"}`;
//AHDA 04/04/2018
//Added permission for picking scripts
const PERMISSION_NOTIFICATION =`{"name":"Ver","attr":"chkViewNotification"},{"name":"Eliminar","attr":"chkEditNotification"},{"name":"Elegir Libretos","attr":"chkPickScripts"}`;
const PERMISSION_CONFNOTIFICATION =`{"name":"Ver Admin","attr":"chkViewConfig"},{"name":"Editar Admin","attr":"chkEditConfig"},{"name":"Vista Usuario","attr":"chkViewNoti"}`;

/* isanchez 01.03.2019 inicio */
const PERMISSION_PARAMETERS = `{"name":"Ver","attr":"chkViewParameter"},{"name":"Editar","attr":"chkEditParameter"}`;
const CONFIG_PARAMETERS = `{"chkViewParameter": false, "chkEditParameter":false}`;
/* isanchez 01.03.2019 fin */

const CONFIG_ACERVO = `{"chkProductionGeneralView":false,"chkSalesGeneralView":false,"chkFavorite":false}`;
//AHDA 29/04/2019
//Added permission for view Scripts 
const CONFIG_WORKDETAIL =`{"chkViewWorkBlock":false,"chkApplyForMaterial":false,"chkShareMaterial":false,"chkViewScripts": false, "chkViewSinopsis": false, "chkViewPersonaje": false, "chkViewEval":false, "chkViewMaterial":false, "chkViewVigencia":false, "chkViewEquiposLit": false, "chkDownloadFT": false, "chkViewMoreInfo":false}`;
const CONFIG_PROFILE =`{"chkViewProfile":false}`;
const CONFIG_REPORTS =`{"chkShowMovementHistory":false,"chkCompAcervoLiterario":false,"chkWorkMoreView":false,"chkViewFavorite":false,"chkViewProspect":false,"chkViewProject":false,"chkViewControlMat":false,"chkViewUsers":false}`;
const CONFIG_CATALOG =`{"chkViewCatalog":false,"chkEditCatalog":false,"chkNewCatalog":false,"chkDeleteCatalog":false}`;
const CONFIG_WORKADMIN =`{"chkViewWorkAdmin":false,"chkEditWorkAdmin":false,"chkEvaluaWorkAdmin":false,"chkMaterial":false,"chkVigencia":false,"chkEquipoLit":false,"chkMoreInfo":false,"chkNewWorkAdmin":false,"chkSyncWorkAdmin":false, "chkViewAdminSinopsis": false, "chkViewAdminPersonaje": false,"chkProspect":false,"chkAssignProject":false,"chkUnlockWork":false,"chkEndWork":false }`;
const CONFIG_USERADMIN =`{"chkViewUser":false,"chkEditUser":false,"chkBlockUser":false,"chkUnlockUser":false}`;
const CONFIG_PROFILEADMIN =`{"chkViewProfileAdmin":false,"chkEditProfileAdmin":false,"chkCreateProfileAdmin":false,"chkDeleteProfileAdmin":false,"chkShowPrivileges":false,"chkEditPrivileges":false}`;
//AHDA 04/04/2018
//Added permission for picking scripts
const CONFIG_NOTIFICATION =`{"chkViewNotification":false,"chkEditNotification":false,"chkPickScripts":false}`;
const CONFIG_CONFNOTIFICATION =`{"chkViewConfig":false,"chkEditConfig":false,"chkViewNoti":false}`;

  function TabContainer(props) {
    return (
      <Typography component="div" style={{ paddingLeft: 8 * 2 }}>
        {props.children}
      </Typography>
    );
  }
  // fin Taps

class ModalPermissions extends React.Component {

  constructor(props) {
    super(props);
    this.handleShowPermissions = this.handleShowPermissions.bind(this);
    this.handleChangeChecked = this.handleChangeChecked.bind(this);
  }

  state = {
    activeIndex: 0,
    checkedAll: false,
    chkProductionGeneralView: false,
    chkSalesGeneralView: false,
    chkFavorite: false,
    chkProspect: false,
    chkApplyForMaterial:false,
    chkAssignProject:false,
    chkViewScripts: false, 
    chkViewSinopsis: false, 
    chkViewPersonaje: false, 
    chkViewEval:false, 
    chkViewMaterial:false, 
    chkViewVigencia:false, 
    chkViewEquiposLit: false,
    chkDownloadFT: false,
    chkViewMoreInfo:false,
    chkBlockUser:false,
    chkCompAcervoLiterario:false,
    chkCreateProfileAdmin:false,
    chkDeleteCatalog:false,
    chkDeleteProfileAdmin:false,
    chkEditCatalog:false,
    chkEditNotification:false,
    chkEditPrivileges:false,
    chkEditProfileAdmin:false,
    chkEditUser:false,
    chkEditWorkAdmin:false,
    chkEndWork:false,
    chkEvaluaWorkAdmin:false,
    chkMaterial:false,
    chkVigencia:false,
    chkEquipoLit:false,
    chkMoreInfo:false,
    chkNewCatalog:false,
    chkNewWorkAdmin:false,
    chkSyncWorkAdmin:false,
    chkViewAdminSinopsis: false, 
    chkViewAdminPersonaje: false,
    chkProductionGeneralView:false,
    chkProspect:false,
    chkSalesGeneralView:false,
    chkShareMaterial:false,
    chkShowMovementHistory:false,
    chkViewControlMat:false,
    chkViewUsers:false,
    chkShowPrivileges:false,
    chkUnlockUser:false,
    chkUnlockWork:false,
    chkVer:false,
    chkViewCatalog:false,
    chkViewFavorite:false,
    chkViewNotification:false,
    chkPickScripts: false,
    chkViewProfile:false,
    chkViewProfileAdmin:false,
    chkViewProject:false,
    chkViewProspect:false,
    chkViewUser:false,
    chkViewWorkAdmin:false,
    chkViewWorkBlock:false,
    chkWorkMoreView:false,
    chkViewConfig:false,
    chkEditConfig:false,
    chkViewParameter: false,
    chkEditParameter:false,
    chkViewNoti : false,
    
    optionsMenu:[],
    menuSelected:0,
    newConfiguration:[],
    profileId :0,
    onlyView : false,
    isl:false,
    showConfiguration:"",
    menuAcervo:[],
    arrayPermissions:[]
  }
 
  componentWillReceiveProps(newProps){
    if(newProps.openModalPermission){
      apiPOST("/roles/getmenuaction",{
        rolId : newProps.profileSelected.rol_id
      }).then(response => {
        
        this.setState({
          arrayPermissions:[],
          menuAcervo:response.data,
          optionsMenu : newProps.optionsMenu,
          profileId:newProps.profileSelected.rol_id,
          onlyView:newProps.onlyView
        }); 
        
        //AHDA 03/06/2019: show first menu when open
        if(newProps.optionsMenu.length > 0)
          this.handleShowPermissions(newProps.optionsMenu[0].menu_id); 
        
      }).catch(err => console.log("error menu action", err));
      
    }else{
      this._handleClearCloseModal();
    }
  }

  _handleClearCloseModal = () =>{
    this.setState({
      menuSelected:0,
      showConfiguration:"",
      arrayPermissions:[],
      activeIndex: 0,
      checkedAll: false,
      chkProductionGeneralView: false,
      chkSalesGeneralView: false,
      chkFavorite: false,
      chkProspect: false,
      chkApplyForMaterial:false,
      chkAssignProject:false,
      chkViewScripts: false, 
      chkViewSinopsis: false, 
      chkViewPersonaje: false, 
      chkViewEval:false, 
      chkViewMaterial:false, 
      chkViewVigencia:false, 
      chkViewEquiposLit: false,
      chkDownloadFT: false,
      chkViewMoreInfo:false,
      chkBlockUser:false,
      chkCompAcervoLiterario:false,
      chkCreateProfileAdmin:false,
      chkDeleteCatalog:false,
      chkDeleteProfileAdmin:false,
      chkEditCatalog:false,
      chkEditNotification:false,
      chkEditPrivileges:false,
      chkEditProfileAdmin:false,
      chkEditUser:false,
      chkEditWorkAdmin:false,
      chkEndWork:false,
      chkEvaluaWorkAdmin:false,
      chkMaterial:false,
      chkVigencia:false,
      chkEquipoLit:false,
      chkMoreInfo:false,
      chkNewCatalog:false,
      chkNewWorkAdmin:false,
      chkSyncWorkAdmin:false,
      chkViewAdminSinopsis: false, 
      chkViewAdminPersonaje: false,
      chkProductionGeneralView:false,
      chkProspect:false,
      chkSalesGeneralView:false,
      chkShareMaterial:false,
      chkShowMovementHistory:false,
      chkViewControlMat:false,
      chkViewUsers:false,
      chkShowPrivileges:false,
      chkUnlockUser:false,
      chkUnlockWork:false,
      chkVer:false,
      chkViewCatalog:false,
      chkViewFavorite:false,
      chkViewNotification:false,
      chkPickScripts: false,
      chkViewProfile:false,
      chkViewProfileAdmin:false,
      chkViewProject:false,
      chkViewProspect:false,
      chkViewUser:false,
      chkViewWorkAdmin:false,
      chkViewWorkBlock:false,
      chkWorkMoreView:false,
      chkViewConfig:false,
      chkEditConfig:false,
      chkViewParameter: false,
      chkEditParameter:false,
      chkViewNoti : false,
    })
  }

  handleChange = (_, activeIndex) => this.setState({ activeIndex });

  handleChangeChecked = name => event => {
    
    if(!this.state.onlyView) {

      let newConfiguration = new Array();
      let grantId = 0;
      this.state.arrayPermissions.forEach(element => {
        if(element.menu_id != this.state.menuSelected){
          grantId = element.grant_id;
          newConfiguration.push(element);
        }
      });
      
      let config={};

      let newMenu ={
        grant_id:grantId,
        menu_id : this.state.menuSelected,
        grant_configuration:""
      }
      
      if(name === "checkedAll"){
      
        switch (this.state.menuSelected) {
          case 1:              
            config = {
              chkProductionGeneralView: event.target.checked,
              chkSalesGeneralView: event.target.checked,
              chkFavorite: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 2:        
            config = {
              chkShowMovementHistory: event.target.checked,
              chkCompAcervoLiterario: event.target.checked,
              chkWorkMoreView: event.target.checked,
              chkViewFavorite: event.target.checked,
              chkViewProspect: event.target.checked,
              chkViewProject: event.target.checked,
              chkViewControlMat: event.target.checked,
              chkViewUsers: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 3:    
            config = {
              chkViewWorkAdmin: event.target.checked,
              chkEditWorkAdmin: event.target.checked,
              chkEvaluaWorkAdmin: event.target.checked,
              chkMaterial: event.target.checked,
              chkVigencia: event.target.checked,
              chkEquipoLit: event.target.checked,
              chkMoreInfo: event.target.checked,
              chkNewWorkAdmin: event.target.checked,
              chkSyncWorkAdmin: event.target.checked,
              chkViewAdminSinopsis: event.target.checked, 
              chkViewAdminPersonaje: event.target.checked,
              chkProspect: event.target.checked,
              chkAssignProject: event.target.checked,
              chkUnlockWork: event.target.checked,
              chkEndWork:  event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 4:     
            config = {
              chkViewWorkBlock: event.target.checked,
              chkApplyForMaterial: event.target.checked,
              chkShareMaterial: event.target.checked,
              chkViewScripts: event.target.checked,
              chkViewSinopsis: event.target.checked, 
              chkViewPersonaje: event.target.checked, 
              chkViewEval:event.target.checked, 
              chkViewMaterial:event.target.checked,  
              chkViewVigencia:event.target.checked, 
              chkViewEquiposLit: event.target.checked,
              chkDownloadFT: event.target.checked,
              chkViewMoreInfo: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 5:              
            config = {
              chkViewProfile: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 6:     
            config = {
              chkViewCatalog: event.target.checked,
              chkEditCatalog: event.target.checked,
              chkNewCatalog: event.target.checked,
              chkDeleteCatalog: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 7:      
            config = {
              chkViewUser: event.target.checked,
              chkEditUser: event.target.checked,
              chkBlockUser: event.target.checked,
              chkUnlockUser: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 8:         
            config = {
              chkViewProfileAdmin: event.target.checked,
              chkEditProfileAdmin: event.target.checked,
              chkCreateProfileAdmin: event.target.checked,
              chkDeleteProfileAdmin: event.target.checked,
              chkShowPrivileges: event.target.checked,
              chkEditPrivileges: event.target.checked,
              [name]: event.target.checked
            }
            break;
            //AHDA 04/04/2019
            //Added break and chkPickScripts
            case 9:         
            /*config = {
              chkViewNotification: event.target.checked,
              chkEditNotification: event.target.checked,
              chkPickScripts: event.target.checked,
              [name]: event.target.checked
            }*/
            break;
            case 10:         
            config = {
              chkViewConfig: event.target.checked,
              chkEditConfig: event.target.checked,
              chkViewNoti : event.target.checked,
              [name]: event.target.checked
            }
            break;
            /*isanchez 01.03.2019 inicio*/
            case 18:         
            config = {
              chkViewParameter: event.target.checked,
              chkEditParameter: event.target.checked,
              [name]: event.target.checked
            }
            break;
            /*isanchez 01.03.2019 fin*/

            
        }

        this.setState(config);
        newMenu.grant_configuration = JSON.stringify(config);       
        newConfiguration.push(newMenu); 
        this.setState({arrayPermissions: newConfiguration});

      }else{      
        this.setState({ [name]: event.target.checked }, () =>{
          switch (this.state.menuSelected) {
            case 1:              
              config = {
                chkProductionGeneralView: this.state.chkProductionGeneralView,
                chkSalesGeneralView: this.state.chkSalesGeneralView,
                chkFavorite: this.state.chkFavorite
              }
              break;
              case 2:        
              config = {
                chkShowMovementHistory: this.state.chkShowMovementHistory,
                chkCompAcervoLiterario: this.state.chkCompAcervoLiterario,
                chkWorkMoreView: this.state.chkWorkMoreView,
                chkViewFavorite: this.state.chkViewFavorite,
                chkViewProspect: this.state.chkViewProspect,
                chkViewProject: this.state.chkViewProject,
                chkViewControlMat: this.state.chkViewControlMat,
                chkViewUsers: this.state.chkViewUsers
              }
              break;
              case 3:    
              config = {
                chkViewWorkAdmin: this.state.chkViewWorkAdmin,
                chkEditWorkAdmin: this.state.chkEditWorkAdmin,
                chkEvaluaWorkAdmin: this.state.chkEvaluaWorkAdmin,
                chkMaterial: this.state.chkMaterial,
                chkVigencia: this.state.chkVigencia,
                chkEquipoLit: this.state.chkEquipoLit,
                chkMoreInfo: this.state.chkMoreInfo,
                chkNewWorkAdmin: this.state.chkNewWorkAdmin,
                chkSyncWorkAdmin: this.state.chkSyncWorkAdmin,
                chkViewAdminSinopsis: this.state.chkViewAdminSinopsis, 
                chkViewAdminPersonaje: this.state.chkViewAdminPersonaje,
                chkProspect: this.state.chkProspect,
                chkAssignProject: this.state.chkAssignProject,
                chkUnlockWork: this.state.chkUnlockWork,
                chkEndWork: this.state.chkEndWork
              }
              break;
              case 4:     
              config = {
                chkViewWorkBlock: this.state.chkViewWorkBlock,
                chkApplyForMaterial: this.state.chkApplyForMaterial,
                chkShareMaterial: this.state.chkShareMaterial, 
                chkViewScripts: this.state.chkViewScripts,
                chkViewSinopsis: this.state.chkViewSinopsis, 
                chkViewPersonaje: this.state.chkViewPersonaje, 
                chkViewEval:this.state.chkViewEval, 
                chkViewMaterial:this.state.chkViewMaterial, 
                chkViewVigencia:this.state.chkViewVigencia, 
                chkViewEquiposLit: this.state.chkViewEquiposLit,
                chkDownloadFT: this.state.chkDownloadFT,
                chkViewMoreInfo: this.state.chkViewMoreInfo
              }
              break;
              case 5:              
              config = {
                chkViewProfile: this.state.chkViewProfile
              }
              break;
              case 6:     
              config = {
                chkViewCatalog: this.state.chkViewCatalog,
                chkEditCatalog: this.state.chkEditCatalog,
                chkNewCatalog: this.state.chkNewCatalog,
                chkDeleteCatalog: this.state.chkDeleteCatalog
              }
              break;
              case 7:      
              config = {
                chkViewUser: this.state.chkViewUser,
                chkEditUser: this.state.chkEditUser,
                chkBlockUser: this.state.chkBlockUser,
                chkUnlockUser: this.state.chkUnlockUser
              }
              break;
              case 8:         
              config = {
                chkViewProfileAdmin: this.state.chkViewProfileAdmin,
                chkEditProfileAdmin: this.state.chkEditProfileAdmin,
                chkCreateProfileAdmin: this.state.chkCreateProfileAdmin,
                chkDeleteProfileAdmin: this.state.chkDeleteProfileAdmin,
                chkShowPrivileges: this.state.chkShowPrivileges,
                chkEditPrivileges: this.state.chkEditPrivileges
              }
              break;
              //AHDA 04/04/2019
              //Added break and chkPickScripts
              case 9:         
              config = {
                /*chkViewNotification: this.state.chkViewNotification,
                chkEditNotification: this.state.chkEditNotification*/
              }
              break;
              case 10:         
              config = {
                chkViewConfig: this.state.chkViewConfig,
                chkEditConfig: this.state.chkEditConfig,
                chkViewNoti: this.state.chkViewNoti
              }
              break;
               /*isanchez 01.03.2019 inicio*/
              case 18:         
              config = {
                chkViewParameter: this.state.chkViewParameter,
                chkEditParameter: this.state.chkEditParameter
              }
              break;
              /*isanchez 01.03.2019 fin*/
          }
          newMenu.grant_configuration = JSON.stringify(config);       
          newConfiguration.push(newMenu); 
          this.setState({arrayPermissions: newConfiguration});
        });
           
      }  
        
    }   
  };

  handleShowPermissions = (menu_id) =>{     
    this.setState({
      menuSelected: menu_id,
      showConfiguration : this._getPermission(menu_id) 
    }, () => {
      this.movePermissions(menu_id, this._getConfigMenu(menu_id));
    });
  }

  movePermissions= (menu_id,jsonConfiguration) =>{
    let data = this.state.menuAcervo.filter(x => x.menu_id == menu_id)[0];

    //this.setState({ menuSelected : menu_id , checkedAll:false, chkEditar:false, chkPublicar:false, chkVer:false, chkEliminar:false});

    if(data != undefined){

      let modify = this.state.arrayPermissions.filter(x => x.menu_id === data.menu_id)[0];

      if(modify === undefined){
        this.setState(JSON.parse(data.grant_configuration));
      }else{
        this.setState(JSON.parse(modify.grant_configuration));
      }

    }else{
      let menu = this.state.arrayPermissions.filter(m => m.menu_id == menu_id)[0];
       
      if(menu !== undefined){
        this.setState(JSON.parse(menu.grant_configuration));        
      }else{
        
        let newMenu = {
          grant_configuration:jsonConfiguration,
          menu_id:menu_id,
          grant_id:0
        }

        this.setState(JSON.parse(newMenu.grant_configuration));
        let auxiliar = this.state.arrayPermissions;
        auxiliar.push(newMenu);
        this.setState({arrayPermissions :  auxiliar});

      }
    }
  }

  _getPermission = (menu) => {
    switch (menu) {
      case 1: return PERMISSION_ACERVO; break;
      case 2: return PERMISSION_REPORTS; break;
      case 3: return PERMISSION_WORKADMIN; break;
      case 4: return PERMISSION_WORKDETAIL; break;
      case 5: return PERMISSION_PROFILE; break;
      case 6: return PERMISSION_CATALOG; break;
      case 7: return PERMISSION_USERADMIN; break;
      case 8: return PERMISSION_PROFILEADMIN; break;
      //case 9: return PERMISSION_NOTIFICATION; break;
      case 10: return PERMISSION_CONFNOTIFICATION; break;
      case 18: return PERMISSION_PARAMETERS; break;
    }
  }

  _getConfigMenu = (menu) => {
    switch (menu) {
      case 1: return CONFIG_ACERVO; break;
      case 2: return CONFIG_REPORTS; break;
      case 3: return CONFIG_WORKADMIN; break;
      case 4: return CONFIG_WORKDETAIL; break;
      case 5: return CONFIG_PROFILE; break;
      case 6: return CONFIG_CATALOG; break;
      case 7: return CONFIG_USERADMIN; break;
      case 8: return CONFIG_PROFILEADMIN; break;
      //case 9: return CONFIG_NOTIFICATION; break;
      case 10: return CONFIG_CONFNOTIFICATION; break;
      case 18: return CONFIG_PARAMETERS; break;
    }
  }
  
  render() {    
    const {
        profileSelected,
        handleCloseModal,
        openModalPermission,
        classes,
        saveChanges
    } = this.props;    

    // Se agrega para mostrar Tap seleccionado CH 21/06/2019
    const { activeIndex } = this.state;
    
    if(profileSelected==null){
        return false;
    }
    
    return (
      <div>
       <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModalPermission}
          onClose={handleCloseModal}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div >
              <Typography className={classes.paperHeader}>
                Privilegios / {profileSelected.name}
              </Typography>
            </div>
            <div className={classes.paperBody}>
              <div
                style={{
                  display: "flex"
                }}
              >              
              {/* CH 21/06/2019 Se cambia overflow de tabs y se agrega value */}
                <VerticalTabs className={classes.overflowVisible} value={activeIndex} onChange={this.handleChange}>
                  {
                    this.state.optionsMenu.map((item, index) =>{
                      //Temporal correction, remove when merge
                      //AHDA 25/04/2019
                      if(item.menu_id === 9)
                        return null
                      else
                        return(
                          <MyTab 
                            key={item.menu_id}
                            onClick={() => this.handleShowPermissions(item.menu_id)} 
                            label={item.menu_name} 
                            icon={
                              index === 0 ? <Dashboard /> : //Acervo
                              index === 1 ? <InsertChart /> : //Reportes
                              index === 2 ? <Settings /> : //Admon de obras
                              index === 3 ? <Dashboard /> : // Detalle de una obra
                              index === 4 ? <AccountCircle /> : //Mi perfil
                              index === 5 ? <LibraryBooks /> : //Catálogos
                              index === 6 ? <Settings /> : //Admin de usuarios
                              index === 7 ? <Settings /> :
                              index === 8 ? <Notifications /> :
                              <Settings />
                            }/>
                        );
                    })
                  }

                </VerticalTabs>

                {/* {activeIndex === 0 && */}
                <TabContainer>
                  {
                    this.state.showConfiguration === "" ? '':
                    <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedAll}
                          onChange={this.handleChangeChecked('checkedAll')}
                          value="checkedAll"
                          color="primary"
                        />
                      }
                      label="Seleccionar Todo"
                    />
                  </div>
                  }
                  
                  <div>
                    <FormGroup>
                        {
                          this.state.showConfiguration === "" ? '' :
                          JSON.parse(`[${this.state.showConfiguration}]`).map((item, index)=>{
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                control={
                                  <Switch
                                    checked={this.state[`${item.attr}`]}
                                    onChange={this.handleChangeChecked(item.attr)}
                                    value={item.attr}
                                    color="primary"
                                  /> 
                                }
                                label={item.name}
                              />
                            )
                          })
                        }
                    </FormGroup>
                  </div>
                </TabContainer>
              </div>
            </div>
            <div className={classes.paperFooter}>
              <Button onClick={()=>{
                handleCloseModal(false);
                this._handleClearCloseModal();
              }} variant="contained" color="default" type="submit">
                Cerrar
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={this.state.onlyView} onClick={()=> saveChanges(this.state.arrayPermissions)} >
                Guardar
              </Button>
            </div>
          </div>
        </Modal> 

      </div>
    );
  }
}

ModalPermissions.propTypes = {
  classes: PropTypes.object,
  openModalPermission:PropTypes.bool,
  profileSelected: PropTypes.object,
  handleCloseModal: PropTypes.func,
  optionsMenu:PropTypes.array,
  onlyView : PropTypes.bool,
  saveChanges: PropTypes.func,
};

export default ModalPermissions;