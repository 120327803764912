import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
//AHDA 12/04/2019
//Added ddependency withRouter 
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withRouter } from 'react-router-dom';
//import Button from '@material-ui/core/Button';
import logo_televisa from '../../../../static/images/logo-televisa.svg';
import './Login.css';

//Styles 
import { styles } from './styles';

//Components
import LoginForm from './../../../components/Forms/LoginForm';

//Language
import langstr from '../../../language/lang';
//import Language from '@material-ui/icons/Language';

//Services 
import { checkHasSession } from '../../../services/cognito_service';

class Login extends React.Component {

  //AHDA 12/04/2019
  //removed constructor and added redirect state 
  /*state = {
    //Language
    lang: langstr.getLanguage(), 
    redirect: undefined
  }
  
  //AHDA 12/04/2019
  UNSAFE_componentWillMount(){
    //checkHasSession(); 
  }*/

  //AHDA 15/05/2019
  constructor(props) {
    checkHasSession();

    super(props);
    this.state = {
      //Language
      lang: langstr.getLanguage(),
      redirect: undefined
    }
  }

  //Language change 
  handleLanguageChange = () => {
    const currentLang = langstr.getLanguage();
    switch (currentLang) {
      case 'en':
        langstr.setLanguage('es');
        break;
      case 'es':
        langstr.setLanguage('en');
        break;
      default:
        break;
    }

    this.setState({ lang: langstr.getLanguage() });
  }

  //AHDA 12/04/2019
  //Added componentDidMount and functionality for redirect param 
  // ----------------- Lifecycle 
  componentDidMount() {
    if (this.props.location.search !== "") {
      let url = this.props.location.search.split('=')[1];
      url = decodeURIComponent(url);
      this.setState({ redirect: url });
    } else {
      this.setState({ redirect: undefined });
    }
  }

  render() {
    const title = brand.name + ' - Iniciar Sesión';
    const description = brand.desc;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <Grid container spacing={24} alignItems="center" direction="row" justify="center">
            <Grid item container justify="center" spacing={0} className={classes.loginWrap}>
              <Hidden smDown>
                <Grid item md={6} className={classes.welcomeWrap}>
                  {/* Welcome Login */}
                  <div className={classes.welcome}>
                    <img src={logo_televisa} alt={brand.name} className="centrarLogo" />
                  </div>
                </Grid>
              </Hidden>
              <Grid item md={6} sm={8} xs={11}>
                {/*AHDA 12/04/2019: Added redirect prop*/}
                <LoginForm redirect={this.state.redirect} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/*Languge button
        <Button className="fixedBtn" variant="extendedFab" color="secondary" onClick={this.handleLanguageChange}>
          <Language/> {this.state.lang}
        </Button>*/}
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

//AHDA 12/04/2019
//Added withRouter
export default withRouter(withStyles(styles)(Login));
