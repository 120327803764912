import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TreeTable from '../../../../components/Tables/TreeTable';
import styles from './../reportes-jss'
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

import IconButton from '@material-ui/core/IconButton';

//Filtros
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Loader from '../../../../components/Loader/Loader';

const branch = 'favoritesTree';

class FavoritesReport extends Component {

  state = {
    //filters
    idUser: 0,
    tituloObra: '',
    //Table
    data: { head: [], body: [] },
    //Catalogos
    users: [],
    works: [],
    results: 0,
    isLoading: true,
    csvData: []
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ tituloObra: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ tituloObra: changes.inputValue })
    }
  }

  //AHDA 18/02/2019
  handleClearFilters = () => {
    this.setState({
      idUser: 0,
      tituloObra: '',
      isLoading: true
    })

    //Carga inicial
    const request = {
      idUser: 0,
      title: "0"
    }
    this.handleFetch(request);
  }

  //Llamada de busqueda
  searchs = () => {
    this.setState({ isLoading: true })
    const request = {
      idUser: this.state.idUser,
      title: this.state.tituloObra === "" ? "0" : this.state.tituloObra === "TODOS" ? "0" : this.state.tituloObra
    }

    //AHDA - 18/02/2019
    this.handleFetch(request);
  }

  componentDidMount() {
    //Get language context and set titles
    const { lang } = this.context;
    //AHDA 04/06/2019: changed label lang.report.producer for lang.reports.user
    this.setState({
      data: {
        ...this.state.data,
        head: [
          { label: '', }, { label: lang.reports.title }, { label: lang.reports.user }, { label: lang.reports.favorites }
        ]
      }
    });

    //Catalogo productores
    apiPOST('/catalogue/get', { idCatalogo: 35 }).then(
      response => {
        if (response.status === 200) {
          this.setState({ users: response.data })
        }
      }
    );

    //Catalogo obras
    apiPOST('/catalogue/get', { idCatalogo: 16 }).then(
      response => {
        if (response.status === 200) {
          this.setState({ works: response.data })
        }
      }
    );

    //Carga inicial
    const request = {
      idUser: 0,
      title: "0"
    }

    //AHDA - 18/02/2019
    this.handleFetch(request);
  }

  //AHDA - 18/02/2019
  handleFetch = (request) => {
    apiPOST('/reports/rankingFavorites', request).then(
      response => {
        if (response.status === 200) {
          const result = [];
          const dataCsv = [["Id", "Título de la Obra", "Productor", "Favoritos"]];
          for (const x in response.data) {
            let obj = {};
            obj.id = response.data[x].id;
            obj.titulo = response.data[x].work_title;
            obj.productor = response.data[x].productor;
            obj.consultas = response.data[x].total;

            const producers = [];
            for (const y in response.data[x].child) {
              let producer = {};
              producer.id = x + "_" + y;
              producer.titulo = response.data[x].work_title;
              producer.productor = response.data[x].child[y].name;
              producer.consultas = "1";
              producers.push(producer);

              const rowCsv = [];
              //AHDA 09/05/2019
              //Bug, GAP in csv
              //rowCsv.push(" "); 
              rowCsv.push(x + "_" + y);
              rowCsv.push(response.data[x].work_title);
              rowCsv.push(response.data[x].child[y].name);
              rowCsv.push("1");
              dataCsv.push(rowCsv);
            }
            obj.child = producers;
            result.push(obj);
          }
          //this.state.data.body = result;
          this.setState({ data: { ...this.state.data, body: result }, isLoading: false, results: response.data.length, csvData: dataCsv })
        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }
    ).catch(
      error => {
        this.setState({ openError: true, isLoading: false });
      }
    );
  }

  //Download csv
  handleDownload = (data) => {

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'acervo',
      screen: 'reporteFavoritos'
    }
    apiPOST('/audit/add', body);

    var csv_content = data,
      download = document.createElement("a"),
      blob = new Blob(["\ufeff", csv_content.join('\n')], { type: 'application/vnd.ms-excel' });
    document.body.appendChild(download); //AHDA 23/05/2019
    download.href = window.URL.createObjectURL(blob);
    download.download = "Favoritos.csv";
    download.click();

  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start">
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.idUser} onChange={this.handleChange} name="idUser" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.users.map((user, idx) =>
                              (
                                <MenuItem value={user.value} key={`producer${idx}`}>{user.data}</MenuItem>
                              ))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.user}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.positionRelative}>
                        <Downshift selectedItem={this.state.tituloObra} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                              <div className={classes.formControl}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  helperText: lang.reports.title,
                                  InputProps: getInputProps({
                                    placeholder: lang.reports.title,
                                    id: 'titleWork',
                                    name: 'titleWork',
                                  }),
                                })}
                                {isOpen ? (
                                  <Paper className={classNames(classes.paper, classes.list)} square>
                                    {getSuggestions(inputValue, this.state.works).map((suggestion, index) =>
                                      renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion.label }),
                                        highlightedIndex,
                                        selectedItem,
                                      }),
                                    )}
                                  </Paper>
                                ) : null}
                              </div>
                            )}
                        </Downshift>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.searchs.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                          Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {
                  this.state.isLoading ?
                    <Loader text={"Cargando"} /> :
                    <Paper className={classes.rootTable} ref={el => (this.componentRef = el)}>
                      <div className={classes.headerTable}>
                        <Typography variant="title">{lang.reports.favorites} ({this.state.results} {lang.reports.results})</Typography>
                        <div>
                          {/* <IconButton aria-label="Imprimir" onClick={this.handleDownload}> 
                      <CSVLink data={this.state.csvData}><CloudDownload/></CSVLink>
                    </IconButton> */}
                          <Tooltip title={lang.reports.download} placement="bottom">
                            <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.csvData)}>
                              <CloudDownload />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip title={lang.reports.print} placement="bottom">
                            <IconButton>
                              <ReactToPrint trigger={() => <Print />} content={() => this.componentRef} />
                            </IconButton>
                          </Tooltip> */}
                        </div>
                      </div>
                      <TreeTable dataTable={this.state.data} branch={branch} />
                    </Paper>
                }
              </PapperBlock>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

FavoritesReport.propTypes = {
  classes: PropTypes.object.isRequired
};

FavoritesReport.contextType = LanguageCxt;

export default withStyles(styles)(FavoritesReport);