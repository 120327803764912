import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './nuevaObra-jss';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

//ExpansionPanel
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import TableEditLibretto from './TableEditLibretto';
import { FormControl, TextField } from '@material-ui/core';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

class ScriptDetail extends React.Component{
    state = {
        dense: false,
        workLanguageId : 0
    }

    componentDidMount(){
        this.setState(
            {
                workLanguageId :( this.props.script.missing_chapters && this.props.script.missing_chapters !== 'undefined') ? this.props.script.missing_chapters : 0
            }            
        )
    }

    onChangeMaterial = (event, script) =>{

        let value = event.target.value
        this.setState(
            {
                workLanguageId: value
            }            
        )
        this.props.onChangeMaterial(value, script);
    }

    render(){
        const {classes, script, onDelete, onEdit} = this.props; 
        
        return (
            <ExpansionPanel >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {/* CH 15/08/19 Se acomodan estilos para que el titulo no se corte */}
                    <div className={classNames(classes.flexItemInput, classes.w100)}>
                        <List dense={this.state.dense} component="nav" className={classNames(classes.removePadding, classes.w100)}>
                            <ListItem className={classNames(classes.removePadding, classes.w100)}>
                                <ListItemAvatar>
                                <Hidden xsDown>
                                    <Avatar>
                                        <LibraryBooks />
                                    </Avatar>
                                </Hidden>
                                </ListItemAvatar>
                                <ListItemText className={classes.minW}
                                primary={`Libretos ${script.language} (1 al ${script.total_chapters})`}
                                />
                                {/* <ListItemSecondaryAction>
                                <IconButton aria-label="Delete">
                                    <DeleteIcon />
                                </IconButton>
                                </ListItemSecondaryAction> */}
                            </ListItem>
                        </List>
                        {/* <Typography className={classNames(classes.secondaryHeading, classes.colorSecondaryText)}> */}
                        {/* {(script.missing_chapters && (script.missing_chapters !== "undefined" || parseFloat(script.missing_chapters) >= 0)) ? */}
                        <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errornew_idioma}>
                            <TextField
                                label="Faltantes"
                                placeholder="Ej. 5, 6, 7"
                                id={"editMaterial"+script.works_language_Id}
                                name={"editMaterial"+script.works_language_Id}
                                value={this.state.workLanguageId}
                                type="text"
                                autoComplete="off"
                                className={classNames(classes.field, classes.w100)}
                                onChange={(e) => this.onChangeMaterial(e, script)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />
                        </FormControl>
                        {/* : ''} */}
                        {/* </Typography> */}
                        
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                    <TableEditLibretto data={script.scripts} onDelete={onDelete} onEdit={onEdit}></TableEditLibretto>
                    </Grid>
                </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}

ScriptDetail.propTypes = {
    classes: PropTypes.object.isRequired, 
    script: PropTypes.object.isRequired, 
    onDelete: PropTypes.func,
    onChangeMaterial : PropTypes.func
}

export default withStyles(styles)(ScriptDetail); 
