const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    hideRow: {
      display: 'none'
    },
    anchor: {
      '& span':{
        color: 'rgba(0,0,0,0)'
      }
    },
    icon: {
      top: 5,
      position: 'relative',
      left: -5, 
      cursor: 'pointer'
    }, 
    tableWrapper: {
      overflowX: 'auto',
    },
    tablecellIcon: {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  });

export default styles; 