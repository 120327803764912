import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import styles from './contact-jss';

class ContactHeader extends React.Component {
  render() {
    const {
      classes,
      total,
      hideDetail,
      showMobileDetail, 
      title //AHDA 30/05/2019
    } = this.props;
    return (
      <AppBar
        position="absolute"
        className={classes.appBar}
      >
        <Toolbar>
          {showMobileDetail && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => hideDetail()}
              className={classes.navIconHide}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="subheading" className={classes.title} color="inherit" noWrap>
            {/*AHDA 30/05/2019: Mapped title prop*/}
            <PermContactCalendar /> {title} ({total})
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

ContactHeader.propTypes = {
  classes: PropTypes.object ,
  showMobileDetail: PropTypes.bool ,
  hideDetail: PropTypes.func ,
  total: PropTypes.number, 
  title: PropTypes.string //AHDA 30/05/2019
};

//AHDA 30/05/2019: Added default props
ContactHeader.defaultProps = {
  title: ""
}

export default withStyles(styles)(ContactHeader);
