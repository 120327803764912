const styles = theme => ({
  root: {
    // width: '100%',
    // overflowX: 'auto',
    marginTop: 0,
  },
  field: {
    width: '100%',
    marginBottom: 20,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  table: {
    marginTop: 0,
    '& thead tr th': {
      color: '#9F9F9F'
    },
    '& tr td': {
      padding: '4px 24px 4px 24px'
    }
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#f5f7f9',
    }
  },
  actionsBtn: {
    minWidth: 110,
  },
  toolbar: {
    background: 'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%) !important',
    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: 'white'
    }
    // position: 'absolute',
    // width: '100%'
  },
  ellipsis: {
    [theme.breakpoints.down('xs')]: {
      width: 80,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
  },
  title: {
    flex: '0 0 auto',
    color: '#FFFFFF',
    '& h2': {
      color: theme.palette.common.white
    }
  },
  spacer: {
    flex: '1 1 100%',
  },
  ajusteTabla: {
    overflowX: 'auto',
    width: '100%',
  },
  widthCell: {
    maxWidth: '300px',
    minWidth: '50px',
  },
  button: {
    color: theme.palette.primary.main,
    background: 'white',
    marginLeft: theme.spacing.unit,
    transition: 'all 0.2s ease-in-out',
    '& svg': {
      color: theme.palette.primary.main,
    },
    '&:hover, &:focus, &:active': {
      color: theme.palette.primary.main,
      background: 'white',
      opacity: 0.8
    }
  },
  searchWhite: {
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
    },
    '& > div': {
      color: 'white',
      '&:hover': {
        '&:before': {
          borderColor: 'rgba(255,255,255,0.42) !important'
        }
      },
      '&:before': {
        borderColor: 'rgba(255,255,255,0.42)'
      },
      '&:after': {
        borderColor: 'white'
      }
    }
  },
  catalogTitleContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  catalogTitleIcon: {
    position: 'relative',
    top: -4,
    marginRight: theme.spacing.unit
  },
  titleField: {
    color: 'white !important',
    width: '100%',
    '&:before, &:hover:before': {
      borderBottomColor: 'white !important'
    },
    '&:hover:after, &:focus:after': {
      borderBottomColor: 'white !important'
    },
    '& button': {
      position: 'relative',
      top: -2
    }
  },
  alertDialog: {
    "& .confirm": {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    }
  },
  addBtn: {
    marginRight: theme.spacing.unit / 2,
    position: 'relative',
    top: -1
  }
});

export default styles;