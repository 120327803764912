const drawerWidth = 240;

const styles = theme => ({
  appBar: {
    background: 'rgba(255,255,255,.0)',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none !important',
    '& ::-webkit-input-placeholder': {
      color: 'rgba(255,255,255,.3)'
    },
    '& ::-moz-placeholder': {
      color: 'rgba(255,255,255,.3)'
    },
    '& :-ms-input-placeholder': {
      color: 'rgba(255,255,255,.3)'
    },
    '& :-moz-placeholder': {
      color: 'rgba(255,255,255,.3)'
    },
    '& $menuButton': {
      marginLeft: theme.spacing.unit * 2
    }
  },
  flex: {
    flex: 1,
    textAlign: 'right',
  },
  appBarShift: {
    transition: theme.transitions.create(['width', 'margin', 'background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    '& $menuButton': {
      zIndex: 9,
      marginLeft: 0,
    }
  },
  contactoData: {
    color: '#fff',
    '& svg': {
      fontSize: 20,
      marginBottom: -5,
      marginRight: 5
    },
  },
  enlaceBlanco: {
    color: '#fff !important',
    fontSize: '0.875rem',
    textDecoration: 'none',
    '& :hover': {
      textDecoration: 'underline',
    }
  },
  borderUser: {
    border: '2px solid #FF5A1A',
  },
  menuButton: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
      zIndex: 2,
    }
  },
  hide: {
    display: 'none',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    borderRadius: 2,
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 180,
      '&:focus': {
        width: 350,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
  },
  wrapperLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
    '& a': {
      display: 'inline-block',
      margin: '0 auto',
      zIndex: 10,
    },
    '& img': {
      paddingTop: 30,
      width: 120,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 50,
      },
    }
  },
  search: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  input: {
    font: 'inherit',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 9}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, 
    color: 'inherit',
    width: '100%',
    '&:focus': {
      outline: 0,
    },
  },
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 20,
    }
  },
  popperClose: {
    pointerEvents: 'none',
    zIndex: 2
  },
  darker: {
    background: theme.palette.primary.main,
    '&:after': {
      content: '""',
      left: -240,
      width: 'calc(100% + 240px)',
      position: 'absolute',
    }
  },
  separatorV: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    height: 20,
    margin: '0 10px',
    opacity: 0.4
  },
  notifMenu: {
    width: 350,
    maxHeight: 600,
    overflow: 'auto',
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      width: 290,
      maxHeight: 450,
      overflow: 'auto'
    },
    '& li': {
      height: 'auto',
      '& h3': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    }
  },
  notifZindex: {
    zIndex: 999,
  },
  badgeMenu: {
    '& span': {
      top: 0,
      right: -30
    }
  },
  textNotif: {
    paddingLeft: 11,
    '& > div div:last-child span': {
      fontSize: '0.8rem',
    },
    '& > div div:last-child p': {
      fontSize: '0.7rem',
    },
    '& span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }
  },
  unsetFlex: {
    display: 'unset',
  }
});

export default styles;
