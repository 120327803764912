import { withStyles } from '@material-ui/core/styles';
import { object, PropTypes } from 'prop-types';
import React from 'react';
import Loading from 'react-loading-bar';
import { renderRoutes } from 'react-router-config';
import '../../styles/components/vendors/react-loading-bar/index.css';
import styles from './styles';

class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      pageLoaded: true,
    };
  }

  componentWillMount = () => {
    this.onProgressShow();
  }

  componentDidMount = () => {
    this.playProgress();
    this.unlisten = this.props.history.listen(() => {
      this.playProgress();
    });
  }

  componentWillUnmount() {
    this.unlisten();
    this.onProgressShow();
  }

  onProgressShow = () => {
    this.setState({ pageLoaded: true });
  }

  onProgressHide = () => {
    this.setState({ pageLoaded: false });
  }

  playProgress = () => {
    this.onProgressShow();
    setTimeout(() => {
      this.onProgressHide();
    }, 500);
  }

  render() {
    const {
      classes,
      route,
    } = this.props;
    const {
      pageLoaded,
      theme,
    } = this.state;
    return (
      <div className={classes.root}>
        <Loading
          show={pageLoaded}
          color="rgba(255,255,255,.9)"
          showSpinner={false}
        />
        {renderRoutes(route.routes)}
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  route: object,
};

App.defaultProps = {
  route: object,
};

export default withStyles(styles)(App);
