import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import AddCatalogue from '../../../../components/Catalogue/AddCatalogue';
import Header from '../../../../components/Catalogue/Header';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';
import Loader from '../../../../components/Loader/Loader';
import { withUIContext } from '../../../../context';

import CustomNotification from '../../../../components/Notification/CustomNotification';
import { getFilteredData } from '../../../../helpers/general';
import { apiPOST } from '../../../../services/service_base';
import styles from '../styles-jss';

class Catalogo extends React.Component {
    state = {
        openForm: false,
        headers: ["ID", "Descripción", "Estatus", "Opciones"],
        data: [],
        rawData: [],
        selected: null,
        proDescripcion: "",
        openError: false,
        errType: "error",
        errMsg: "Ocurrió un error, intenta de nuevo más tarde",
        isLoading: true,
        chkDeleteCatalog: false,
        chkEditCatalog: false,
        chkNewCatalog: false
    };

    handleClose = () => {
        this.setState({ openError: false });
    };

    handledOpenForm = (item) => {
        if (item !== undefined) {
            this.setState({
                openForm: true,
                proDescripcion: item.descripcion,
                selected: item
            });
        }
        else {
            this.setState({ openForm: true, proDescripcion: "", selected: null });
        }
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    handleChange = (event) => {
        if (event.target.value.indexOf('\'') <= -1) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleClear = (event) => {
        this.setState({ proDescripcion: "" });
    }

    handleUpdateStatus = (data, item) => {
        let body = {
            idCatalog: 23,
            id_record: item.id,
            new_description: item.descripcion,
            new_status: item.estatus === 1 ? 0 : 1
        }

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {
                    let auxData = [...this.state.data];
                    let auxRawData = [...this.state.rawData];

                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id);
                    let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === item.id);

                    auxData[auxIndex].estatus = response.data[0].estatus;
                    auxRawData[auxRawIdx].estatus = response.data[0].estatus;

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                } else {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                }
            }).catch(
                error => {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                });
    }

    handleDeleteItem = (item) => {
        this.setState({ isLoading: true })
        let body = {
            idCatalog: 23,
            id_record: item.id,
            new_status: 2,
            new_description: item.descripcion
        }

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {

                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id);
                    let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === item.id);

                    let auxData = [...this.state.data];
                    let auxRawData = [...this.state.rawData];

                    auxData.splice(auxIndex, 1);
                    auxRawData.splice(auxRawIdx, 1);

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });

                } else {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                }
            }).catch(
                error => {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let body = {
            idCatalog: 23,
            id_record: this.state.selected ? this.state.selected.id : 0,
            new_status: this.state.selected ? this.state.selected.estatus : true,
            new_description: this.state.proDescripcion
        }

        let auxData = [...this.state.data];
        let auxRawData = [...this.state.rawData];

        const existsItem = auxRawData.filter(i => i.descripcion.toUpperCase() === this.state.proDescripcion.toUpperCase());
        if (existsItem.length > 0) {
            this.setState({ openError: true, errType: "warning", errMsg: "Ya existe una opción con este nombre, favor de validar" });
            return false;
        }

        this.setState({ isLoading: true });

        if (this.state.selected) {
            //update
            apiPOST('/catalogue/update', body).then(
                response => {
                    if (response.status === 200) {

                        let auxIndex = this.state.data.findIndex(itm => itm.id === this.state.selected.id);
                        let auxRawIdx = this.state.rawData.findIndex(itm => itm.id === this.state.selected.id);

                        auxData[auxIndex] = response.data[0];
                        auxRawData[auxRawIdx] = response.data[0];

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    } else {
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                    }
                }).catch(
                    error => {
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                    });
        } else {
            //Create
            apiPOST('/catalogue/add', body).then(
                response => {
                    if (response.status === 200) {
                        let auxItem = response.data[0];
                        auxData.push(auxItem);
                        auxRawData.push(auxItem);

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    } else {
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                    }
                }
            ).catch(
                error => {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                }
            );

        }
    }

    handleSearch = (search) => {
        let key = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Get all
        if (key === "") {
            this.setState({ data: [...this.state.rawData] });
            return false;
        }

        let auxData = [...this.state.rawData];

        auxData = getFilteredData(auxData, "descripcion", key);
        this.setState({ data: auxData });
    }

    componentDidMount() {
        let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 6)[0];
        let configPermission = JSON.parse(config.grant_configuration);

        this.setState({
            chkDeleteCatalog: configPermission.chkDeleteCatalog,
            chkEditCatalog: configPermission.chkEditCatalog,
            chkNewCatalog: configPermission.chkNewCatalog
        });

        apiPOST('/catalogue/getComplete', { idCatalog: 23 }).then(
            response => {
                if (response.status === 200) {
                    this.setState({ data: response.data, isLoading: false, rawData: response.data });
                } else {
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
                }
            }
        ).catch(
            error => {
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", isLoading: false });
            }
        );

    }

    render() {

        const { classes, uicontext } = this.props;

        return (
            <div className="marginTopLogo">

                <Paper className={classes.root}>
                    <Header
                        title="Territorios Restringidos"
                        tooltip="Nuevo territorio"
                        openForm={() => this.handledOpenForm(undefined)}
                        textBtn="Nuevo"
                        canAdd={this.state.chkNewCatalog}
                        search={this.handleSearch}
                        catalogue={23}
                    />
                    {
                        this.state.isLoading ?
                            <Loader text={"Cargando"} /> :
                            <TableCatalogue
                                canEdit={this.state.chkEditCatalog}
                                canDelete={this.state.chkDeleteCatalog}
                                headers={this.state.headers}
                                data={this.state.data}
                                openForm={this.handledOpenForm}
                                deleteItem={this.handleDeleteItem}
                                updateStatus={this.handleUpdateStatus}
                            />
                    }
                </Paper>
                {
                    uicontext.state.isCatalogEdit ? null :
                        <AddCatalogue
                            openForm={this.state.openForm}
                            closeForm={this.handledCloseForm}
                            classes={classes}
                            submit={this.handleSubmit}
                            clearForm={this.handleClear}
                            title="Territorios Restringidos"
                            catalogue={23}
                        >
                            <div>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextValidator
                                            label="Territorios Restringidos"
                                            placeholder=""
                                            id="proDescripcion"
                                            name="proDescripcion"
                                            value={this.state.proDescripcion}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['required', 'isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['Campo requerido', 'Campo requerido', 'No se permiten comillas simples']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 200 }
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </AddCatalogue>
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <CustomNotification
                        onClose={this.handleClose}
                        variant={this.state.errType}
                        message={this.state.errMsg} />
                </Snackbar>
            </div>
        )
    }
}

Catalogo.propTypes = {
    openNewPais: PropTypes.func,
};

export default withUIContext(withStyles(styles)(Catalogo));