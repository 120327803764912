//AHDA 11/04/2019
//Module created

import JSZipUtils from "jszip-utils"; 
import JSZip from "jszip"; 

const getBinary = (file) => {
    return new Promise(
        function(resolve, reject){
            JSZipUtils.getBinaryContent(file.url, function (err, data) {
                if(err) {
                    reject(err); 
                } else {
                    resolve(data); 
                }
            });
        }
    )
}

const downloadFiles = (files) => {
    let promises = [], auxFiles = []; 

    files.map(f => {
        promises.push(
            getBinary(f).then(response =>{
                f.data = response; 
                auxFiles.push(f); 
                return "Success"; 
            }).catch(err => {
                console.log("Error downloading file: ", err); 
                return "Error"; 
            })
        )
    });

    return Promise.all(promises).then(result => {
        if(result)
            return auxFiles; 
        else 
            return [];
    })
}

export const zipFile = (fileName, files) => {
    return new Promise(
        function(resolve, reject){

            var zip = new JSZip();   
            let auxFiles = []; 

            downloadFiles(files).then(response => {
                console.log("Files: ", response); 
                auxFiles = response; 
        
                auxFiles.map((f, idx) => {
                    zip.file(`${f.fileName}`, f.data, {binary:true});
                })

                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    console.log(content); 

                    const downloadUrl = URL.createObjectURL(content)
                    let a = document.createElement("a"); 
                    document.body.appendChild(a); //AHDA 23/05/2019
                    a.href = downloadUrl;
                    a.download = `${fileName}.zip`;
                    document.body.appendChild(a);
                    a.click();
                    resolve(content);  
                }).catch(function(err){
                    reject(err); 
                }); 

            }); 
        }
    ); 
}