import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from '../Catalogue/styles-jss';
// import Header from '../../routes/Pages/Configuration/ConfigureNotifications/notifHeader';
import TableLibretos from '../../components/Catalogue/TableLibretos';
import PropTypes from 'prop-types';
import { getFileTitle } from '../../helpers/general';

class DetailTable extends React.Component {

    //AHDA 02/05/2019
    //Added constructor and changed state here
    constructor(props){
        super(props); 
        this.state = {
            checkedA: true, 
            checkedB: true,
            checkedF: true,
            //Open form
            openForm: false,
            //Table
            headers: ["Nombre del archivo", "Idioma", "Fecha de creación","Descripción","Cantidad", ""],
            data: [],

            //Selected catalogue item
            selected: null,
            proDescripcion: "",
            proCodigo: "",
            //Error 
            openError: false,
            checked2: ["Juan"],
        }
    }

    //Open and close form
    handledOpenForm = (item) => {
        if (item !== undefined) {
            this.setState({
                openForm: true,
                proDescripcion: item.descripcion,
                proCodigo: item.cod_pais,
                selected: item
            });
        }
        else {
            this.setState({ openForm: true, proDescripcion: "", proCodigo: "", selected: null });
        }
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    handleDeleteItem = (item) => {
        console.log("Se deberia eliminar", item);
    }

    //Handle Form
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let body = {
            id: this.state.selected ? this.state.selected.id : 0,
            descripcion: this.state.proDescripcion,
            cod_pais: this.state.proCodigo,
            estatus: this.state.selected ? this.state.selected.estatus : true
        }

        console.log("Deberia insertar/modificar:", body); 
    }

    handleClear = (event) => {
        this.setState({ proDescripcion: "", proCodigo: "" });
    }

    handleToggle = value => () => {
        const { checked2 } = this.state;
        const currentIndex = checked2.indexOf(value);
        const newChecked = [...checked2];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({ checked2: newChecked });
    };

    //AHDA 02/05/2019
    //Added component did mount 
    //Lifecycle
    componentDidMount(){
        let auxData = []; 

        this.props.data.map((item) => {
            item.url = item.nombre; 
            item.nombre = getFileTitle(item.nombre); 
            auxData.push(item); 
        })

        this.setState({data: auxData}); 
    }

    render() {
        //AHDA 25/04/2019
        //Added canView
        const { classes, clickView, canView, handleDownload } = this.props;

        return (
            <div>
                <Paper className={classes.root}>
                    <TableLibretos
                        headers={this.state.headers}
                        data={this.state.data}
                        openForm={this.handledOpenForm}
                        deleteItem={this.handleDeleteItem}
                        showDelete={false}
                        clickView={clickView}
                        handleDownload={handleDownload}
                        canView={canView} />
                </Paper>
            </div>
        )
    }
}

//AHDA 25/04/2019
//Added canView
DetailTable.propTypes = {
    data: PropTypes.array, 
    clickView: PropTypes.func, 
    canView: PropTypes.bool
};

//AHDA 25/04/2019
//Added canView
DetailTable.defaultProps = {
    data: [], 
    canView: false
};

export default withStyles(styles)(DetailTable);