import { withStyles } from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { style } from './style';

const ButtonCircle = (props) => {
    const { className, children, href, classes, rounded, square, onClick, variant, color, disabled } = props;
    return <React.Fragment>
        <MaterialButton
            disabled={disabled}
            href={href}
            onClick={onClick}
            className={classNames(
                classes.button,
                rounded && classes.rounded,
                square && classes.square,
                getColorClasses(classes, color),
                className)}
            variant={variant || 'contained'}
            color={color || 'primary'}>
            {children}
        </MaterialButton>
    </React.Fragment>

}

ButtonCircle.propTypes = {
    children: PropTypes.node.isRequired,
    rounded: PropTypes.bool,
    square: PropTypes.bool,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    variant: PropTypes.oneOf('text', 'flat', 'outlined', 'contained', 'raised', 'fab', 'extendedFab'),
    color: PropTypes.oneOf('inherit', 'primary', 'secondary', 'default', 'none')
};

export default withStyles(style, { withTheme: true })(ButtonCircle);

/**
 * Returns a classname for the especified color if any.
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} color 
 * @returns 
 */
function getColorClasses(classes, color) {
    return [
        color === 'default' && classes.defaultColor,
        color === 'none' && classes.noneColor,
    ].filter(Boolean)
}