import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {fetchCall} from '../../../../services/service_base';
import styles from '../../../../components/Tables/tableParts/tableStyle-jss';
import AddProfile from './AddProfile';
import ModalPermissions from './ModalPermissions';
// inicio Taps
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification'; 
import ls from 'local-storage';
import ProfileTable from '../../../../components/Tables/ProfileTable';
import { apiPOST, apiGET } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';

class Profile extends Component {

  state = {
    dataProfile : [],
    anchorEl: null,
    openAddProfile:false,
    selectedProfile:null,
    openModal:false,
    activeIndex: 0,
    checkedAll: false,
    checkedConfig1: false,
    checkedConfig2: false,
    checkedConfig3: false,
    checkedConfig4: false,
    editProfile:false,
    openNotification:false,
    typeNotification:"info",
    messageNotification:"",
    optionsMenu:[],
    onlyView:false,
    canEdit:true,
    canShow:false,
    canPublish:true,
    canDelete : true,
    menuAction:[],
    isLoading:true,
    actionProfile:'',
    chkCreateProfileAdmin: false,
    chkDeleteProfileAdmin: false,
    chkEditPrivileges: false,
    chkEditProfileAdmin: false,
    chkShowPrivileges: false,
    chkViewProfileAdmin: false
  }

  constructor(props){
    super(props);
    this.handleStatus = this.handleStatus.bind(this);
  }

  handleClickOpt = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount(){ 
    this._getUserPermissions();
  }

  _getUserPermissions = () =>{

    let config = ls.get("user_permissions").filter(x => x.menu_id === 8)[0];
    console.log("Profile Permissions", JSON.parse(config.grant_configuration));
    let chk = JSON.parse(config.grant_configuration);

    this.setState({
      chkCreateProfileAdmin: chk.chkCreateProfileAdmin,
      //------------------Menu
      chkDeleteProfileAdmin: chk.chkDeleteProfileAdmin,
      chkEditPrivileges: chk.chkEditPrivileges,
      chkEditProfileAdmin: chk.chkEditProfileAdmin,
      chkShowPrivileges: chk.chkShowPrivileges,
      //------------------End menu
      chkViewProfileAdmin: chk.chkViewProfileAdmin
    });

      this._getProfileList();
  }

  handleCloseNotification = () =>{
    this.setState({openNotification:false,messageNotification:''});
  }

  _getProfileList = () => {
    apiPOST("/roles/get",{
      portal: 1
    }).then(response =>{
      console.log("Roles", response);
      this.setState({
        dataProfile : response.data,
        isLoading:false
      });
    }).catch(err => console.log("Fails fetch profile list", err));
  }

  handleOpenModal = ()=>{
    this.setState({openModal:true})
  }

  handleCloseModal = (loadData) =>{
    this.setState({openModal:false});    
  }

  handleClick = (event,profileId) => {
     this.setState({ [`${profileId}`] : true,anchorEl: event.currentTarget });
  };

  handleClose = (profileId) => {
    this.setState({ [`${profileId}`] : null });
  };

  openNewProfile = () =>{
    this.setState({openAddProfile:true, actionProfile:"Nuevo"});    
  }

  closeNewProfile = () =>{
    this.setState({openAddProfile:false, selectedProfile:null});
  }

  handleEditProfile = (profile) =>{    
    this.setState({
      [`${profile.profileId}`] :null,
       selectedProfile:profile,
       openAddProfile:true,
       editProfile:true,
       actionProfile: "Editar"
      });
  }

  handleEditPermissions = (profile) =>{    
    this.setState({isLoading:true});
    apiPOST("/menu/get", {
      portal : 1
    }).then(response => {
      this.setState({
        [`${profile.profileId}`] :null,
        selectedProfile:profile,
        openModal:true,
        optionsMenu:response.data,
        onlyView:false,
        isLoading:false
      });
    }).catch(err => {
      console.log("error getting menu", err);
    });

  }

  handleViewPermissions = (profile) =>{
   
    this.setState({isLoading:true});
    apiPOST("/menu/get", {
      portal : 1
    }).then(response => {
      this.setState({
        [`${profile.profileId}`] :null,
         selectedProfile:profile,
         openModal:true,
         optionsMenu:response.data,
         onlyView:true,
         isLoading:false
        });
      }).catch(err => {
        console.log("error getting menu", err);
      });
    
  }

  submitProfile = (event, data) =>{
    event.preventDefault();
    this.setState({isLoading:true});
    data.userId = ls.get("user_object").rdsUser.id_usuario;
    data.portalSave = 1;

    if(data.isUpdating){
      data.rolId = this.state.selectedProfile.rol_id;
       
      let isNameChanged = this.state.dataProfile.filter(x => x.rol_id === this.state.selectedProfile.rol_id)[0];
      if(isNameChanged != undefined){
        if(isNameChanged.name !== data.nameRol){
          let exists = this.state.dataProfile.filter(x => x.name.toString().toLowerCase().trim() === data.nameRol.toString().toLowerCase().trim())[0];
          if(exists != undefined){
            this.setState({
              openNotification:true,
              messageNotification:'Ya existe un perfil con ese nombre',
              typeNotification:'warning',
              isLoading:false
            });
            return true;
          }
        }
      }

      apiPOST("/roles/update", data).then(response => {
        if(response.status === 200){
          this.setState({
            openAddProfile:false,
            openNotification:true,
            messageNotification: 'Perfil modificado correctamente',
            typeNotification:'success',
            selectedProfile:null,
            isLoading:false
          })
        }
        this._getProfileList();
      }).catch(err => {
        console.log("error updating profile", err);
      });
    }else{
       
      let exists = this.state.dataProfile.filter(x => x.name.toString().toLowerCase().trim() === data.nameRol.toString().toLowerCase().trim())[0];

      if(exists === undefined){
        apiPOST("/roles/add", data).then(response => {
          if(response.status === 200){
            this.setState({
              openAddProfile:false,
              openNotification:true,
              messageNotification: 'Perfil guardado correctamente',
              typeNotification:'success',
              selectedProfile:null,
              isLoading:false
            })
          }
          this._getProfileList();
        }).catch(err => {
          console.log("error saving profile", err);
        });
      }else{
        this.setState({
          openNotification:true,
          messageNotification:'Ya existe un perfil con ese nombre',
          typeNotification:'warning',
          isLoading:false
        })
      }
    }

  }
 
  handleStatus = (pro) =>{
    this.setState({isLoading:true});
    apiPOST("/roles/status", {
      rolId: pro.rol_id, rol_status : pro.rol_status === "ON" ? 2 : 1
    }).then(response => {
      if(response.status === 200){
        this.setState({
          openAddProfile:false,
          openNotification:true,
          messageNotification: 'Se cambió el estatus correctamente',
          typeNotification:'success',
          selectedProfile:null,
          isLoading:false
        });
        this._getProfileList();
      }
    }).catch(err => {
      console.log("error saving profile", err);
    });

  }

  handleDefaultProfile = (pro) =>{
    this.setState({isLoading:true});
    apiPOST("/roles/default", {
      rolId: pro.rol_id, portalSave : 1
    }).then(response => {
      if(response.data[0].result === 1){
        this.setState({
          openAddProfile:false,
          openNotification:true,
          messageNotification: 'Se cambió el rol default correctamente',
          typeNotification:'success',
          selectedProfile:null,
          isLoading:false
        });
        this._getProfileList();
      }else{
        this.setState({
          openAddProfile:false,
          openNotification:true,
          messageNotification: 'Ya existe un rol por default',
          typeNotification:'warning',
          selectedProfile:null,
          isLoading:false
        });
      }
    }).catch(err => {
      console.log("error saving profile", err);
    });

  }

  handleDeletePermissions = (profile) =>{

    if(profile.usuario>0){
      this.setState({
        messageNotification:'No puedes eliminar el perfil',
        typeNotification:'warning',
        openNotification:true
      });
      return true;
    }

    //AHDA 18/06/2019: Added validation for default rol
    if(profile.rol_default === "ON"){
      this.setState({
        messageNotification: 'No puedes eliminar un perfil que tiene rol por default', 
        typeNotification: "warning", 
        openNotification: true
      }); 

      return false; 
    }

    this.setState({isLoading:true})
    apiPOST("/roles/status", {
      rolId: profile.rol_id, 
      rol_status : 3
    }).then(response => {
      if(response.status === 200){
        this.setState({
          openAddProfile:false,
          openNotification:true,
          messageNotification: 'Se eliminó el perfil',
          typeNotification:'success',
          selectedProfile:null,
          isLoading:false
        });
        this._getProfileList();
      }
    }).catch(err => {
      console.log("error saving profile", err);

      //AHDA 18/06/2019: Added error message
      this.setState({
        openAddProfile:false,
        openNotification:true,
        messageNotification: 'No se pudo eliminar el perfil, intenta de nuevo más tarde',
        typeNotification:'error',
        selectedProfile:null,
        isLoading:false
      });
    });
  }

  _saveChangesPermissions = (data) => {
    //AHDA 19/06/2019: Se cierra modal al dar click y no al finalizar petición
    this.setState({isLoading:true,
      openModal:false});
    apiPOST("/roles/updatepermissions",{
      permissions:data,
      updatedAt :JSON.parse(localStorage.getItem("user_object")).rdsUser.id_usuario,
      rolId : this.state.selectedProfile.rol_id
    }).then(response => {
      if(response.status === 200){
        this._getProfileList();
      }
    }).catch(err => console.log("error saving permissions", err))
  }

  render() {

    const {
      classes
    } = this.props;
    
    return (
      <div>
        
        {this.state.isLoading ? <LoaderFull /> : ''}

        <AddProfile 
          openForm={this.state.openAddProfile}
          closeForm={this.closeNewProfile} 
          classes={classes}
          submit={(event, data)=> this.submitProfile(event, data)}
          dataInit={this.state.selectedProfile}
          actionProfile={this.state.actionProfile}
        />

        <ModalPermissions
          classes={classes}
          openModalPermission={this.state.openModal}
          profileSelected={this.state.selectedProfile}
          handleCloseModal={this.handleCloseModal}
          optionsMenu={this.state.optionsMenu}
          onlyView={this.state.onlyView}
          saveChanges={this._saveChangesPermissions}
          menuAction={this.state.menuAction}
        />

        <Snackbar
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          open={this.state.openNotification}
          autoHideDuration={6000}
          onClose={this.handleCloseNotification}>
        <CustomNotification 
          onClose={this.handleCloseNotification}
          variant={this.state.typeNotification}
          message={this.state.messageNotification}/>
        </Snackbar>

        {/*AHDA 03/06/2019: Changed canPublish for canAdd
        AHDA 04/06/2019: Added canEditPriv, canShowPriv
        */}
        <ProfileTable
          classes={classes}
          dataProfile={this.state.dataProfile}
          openNewProfile={this.openNewProfile}
          handleStatus={this.handleStatus}
          handleDefaultProfile={this.handleDefaultProfile}
          handleEditProfile={this.handleEditProfile}
          handleViewPermissions={this.handleViewPermissions}
          handleEditPermissions={this.handleEditPermissions}
          handleDeletePermissions={this.handleDeletePermissions}
          
          canAdd={this.state.chkCreateProfileAdmin}
          canEdit={this.state.chkEditProfileAdmin}
          canDelete={this.state.chkDeleteProfileAdmin}
          canEditPriv={this.state.chkEditPrivileges}
          canShowPriv={this.state.chkShowPrivileges}
        />

        
      </div>
    );
  }
}
 
Profile.propTypes = {
  classes: PropTypes.object.isRequired
};
 
export default withStyles(styles)(Profile);
