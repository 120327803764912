import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import brand from '../../../utils/brand';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
//import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
//import TableDescargarLibreto from '../Users/Notifications/TableDescargarLibreto'; 
//AHDA 10/04/2019
//Added dependency for Loader and table
import Loader from '../../../components/Loader/Loader'; 
import LoaderFull from '../../../components/Loader/LoaderFull'; //AHDA 04/06/2019
import TableElegirLibretto from '../Users/Notifications/TableElegirLibretto';

//AHDA 05/04/2019
//Added dependencies for error notifications, and helpers
//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';

//AHDA 10/04/2019
//Added depndecies for services, storage and helpers
import {apiPOST, returnCurrentUserId, returnCurrentUser} from '../../../services/service_base'; 
import { zipFile } from '../../../services/zip_services';
import { getMultipleFiles } from '../../../services/storage';

//Helpers 
import { getFileTitle, getCleanTitle } from '../../../helpers/general';

//GEO 11/04/2019
//Cards para mensaje
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Warning from '@material-ui/icons/Warning';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
        margin: '10px auto'
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    alignMsj: {
        paddingBottom: '50px !important;',
        padding: 50,
        textAlign: 'center',
    },
    textMsj: {
        color: '#999999',
    },
    // MODAL
    appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
      detailContainer: {
        margin: '-16px auto 0',
        width: '100%',
        paddingTop: 40,
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1080,
          minWidth: 1080,
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: 960,
          minWidth: 960,
          paddingTop: 40,
          marginTop: 0
        },
        [theme.breakpoints.down('sm')]: {
          //overflowX: 'hidden',
        }
      },
      //GEO 11/04/2019
      //Se agregan clases
      removePadding:{
          paddingBottom: '16px !important',
      },
      p20: {
          padding: 20,
      },
      flexText: {
          alignItems: 'center',
          display: 'flex',
      },
      styleIcon: {
          color: '#FF5A1A',
          fontSize: 40,
          marginRight: 10,
      }
});

// function Transition(props) {
//     return <Slide direction="up" {...props} />;
//   }



class DownloadLibretos extends React.Component {
    
    //AHDA 10/04/2019
    //Added Message notifications state, Steps, Table
    state = {
        open: true,
        //Message Notifications 
        openError: false, 
        notifType: "error", 
        notifMsg: "Ocurrió un error, intente de nuevo más tarde",  
        //Table
        sharedMaterial: undefined, 
        scripts: [], 
        rawScripts: [], 
        //Steps: 
        //loading: renders loading
        //scripts: renders table
        //message: renders error message
        step: "scripts", 
        alertMessage: "", 
        errorType: "", 
        isLoading: false //AHDA 04/06/2019
    };
    
    //AHDA 10/04/2019
    //Deleted handle click event 

    //AHDA 10/04/2019
    //Added event for closing message notifications
    // ------------------------ Message Notifications
    handleCloseNotification = () => {
        this.setState({openError: false})
    } 

    //AHDA 10/04/2019
    //Added events for table, handleViewFile and handleClickSelectFiles
    // ------------------------ Table
    handleViewFile = (idx) => {

        //TODO: add prefix for shared folder 
        let auxData = [...this.state.rawScripts]; 
        const item = auxData[idx]; 
        const user = returnCurrentUser(); 
        if (item) {
            const url = `shared/${user ? user.usuario_cognito : null}/${item.ruta}`; 
            let auxUrl = encodeURIComponent(url)
            window.open(`/file?doc=${auxUrl}`, "_blank");
        }
    }

    handleClickSelectFiles = (selectedRows) => {

        //Get selected rows 
        const auxSelectedRows = selectedRows.data;
        //Copy of raw scripts
        const rawScripts = [...this.state.rawScripts]; 
        //Helper for filtering selected rows and generated urls
        let auxMaterial = [], urls = []; 

        //Get selected materials
        for(let i in auxSelectedRows){
            auxMaterial.push({ 
                key: rawScripts[auxSelectedRows[i].dataIndex].ruta, 
                fileName: getFileTitle(rawScripts[auxSelectedRows[i].dataIndex].ruta)
            }); 
        }

        //Generate urls 
        getMultipleFiles(auxMaterial, 'public', 120).then(response => {
            urls = response;
            zipFile(`${getCleanTitle(this.state.sharedMaterial.titulo_original)}_Libretos`, urls).then().catch(err => {
                this.setState({openError: true, notifType: "error", notifMsg: "No se pudieron descargar los archivos"});
            });
        });
    }
    // ------------------------ End Table

    //AHDA 11/04/2019
    //Added method for getting title
    //Helpers
    getTitle = () => {
        if(this.state.sharedMaterial){
            return this.state.sharedMaterial.titulo_original; 
        }else{
           switch(this.state.errorType){
               case 'expired': 
               return "Solicitud caducada";
               case 'notUser': 
               return "Solicitud no autorizada";  
               case 'notFound': 
               return "Solicitud no encontrada"; 
               default:
               return ""; 
           } 
        }
    }

    //AHDA 10/04/2019
    //Added componentDidMount
    //Lifecycle
    componentDidMount (){
 
        //AHDA 04/06/2019: Added isLoading
        this.setState({isLoading: true, step: "scripts"}); 
        if(this.props.match.params){
            let auxRequestId = this.props.match.params.id ? this.props.match.params.id : 0; 
            auxRequestId = Number.parseInt(auxRequestId); 
            if(auxRequestId > 0){
                const currentId = returnCurrentUserId();
                apiPOST('/material/getgrantedrequest', {idRequest: auxRequestId}).then(
                    response => {
                        console.log("Response: ", response); 
                        if(response.status === 200){
                            let auxData = [...response.data.scripts], auxScripts = []; 

                            //Validation for expired request
                            //AHDA 04/06/2019: Added isLoading
                            if(response.data.request === null){
                                this.setState({step: "message", errorType:"expired", alertMessage: "Ha expirado el tiempo disponible de los libretos solicitados", isLoading: false});
                                return false;
                            }
 
                            //Validation for user 
                            //AHDA 04/06/2019: Added isLoading
                            if(response.data.request.id_user_requested !== currentId){
                                //TODO: Change message 
                                this.setState({step: "message", errorType:"notUser", alertMessage: "No cuenta con permisos para ver los libretos", isLoading: false});
                                return false; 
                            }

                            //Get scripts
                            for(let i in auxData){
                                const item = [
                                    getFileTitle(auxData[i].ruta), auxData[i].language, auxData[i].date_created, 'true'
                                ]
                                auxScripts.push(item); 
                            }

                            //AHDA 04/06/2019: Added isLoading
                            this.setState({
                                step: "scripts", 
                                sharedMaterial: response.data.request, 
                                rawScripts: [...response.data.scripts], 
                                scripts: auxScripts, 
                                isLoading: false
                            }); 

                        }else{
                            //AHDA 04/06/2019: Added isLoading
                            this.setState({step: "scripts", openError: true, notifType: "error", notifMsg: "Ocurrió un error, intente de nuevo más tarde", isLoading: false});
                        }
                    }
                ).catch(
                    err => {
                        //AHDA 04/06/2019: Added isLoading
                        console.log("Error: ", err); 
                        this.setState({step: "scripts", openError: true, notifType: "error", notifMsg: "Ocurrió un error, intente de nuevo más tarde", isLoading: false});
                    }
                )
            }else{
                //AHDA 04/06/2019: Added isLoading
                this.setState({step: "message", errorType:"notFound", alertMessage: "No se encontraron los libretos solicitados", isLoading: false});
            }
        }else{
            //AHDA 04/06/2019: Added isLoading
            this.setState({step: "message", errorType:"notFound", alertMessage: "No se encontraron los libretos solicitados", isLoading: false});
        }
    }

    render(){
        
        const title = brand.name + ' - Material Compartido';
        const description = brand.desc;
        const { classes, theme } = this.props;

        return(
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    // TransitionComponent={Transition}
                >
                    {/*AHDA 04/06/2019: Added LoaderFull*/}
                    {
                        this.state.isLoading ? <LoaderFull /> : null
                    }

                    {/*AHDA 10/04/2019: Added Snackbar for messages notifications*/}
                    {/*For notifications*/}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.openError}
                        autoHideDuration={6000}
                        onClose={this.handleCloseNotification}>
                        <CustomNotification
                            onClose={this.handleCloseNotification}
                            variant={this.state.notifType}
                            message={this.state.notifMsg} />
                    </Snackbar>

                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            {/*AHDA 10/04/2019: Changed title and added work title*/}
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Material Compartido - { this.getTitle() } 
                            </Typography>
                        </Toolbar>
                        </AppBar>
                        <div className={classes.detailContainer}>
                            {/*AHDA 04/06/2019: Deleted loading step*/}
                            {/* <Paper className={classes.centerProgress}> */}
                                <Grid
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    spacing={16}>

                                    {/*AHDA 11/04/2019: Changed Method for rendering content*/}
                                    {{
                                        'message': 
                                        <Grid item md={12} sm={12} xs={12} className={classes.p20}>
                                        {/*GEO 11/04/2019
                                        Mensaje cuando el tiempo haya expirado */}
                                            <Card className={classes.card}>
                                                <CardContent className={classes.removePadding}>
                                                    <div className={classes.flexText}>
                                                        <Warning className={classes.styleIcon} />
                                                        <Typography variant="subtitle2">
                                                            {this.state.alertMessage}, para mayor información enviar un correo a <Link href="mailto:acervoliterario@televisaunivision.com" className={classes.link}>acervoliterario@televisaunivision.com</Link>
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        {/* Termina mensaje */}
                                        </Grid>,  
                                        'scripts': 
                                        <TableElegirLibretto
                                            info={this.state.sharedMaterial}
                                            data={this.state.scripts}
                                            handleViewFile={this.handleViewFile}
                                            clickSelect={this.handleClickSelectFiles}
                                            tableButtonText={"Descargar"}
                                        ></TableElegirLibretto>
                                    }[this.state.step]}
                                </Grid>
                            {/* </Paper> */}
                        </div>
                </Dialog>
            </div> 
        )
    }
}

export default withStyles(styles)(DownloadLibretos);