import React, { useContext } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Grid, FormControl, InputLabel, Typography, withStyles, Checkbox } from '@material-ui/core'
import styles from './product-jss'
import SimpleTable from '../../components/Tables/SimpleTable'
import { LanguageCxt } from '../../language/LanguageContext';

const TabMoreInfo = ({ classes, data }) => {
    const { lang } = useContext(LanguageCxt);
    const dataHead = [lang.tabMoreInfo.assignedUser, lang.tabMoreInfo.assignedDate, lang.tabMoreInfo.comments]
    const dataRow = data && data.assignedProspects.map(({ nombre, fechaModificacion, comentarios }) => {
        return [nombre, fechaModificacion, comentarios];
    });
    return (
        <Card>
            <CardHeader subheader={lang.tabMoreInfo.title} className={classes.header} />
            <CardContent>
                {data ?
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Checkbox checked={data.markedProspect} disabled />
                            <Typography variant='body1' component="span" className={classes.checkboxOption} >{lang.tabMoreInfo.markedAsProspect}</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.optionContainer}>
                            {data.nombre ?
                                <FormControl fullWidth>
                                    <InputLabel shrink>{lang.tabMoreInfo.assignedto}</InputLabel>
                                    <label className={classNames(classes.paddingTopLabel)}>{data.nombre}</label>
                                </FormControl>
                                : <Typography variant='body1' component="span" >{lang.tabMoreInfo.notAssigned}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {dataRow && dataRow.length > 0 ?
                                <SimpleTable dataRow={dataRow} dataHead={dataHead} style={{ minWidth: '860px', margin: 0 }} />
                                : <Typography variant='body1' component="span" >{lang.tabMoreInfo.notAssignedUser}</Typography>
                            }
                        </Grid>
                    </Grid>
                    : <InputLabel >{lang.common.noInfo}</InputLabel>}
            </CardContent>
        </Card>
    )
}

TabMoreInfo.propTypes = {
    data: PropTypes.array
}

export default withStyles(styles)(TabMoreInfo);