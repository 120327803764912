//Dummy images
import video from '../../../static/images/default.png';

export const styles = (theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  root: {
    width: '100%',
  },
  videos: {
    width: '100%',
    textAlign: 'center',
  },
  videoContainer: {
    //THUMBANAIL
    background: `url(${video}) no-repeat center`,
    backgroundSize: 'cover',
    padding: '50px',
    '& img': {
      margin: 'auto',
      border: 'none',
      cursor: 'pointer',
      width: '50px',
    },
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '0', //AHDA 03/06/2019
  },
  flexboxInputs: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  checkboxLabel: {
    minWidth: 'unset',
  },
  formControlSpacing: {
    marginBottom: theme.spacing.unit * 2,
  },
  videoName: {
    wordBreak: 'break-all',
  },
  alignCenter: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  paddingTopLabel: {
    paddingTop: 15,
  },
});
