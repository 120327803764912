import cyan from '@material-ui/core/colors/cyan';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    zIndex: 1,
    position: 'relative'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden'
    },
  },
  brandMovil: {
    alignItems: 'center',
    display: 'flex',
    height: 139,
    justifyContent: 'center',
    padding: '10px 10px 5px',
    position: 'relative',
    '& img': {
      width: 150,
    },
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing.unit * 3
  },
  formWrap: {
    [theme.breakpoints.up('md')]: {
      marginTop: -24
    },
  },
  btnArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 3,
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
        margin: 5
      }
    },
  },
  noMargin: {
    margin: 0
  },
  optArea: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
  },
  textWhite: {
    color: '#ffffff',
  },
  redBtn: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  blueBtn: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
    '&:hover': {
      backgroundColor: indigo[700],
    },
  },
  cyanBtn: {
    color: theme.palette.getContrastText(cyan[700]),
    backgroundColor: cyan[500],
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  footer: {
    textAlign: 'center',
    padding: 5,
    background: theme.palette.grey[100],
    color: '#666',
    fontSize: 12,
    position: 'relative'
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: 20,
    '& img': {
      width: 32
    },
    '& h3': {
      fontSize: 18,
      margin: 0,
      paddingLeft: 10,
      fontWeight: 500,
      color: theme.palette.grey[700]
    }
  },
  brandText: {
    marginTop: 10,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  decoBottom: {
    fontSize: 480,
    position: 'absolute',
    left: 10,
    bottom: -190,
    opacity: 0.1,
    color: theme.palette.secondary.dark
  },
  tab: {
    marginBottom: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing.unit * -3,
    },
  },
  link: {
    fontSize: 12,
    marginLeft: -30,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  socMedFull: {
    marginBottom: theme.spacing.unit * 2
  },
  lockWrap: {
    textAlign: 'center',
    padding: theme.spacing.unit * 3
  },
  avatar: {
    width: 150,
    height: 150,
    margin: '5px auto 30px',
    [theme.breakpoints.up('md')]: {
      margin: '-75px auto 30px',
    },
    boxShadow: theme.shadows[8]
  },
  userName: {
    marginBottom: theme.spacing.unit * 3
  },
  hint: {
    padding: theme.spacing.unit
  },
  noMarginB: {
    marginBottom: 0
  },
  help: {
    textAlign: 'justify',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.9rem',
    textAlign: 'center'
  },
  logoAcervoLiterarioContainer: {
    '&:after': {
      content: "''",
      display: "block",
      background: theme.palette.warning.main,
      height: 4,
      width: '20%'
    }
  },
  wrapperLogin: {
    maxWidth: '235px',
    margin: '3.5rem auto',
    '& h3': {
      color: theme.palette.primary.main,
      position: "relative",
      fontSize: '1.7rem',
      fontWeight: 400,
      letterSpacing: '6px',
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: -3,
        left: 0,
        width: '50px',
        height: '4px',
        backgroundColor: theme.palette.primary.main
      }
    },
  }
});

export default styles;
