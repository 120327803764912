import React from "react";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
  cajaBtn: {
      padding: 14,
      paddingTop: 6
  },
  rightIcon: {
    marginLeft: 10,
  }
};

class CustomToolbarSelect extends React.Component {

  //AHDA 08/04/2019
  //Added props clickSelect, selectedRows
  //AHDA 10/04/2019
  //Added tableButtonText
  render() {
    const { 
      classes, 
      clickSelect, 
      selectedRows,
      tableButtonText
    } = this.props;

    return (
      <div className={classes.cajaBtn}>
        {/*AHDA 08/04/2019: added onClick event*/}
        <Button className={classes.Button} variant="contained" color="primary" onClick={() => clickSelect(selectedRows)}>
          {tableButtonText} <Icon className={classes.rightIcon}>chevron_right</Icon> 
        </Button>
      </div>
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);