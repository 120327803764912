//AHDA 05/04/2019
//Added dependency for PropTypes 
import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';

//AHDA 05/04/2019
//Commented unused dependencies 
//Added Tooltip, CloudDownload, Services section

//import TableCell from '@material-ui/core/TableCell';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import Checkbox from '@material-ui/core/Checkbox';
import CustomToolbarSelect from "./CustomToolbarSelect";

import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownload from '@material-ui/icons/CloudDownload';

//Services
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';

//AHDA 29/04/2019
//Imported translated messages for table
import { textLabelsEs } from '../../../../utils/tableTexts';

class AdvFilter extends React.Component {
  //AHDA 05/04/2019
  //Added onClick method to custom option
  state = {
    columns: ['Nombre del archivo', 'Idioma', 'Fecha de creación', 
    {
      name: "Ver",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton aria-label="Delete" onClick={() => this.props.handleViewFile(tableMeta.rowIndex)}>
              <Visibility /> 
            </IconButton>
          );

        }
      }
    }
  ]
  //AHDA 05/04/2019
  //Deleted dummy data 
  }

  //AHDA 05/04/2019
  //Method for downloading 
  handleDownload = (data) => {

    let auxData = [...data]; 

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'acervo',
      screen: 'notificationsPickScript'
    }
    apiPOST('/audit/add', body);

    const dataCsv = [['Nombre del archivo', 'Idioma', 'Fecha de creación']]

    for (const x in auxData) {
      const object = []
      //Delete last position, (since is true due to table functionality)
      auxData[x].splice(auxData[x].length - 1, 1); 
      object.push(auxData[x]);
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = `Libretos_${this.props.info.titulo_original}.csv`;
    download.click();
  }

  render() {
    const { columns } = this.state;
    //AHDA 10/04/2019
    //Added tableButtonText
    const { data, clickSelect, tableButtonText } = this.props; 
    
    //AHDA 05/04/2019: 
    //Disabled options print, viewColumns and download
    //Added custom download button
    //AHDA 08/04/2019
    //Added clickSelect to CustomToolbarSelect
    //AHDA 29/04/2019
    //Added tableButtonText, textLabels
    const options = {
      filterType: 'dropdown',
      selectableRows: true,
      responsive: 'stacked',
      print: false,
      viewColumns: false, 
      download: false, 
      rowsPerPage: 10,
      page: 1,
      textLabels: textLabelsEs,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect 
        selectedRows={selectedRows} 
        displayData={displayData} 
        setSelectedRows={setSelectedRows} 
        clickSelect={clickSelect}
        tableButtonText={tableButtonText}/>
      ),
      customToolbar: () => {
        return (
          <Tooltip title={"Descargar"} placement="bottom" onClick={() => this.handleDownload(data)}>
            <IconButton aria-label="download">
              <CloudDownload />
            </IconButton>
          </Tooltip>
        )
      }
    };
    return (
        <div>
          {/*AHDA 05/04/2019: Commented checkbox without functionality*/}
          {
            /*<TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={numSelected === rowCount}
                            // onChange={onSelectAllClick}
                        />
                    </TableCell>
                  
                </TableRow>
            </TableHead>*/
          }    
          
          <MUIDataTable
            title="Libretos"
            data={data}
            columns={columns}
            options={options}
          />
        </div>
    );
  }
}

//AHDA 05/04/2019
//Added props
//AHDA 10/04/2019
//Added tableButtonText
AdvFilter.propTypes = {
  data: PropTypes.array.isRequired, 
  info: PropTypes.object, 
  handleSelectRows: PropTypes.func, 
  handleViewFile: PropTypes.func, 
  clickSelect: PropTypes.func, 
  tableButtonText: PropTypes.string
}

//AHDA 10/04/2019
//Added tableButtonText default text
AdvFilter.defaultProps = {
  handleSelectRows: () => {}, 
  tableButtonText: ""
}

export default AdvFilter;