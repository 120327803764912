const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
    color: theme.palette.primary.main,
    '& small': {
      color: theme.palette.primary.main,
    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tabContentContainer: {
    height: 'auto',
    width: '100%'
  },
  title: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
    padding: '1rem 0',
  },
  tabsContainer: {
    background: 'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%)',
    fontSize: 25,
    '& button': {
      color: 'white',
      opacity: 0.65
    },
    '& *[aria-selected=true]': {
      color: 'white',
      opacity: 1,
      transform: 'scale(1.1)'
    },
    '& *[role=tablist]': {
      overflowY: 'hidden',
    }
  }
});

export default styles;