import {
  createMuiTheme, MuiThemeProvider
} from '@material-ui/core/styles';
import Amplify, { Auth } from 'aws-amplify';
import createHistory from 'history/createBrowserHistory';
import React, { Component } from 'react';
import { renderRoutes } from 'react-router-config';
import { Router } from "react-router-dom";
import langstr from './app/language/lang';
import { LanguageProvider } from './app/language/LanguageContext';
import routes from "./app/routes/index";
import { awsapi, awscognito, awsstorage, oauth } from './config/config';
import { UIContextWrapper } from './app/context/UIContext';
import ThemePallete from './app/utils/themePalette';

//Styles
import './app/styles/layout/_base.scss';
import './app/styles/normalize.scss';

const history = createHistory();

Amplify.configure({
  Auth: awscognito,
  API: awsapi,
  Storage: awsstorage
});

//Added configuration for Auth
Auth.configure({ oauth });

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme(ThemePallete['acervoTheme'])}>
        <LanguageProvider value={{ lang: langstr }}>
          <UIContextWrapper>
            <Router history={history}>
              {renderRoutes(routes)}
            </Router>
          </UIContextWrapper>
        </LanguageProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
