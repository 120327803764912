export const textLabelsEs = {
  body: {
      noMatch: "Lo sentimos, no se encontraron registros coincidentes",
      toolTip: "Ordenar",
    },
  pagination: {
      next: "Siguiente página",
      previous: "Pagina anterior",
      rowsPerPage: "Filas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Impresión",
      viewColumns: "Ver columnas",
      filterTable: "Filtros",
    },
    filter: {
      all: "TODOS",
      title: "FILTROS",
      reset: "REINICIAR",
    },
    viewColumns: {
      title: "Mostrar columnas",
      titleAria: "Mostrar / ocultar columnas de tabla",
    },
    selectedRows: {
      text: "fila(s) seleccionadas",
      delete: "Eliminar",
      deleteAria: "Eliminar filas seleccionadas",
    },
}