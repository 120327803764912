import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// Reducer Branch
import Toolbar from '@material-ui/core/Toolbar'; 
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import styles from '../../components/Tables/tableParts/tableStyle-jss';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Dashboard from '@material-ui/icons/Dashboard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InsertChart from '@material-ui/icons/InsertChart';
import Settings from '@material-ui/icons/Settings';
import Notifications from '@material-ui/icons/Notifications';
import SweetAlert from 'sweetalert-react';
import { AddCircleOutline } from '@material-ui/icons';
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#9F9F9F',
    padding: '4px 24px 4px 24px',
  },
  body: {
    padding: '4px 24px 4px 24px',
    fontSize: 14,
  },
}))(TableCell);

const ITEM_HEIGHT = 48;

class ProfileTable extends Component {
 
    state ={
        anchorEl:null,
        show:false,
        selectedItem:null
    }
 
    handleClick = (event,profileId) => {
        this.setState({ [`${profileId}`] : true,anchorEl: event.currentTarget });
    };

    handleClose = (profileId) => {
        this.setState({ [`${profileId}`] : null ,anchorEl: null});
    }; 

    handleConfirmSwal = () => {
      this.setState({ show: false });
      this.props.handleDeletePermissions(this.state.selectedItem);
      // this.props.deleteItem(this.state.selected);
    }

    _openConfirmDialog = (item) => {
      this.setState({
        show:true,
        selectedItem:item
      });
    }

 
  render() {

    const {
      classes,
      dataProfile,
      openNewProfile,
      handleStatus,
      handleDefaultProfile,
      handleEditProfile,
      handleViewPermissions,
      handleEditPermissions,
      handleDeletePermissions,
      canEdit,
      canDelete, 
      canAdd, //AHDA 03/06/2019
      canEditPriv, //AHDA 04/06/2019 
      canShowPriv, //AHDA 04/06/2019

    } = this.props;
    
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    
    
    return (
      <div className="marginTopLogo">

        <SweetAlert
          show={this.state.show}
          customClass={classes.alertDialog}
          title="¿Estás seguro?"
          text="Una vez que elimines este elemento, no se podrá recuperar"
          showCancelButton
          onConfirm={() => {
              this.handleConfirmSwal();
          }}
          onCancel={() => {
              this.setState({ show: false, selected: null });
          }}
        />

        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            Perfiles y Privilegios
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
          {/*AHDA */}
          {
            canAdd && 
            <Button 
              variant="contained" 
              //disabled={canPublish} 
              onClick={openNewProfile} color="primary" className={classes.button}>
              {/* CH 22/04/2019 Se elimina Tooltip */}
              <AddCircleOutline className={classNames(classes.leftIcon, classes.iconSmall)} />
              Nuevo
            </Button>
          }
          </div>
        </Toolbar>
        <Paper className={classes.rootTable}>
          <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>Clave</CustomTableCell>
            <CustomTableCell align="left">Nombre</CustomTableCell>
            <CustomTableCell align="left">Descripción</CustomTableCell>
            {/* JCMY 05/07/2019 incidecnia 830*/}
            {/* <CustomTableCell align="left">Opciones del menú</CustomTableCell> */}
            <CustomTableCell align="right">Usuarios</CustomTableCell>
            <CustomTableCell align="center">Estatus</CustomTableCell>
            <CustomTableCell align="center">Default Rol</CustomTableCell>
            <CustomTableCell align="center">Opciones</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataProfile.map(row => {
            return (
              <TableRow className={classes.row} key={row.rol_id}>
                <CustomTableCell component="th" scope="row">
                  {row.key}
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {row.name}
                </CustomTableCell>
                <CustomTableCell align="left">{row.descripcion}</CustomTableCell>
                {/* JCMY 05/07/2019  incidencia 830*/}
                {/* <CustomTableCell align="right">
                <div className={classNames(classes.contentIcons, classes.alignCellIcon)}>
                  {   
                    JSON.parse(row.menuAcervo !== null ? "["+row.menuAcervo +"]": '[]').map((item, index) =>{
                      return(      
                        <Tooltip title={item.name}>                    
                          <div key={index.toString()}  className={classes.icon}>
                            {
                              item.id === "1" ? <Dashboard className={classNames(classes.icon)}/> :
                              item.id === "2" ? <InsertChart className={classNames(classes.icon)}/> :
                              item.id === "3" ? <Settings className={classNames(classes.icon)}/> :
                              item.id === "5" ? <AccountCircle className={classNames(classes.icon)}/> :
                              item.id === "8" ? <Notifications className={classNames(classes.icon)}/> :
                              <Settings className={classNames(classes.icon)}/>
                            }                          
                          </div>
                        </Tooltip>       
                      );
                    })
                  }
                  </div>
                </CustomTableCell> */}
                <CustomTableCell align="right">{row.usuario ? row.usuario : 0}</CustomTableCell>
                <CustomTableCell align="right">
                  
                  <Switch
                    checked={row.rol_status === "ON" ? true: false}
                    onChange={() =>{handleStatus(row)}}
                    value="checkedB"
                    color="primary"
                    disabled={!canEdit}
                  />

                </CustomTableCell>
                <CustomTableCell align="right">
                  <Switch
                      checked={row.rol_default === "ON" ? true: false}
                      onChange={() =>{handleDefaultProfile(row)}}
                      value="checkedB"
                      color="primary"
                      disabled={!canEdit}
                    />
                </CustomTableCell>
                
                <CustomTableCell align="center">
                <div key={row.profileId} className={classes.contentIcons}>
                
                    <IconButton
                        aria-label="More"
                        aria-owns={open ? 'long-menu' : undefined}
                        aria-haspopup="true"
                        onClick={(event) => this.handleClick(event,row.rol_id)}
                      >
                        <MoreVertIcon />
                    </IconButton>
                      
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(this.state[`${row.rol_id}`])}
                        onClose={() => this.handleClose(`${row.rol_id}`)}
                        key={row.rol_id}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                          },
                        }}
                      >
                        <MenuItem 
                        disabled={!canEdit} 
                        onClick={()=>{
                            handleEditProfile(row);
                            this.handleClose(`${row.rol_id}`);
                        }}>Editar perfil</MenuItem>
                        
                        {/*AHDA 04/06/2019: Mapped permissions*/}
                        <MenuItem 
                        disabled={!canShowPriv}
                        onClick={()=>{
                            handleViewPermissions(row);
                            this.handleClose(`${row.rol_id}`);
                        }}>Ver privilegios</MenuItem>

                        {/*AHDA 04/06/2019: Mapped permissions*/}
                        <MenuItem 
                          disabled={!canEditPriv} 
                          onClick={()=>{
                            handleEditPermissions(row);
                            this.handleClose(`${row.rol_id}`);
                        }}>Editar privilegios</MenuItem>

                        <MenuItem 
                        disabled={!canDelete} 
                        onClick={()=>{
                            this._openConfirmDialog(row);
                            this.handleClose(`${row.rol_id}`);
                        }}>Eliminar perfil</MenuItem>

                      </Menu> 
                  
                </div>
                </CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
        </Paper>
      </div>
    );
  }
}
 
ProfileTable.propTypes = {
    classes: PropTypes.object.isRequired,
    dataProfile :PropTypes.array,
    openNewProfile :PropTypes.func,
    handleStatus :PropTypes.func,
    handleDefaultProfile :PropTypes.func,
    handleEditProfile :PropTypes.func,
    handleViewPermissions :PropTypes.func,
    handleEditPermissions :PropTypes.func,
    handleDeletePermissions :PropTypes.func,
    canAdd:PropTypes.bool, //AHDA 03/06/2019
    canEdit:PropTypes.bool,
    canDelete:PropTypes.bool,
    canEditPriv: PropTypes.bool, //AHDA 04/06/2019 
    canShowPriv: PropTypes.bool //AHDA 04/06/2019
};
 
export default withStyles(styles)(ProfileTable);