import { Button, Modal, Typography, withStyles } from "@material-ui/core";
import React from "react";
import styles from "../../../../components/Tables/tableParts/tableStyle-jss";

function getModalStyle() {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
}


class Dialog extends React.Component {
    
    render () {
        const { 
            classes, 
            open, 
            onClose, 
            children, 
            title, 
            onSave,
            labelCancel,
            labelSave
        } = this.props;

        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={onClose}
            >
                <div style={getModalStyle()} className={classes.paper}>
                    <div>
                        <Typography className={classes.paperHeader}>
                            {title}
                        </Typography>
                    </div>
                    <div className={classes.paperBody}>
                        {children}
                    </div>
                    <div className={classes.paperFooter}>
                        <Button onClick={onClose} variant="contained" color="default" type="submit">
                            {labelCancel}
                        </Button>
                        <Button onClick={onSave} variant="contained" color="primary" type="submit">
                            {labelSave}
                        </Button>
                    </div>
                </div>
            </Modal>
        )
    }
}


export default withStyles(styles)(Dialog);