const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    marginTop: 0,
    '& thead tr th': {
      color: '#9F9F9F'
    },
    '& tr td': {
      padding: '4px 24px 4px 24px'
    }
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#f5f7f9',
    }
  },
  actionsBtn: {
    minWidth: 110,
  },
  toolbar: {
    background: 'linear-gradient(93deg, #F12065 0.18%, #F32758 50.82%, #FE5503 101.46%) !important',
    marginTop: 0,
  },
  title: {
    flex: '0 0 auto',
    color: '#FFFFFF',
    '& h2': {
      color: theme.palette.common.white
    }
  },
  spacer: {
    flex: '1 1 100%',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  field: {
    width: '100%',
    marginBottom: 20,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  w100: {
    width: '100%',
  },
  listaRoles: {
    textTransform: 'capitalize',
  },
  mbTable: {
    marginBottom: '2rem',
  },

  paper: {
    '& div[role="document"]' : {
      backgroundColor: "white",
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      left: "50%",
      maxWidth: 480,
      outline: 'none',
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
    }
  },

  paperHeader: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing.unit +  'px',
    paddingInline: theme.spacing.unit * 2 +  'px',
    width: '100%',
  },

  paperBody: {
    padding: theme.spacing.unit * 2,
    minHeight: 120,
  },

  paperFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    paddingBlock: theme.spacing.unit +  'px',
    paddingInline: theme.spacing.unit * 2 +  'px',
    width: '100%',
  },

  my: {
    marginBlock: theme.spacing.unit * 2 +  'px',
  },

  boxcheck: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing.unit * 2 +  'px'
  }

});

export default styles;