import { grey } from '@material-ui/core/colors'

export const style = (theme) => {
    return ({
        button: {
            textTransform: 'unset',
            height: '1.6rem',
            width: '1.6rem',
            minWidth: 0,
            padding: 0,
        },
        rounded: {
            borderRadius: '50%',
        },
        square: {
            borderRadius: 0,
        },
        defaultColor: {
            color: 'white',
            background: grey[600],
        },
        noneColor: {
            background: 'unset',
            color: theme.palette.graydefault,
            boxShadow: 'none',
            transition: '0.1s ease-in-out transform',
            '&:hover': {
                background: 'unset',
                boxShadow: 'none',
                transform: 'scale(1.2)',
            },
        }
    })
}