import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Chip, FormControlLabel, Grid, InputLabel, Radio, Typography, withStyles } from '@material-ui/core'
import styles from './product-jss'
import classNames from 'classnames'
import { getChipClassNames } from './product-utils'
import moment from 'moment'
import { LanguageCxt } from '../../language/LanguageContext'

const TabVigencias = ({ classes, data, titleTerritories }) => {
    const { lang } = useContext(LanguageCxt);

    data.options = data.options ? typeof data.options === 'object' ? data.options : JSON.parse(data.options) : {};

    return (
        <>
            <div className={classes.chipContainer}>
                {data.type && <Chip label={data.type} className={getChipClassNames(classes, data.type)} />}
            </div>
            <Card>
                <CardHeader subheader={lang.tabValidity.title} className={classes.header} />
                <CardContent>
                    {data.id_obra_vigencia ?
                        <Grid>
                            {data.date &&
                                <Grid container spacing={32}>
                                    <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                        <InputLabel>{lang.tabValidity.month}: </InputLabel>
                                        <Typography variant='body1' component="span" className={classes.optionValue}>{moment(data.date).format('MMMM')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                        <InputLabel>{lang.tabValidity.year}: </InputLabel>
                                        <Typography variant='body1' component="span" className={classes.optionValue}>{moment(data.date).format('YYYY')}</Typography>
                                    </Grid>
                                </Grid>
                            }
                            {data.options.pup !== undefined && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <FormControlLabel
                                        checked={data.options.pup}
                                        disabled
                                        control={<Radio className={classes.primaryRadio} />}
                                        
                                    />
                                    <Typography variant='body1' component="span" className={classNames(classes.optionLabel, classes.optionLabel80)}>{lang.tabValidity.PUP}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <InputLabel>{lang.tabValidity.year}: </InputLabel>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.options.year}</Typography>
                                </Grid>

                            </Grid>
                            }
                            {data.options.perpetuo !== undefined && <Grid container spacing={32} >
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <FormControlLabel
                                        checked={data.options.perpetuo}
                                        disabled
                                        control={<Radio className={classes.primaryRadio} />}
                                        
                                    />
                                    <Typography variant='body1' component="span" className={classNames(classes.optionLabel, classes.optionLabel80)}>{lang.tabValidity.perpetual}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {data.options.territorio && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classes.optionLabel}>{titleTerritories}:</Typography>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.options.territorio}</Typography>
                                </Grid>
                            </Grid>
                            }
                            {data.comment && <Grid container spacing={32}>
                                <Grid item xs={12} sm={4} className={classes.optionContainer}>
                                    <Typography variant='body1' component="span" className={classes.optionLabel}>{lang.tabValidity.comment}:</Typography>
                                    <Typography variant='body1' component="span" className={classes.optionValue}>{data.comment}</Typography>
                                </Grid>
                            </Grid>
                            }
                        </Grid>
                        : <InputLabel >{lang.common.noInfo}</InputLabel>}
                </CardContent>
            </Card>
        </>
    )
}

TabVigencias.propTypes = {
    data: PropTypes.array,
    titleTerritories: PropTypes.string
}

export default withStyles(styles)(TabVigencias);