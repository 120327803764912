import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles-jss'

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Delete from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton';
import ModeEdit from '@material-ui/icons/ModeEdit';
import ls from 'local-storage';
import SweetAlert from 'sweetalert-react';
import Tooltip from '@material-ui/core/Tooltip';
import '../../styles/components/vendors/sweetalert-react/sweetalert.css';

//AHDA 20/05/2019
/*import MUIDataTable from 'mui-datatables';
import { textLabelsEs } from '../../utils/tableTexts';
import AddIcon from '@material-ui/icons/Add';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';*/


class TableCatalogue extends React.Component {
    state = {
        show: false,
        selected: null, 
        //Pagination
        page: 0, //AHDA 21/05/2019
        rowsPerPage: 10 //AHDA 21/05/2019
    };

     //AHDA 21/05/2019: Unused functionality
    /*getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
            root: {
              backgroundColor: "#0071B1",
              '& h6': {
                  color: '#fff'
              },
              MuiIconButton:{
                color: '#fff'
              }
            }
          }
        }
      })*/

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.deleteItem(this.state.selected);
    }

    componentDidMount() {
        const tableProperties = ls.get("tableProperties");
        if (tableProperties) {
            this.setState({ page: tableProperties['page'], rowsPerPage: tableProperties['rowsPerPage'] });
        }
    }

    //AHDA 21/05/2019: Unused functionality
    /*componentDidMount(){
        //Get Headers 
        if(this.props.customHeaders.length > 0){
            this.setState({headers: [...this.props.customHeaders]}); 
        }else{
            let auxHeaders =  [...this.props.headers]
            
            if(this.props.showStatus){auxHeaders.push({
                    name: "Estatus",
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Switch checked={false}/>
                            );
                        }
                    }
                })
            }

            if(this.props.showEdit || this.props.showDelete){
                auxHeaders.push({
                    name: "Opciones",
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>
                                    <Tooltip title="Editar">
                                        <IconButton>
                                            <ModeEdit />
                                        </IconButton>
                                    </Tooltip>
                                    {
                                        this.props.showDelete ?
                                            <Tooltip title="Eliminar">
                                                <IconButton>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip> : null
                                    }
                                </div>
                            );
                        }
                    }
                })
            }

            this.setState({headers: auxHeaders}); 
        }

        //Get data
        const auxData = [...this.props.data];
        this.fetchData(auxData); 
    }*/

    //--------------------------------- Helpers 
    /*fetchData = (auxData) => {
        let newData = [];
        const auxRawData = [...auxData];  
        for(let x in auxData){
            let item = []; 
            Object.keys(auxData[x]).map(key => {
                if(auxData[x][key]){
                    item.push(auxData[x][key].toString()); 
                }else{
                    item.push("-"); 
                }
                return key; 
            }); 

            //For options column  
            if(this.props.showDelete || this.props.showEdit)
                item.push(JSON.stringify(auxData[x])); 
            
            newData.push(item); 
        } 

        this.setState({data: newData, rawData: auxRawData}); 
    }*/
    //--------------------------------- End Helpers
    
    //AHDA 21/05/2019: Added pagination
    //--------------------------------- Pagination 
    handleChangePage = (event, page) => {
        //dataTable.body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        this.setState({ page });
        if (event) {
            ls.set("tableProperties", { date: (new Date()).getTime(), page: page, rowsPerPage: this.state.rowsPerPage });
        }
    };
    
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        ls.set("tableProperties", { date: (new Date()).getTime(), page: this.state.page, rowsPerPage: event.target.value });
    };
    //--------------------------------- End pagination
    
    render() {

        const { classes, openForm, updateStatus, showDelete, canEdit, canDelete, data, headers } = this.props;
        const { page, rowsPerPage} = this.state; //AHDA 21/05/2019
        
        //AHDA 21/05/2019: Unused functionality 
        //const { data, headers } = this.state; 
        /*const options = {
            //filterType: 'dropdown',
            selectableRows: false,
            responsive: 'stacked',
            print: false,
            viewColumns: false, 
            download: false, 
            rowsPerPage: 10,
            page: 1,
            textLabels: textLabelsEs, 
            filter: false, 
            customToolbar: () => {
                return (
                  <Tooltip title={this.props.tooltip} placement="bottom" onClick={this.props.openForm}>
                    <IconButton aria-label={this.props.tooltip}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                );
            }
          };*/

        /*return (
            <div >
                <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                    title={this.props.title}
                    data={data}
                    columns={headers}
                    options={options}
                />
                </MuiThemeProvider>
            </div>
        ); */

        const renderRow = (item) => {
            let dataBodyVal = Object.values(item);
            return dataBodyVal.map((da, id) => {
                //GEO 10/04/2019
                // Se agrega clase para modificar width de los th y td de la tabla
                return (
                    <TableCell className={classes.widthCell} key={`cell_${id}`}>
                        {(() => {
                            switch (da) {
                                case 0:
                                    return <Switch disabled={!canEdit} checked={false} onClick={(event) => updateStatus(dataBodyVal, item,event)} id={`check_${Object.keys(item)[id]}`} value="checkedA" color="primary" />
                                case 1:
                                    return <Switch disabled={!canEdit} checked={true} onClick={(event) => updateStatus(dataBodyVal, item,event)} id={`check_${Object.keys(item)[id]}`} value="checkedA" color="primary" />
                                default:
                                    return da
                            }
                        })()}
                    </TableCell>
                )
            })
        }
        
        //AHDA 21/05/2019: Added Method for rendering body 
        const renderBody = (data) => {
            return data.map((dat, idx) =>
            (
                <TableRow key={`row_${idx}`}>
                    {renderRow(dat,canEdit)}
                    {/* GEO 10/04/2019
                    Se agrega clase para modificar width de los th y td de la tabla */}
                    <TableCell className={classes.widthCell}>
                        <div className={classes.actionsBtn}>
                            <Tooltip title="Editar">
                                <IconButton disabled={!canEdit} aria-label="Editar" onClick={() => openForm(dat)}>
                                    <ModeEdit />
                                </IconButton>
                            </Tooltip>
                            {
                                showDelete ?
                                    <Tooltip title="Eliminar">
                                        <IconButton disabled={!canDelete} aria-label="Eliminar" onClick={() => this.handleOpenSwal(dat)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip> : null
                            }
                        </div>
                    </TableCell>
                </TableRow>

            ))
        }

        return (

            <div className={classes.ajusteTabla}>
                <Table className={classNames(classes.table, classes.stripped)}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) =>
                                (
                                    <TableCell key={`header_${idx}`}>{header}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*AHDA 21/05/2019: Changed method for rendering tabke body*/}
                        {
                            data && renderBody(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                        }
                    </TableBody>

                    <SweetAlert
                        show={this.state.show}
                        title="¿Estás seguro?"
                        customClass={classes.alertDialog} 
                        text="Una vez que elimines este elemento, no se podrá recuperar"
                        showCancelButton
                        onConfirm={() => {
                            this.handleConfirmSwal();
                        }}
                        onCancel={() => {
                            this.setState({ show: false, selected: null });
                        }}
                    />
                </Table>
                {/*AHDA 21/05/2019: Added pagination*/}
                <TablePagination
                    rowsPerPageOptions={[10, 15, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                    'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                    'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage = {"Filas por página"}
                />
            </div>
        )
    }
}


TableCatalogue.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    openForm: PropTypes.func,
    deleteItem: PropTypes.func,
    updateStatus: PropTypes.func,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool,
    showDelete: PropTypes.bool
};

TableCatalogue.defaultProps = {
    openForm: () => { },
    deleteItem: () => { },
    updateStatus: () => { },
    canEdit:true,
    canDelete:true,
    showDelete: true
}

export default withStyles(styles)(TableCatalogue);