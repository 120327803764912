import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import brand from '../../../utils/brand';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import Favorite from '@material-ui/icons/Favorite';
import Collections from '@material-ui/icons/Collections';
import PermMedia from '@material-ui/icons/PermMedia';
import Cover from './../../../components/Cover/Cover';
import PaginationCard from './../../../components/Profile/PaginationCards';
import { LanguageConsumer } from '../../../language/LanguageContext';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';
import ProductDetail from '../../../components/Gallery/ProductDetail';
import Loader from '../../../components/Loader/Loader';

// Expandible
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './perfil-jss'
import ls from 'local-storage';

import { apiPOST, returnCurrentUserId, returnCurrentUser } from '../../../services/service_base';

//TAB CONTAINER
function TabContainer(props) {
  return (
    <div style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class UserProfile extends React.Component {
  
  //AHDA 30/04/2019
  //Added permissions
  state = {
    value: 0,
    expanded: 'panel1',
    //View detail
    open: false,
    selected: 0,
    selectedProduct: [],
    detailWork: 0,
    loading: false,
    //Notifications
    openError: false,
    messageError: 'errMsg',
    variantError: 'error',
    //Sections
    favorites: [],
    prospects: [],
    projects: [],
    historicProjects: [],
    //Permissions 
    chkFavorite: false,
    chkProductionGeneralView: false,
    chkProspect: false,
    chkSalesGeneralView: false, 
    chkApplyForMaterial: false,
    chkAssignProject: false,
    chkEndWork: false,
    chkShareMaterial: false,
    chkUnlockWork: false,
    chkViewWorkBlock: false, 
    chkViewScripts: false,
    productor:0
  };

  //Expandables
  handleChangeExpand = panel => (event, expanded) => {

    //Get projects
    switch (panel) {
      //AHDA 24/04/2019
      //Added clasification param
      case 'panel1':
        this.handleRequest('/user/getProjects', 'projects', { user: returnCurrentUserId(), status: 1, clasification: this.state.clasification });
        break;
      case 'panel2':
        this.handleRequest('/user/getProjects', 'historicProjects', { user: returnCurrentUserId(), status: 2, clasification: this.state.clasification });
        break;
      default:
        break;
    }

    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  //Tabs
  handleChange = (event, value) => {
    //AHDA 24/04/2019
    //Added clasification param
    switch (value) {
      case 0:
        this.handleRequest('/user/getFavorites', 'favorites', { user: returnCurrentUserId(), clasification: this.state.clasification, production:this.state.productor });
        break;
      case 1:
        this.handleRequest('/user/getProspects', 'prospects', { user: returnCurrentUserId(), clasification: this.state.clasification, production:this.state.productor });
        break;
      case 2:
        this.handleRequest('/user/getProjects', 'projects', { user: returnCurrentUserId(), status: 1, clasification: this.state.clasification, production:this.state.productor });
        break;
      default:
        break;
    }
    this.setState({ value });
  };

  //Detail open
  handleDetailOpen = (product) => {

    this.setState(
      {
        detailWork : 0
      }, () => {
        //MARK AS SEARCH IN AUDIT
        const request = {
          user: returnCurrentUserId(),
          idObra: product.id_obras,
          screen: 'profile'
        }
        apiPOST('/obras/markAsSearch', request);

        //IF IS OPEN JUST CHANGE DATA. 
        if (this.state.open) {
          this.setState({ selected: product.id_obras, selectedProduct: product, loading: true });
        } else {
          this.setState({ open: true, selected: product.id_obras, selectedProduct: product, loading: true });
        }

        //AHDA 24/04/2019 
        //Added clasification param 
        //GET WORK DETAIL 
        const requestGetObra = {
          idObra: product.id_obras, 
          clasification: this.state.clasification
        }
        apiPOST('/obras/getDetail', requestGetObra).then(response => {
          if (response.status === 200) {
            this.setState({ detailWork: response.data, loading: false })
          } else {
            this.setState({ openError: true, loading: false, open: false });
          }
        }).catch(error => {
          this.setState({ openError: true, loading: false, open: false });
        });
      }
    )
  }

  //Close detail
  handleClose = () => {
    this.setState({ open: false, selected: 0, selectedProduct: [] });
  };

  //FOR PROSPECTS
  handleNotifications = () => {
    this.setState({ openError: true, messageError: 'errProspects', variantError: 'info' });
  }

  handleCloseNotification = () => {
    this.setState({ openError: false });
  }

    //------------------------ Refreshing Favorites and Prospects 
    handleAddTo = (work, type, added, external) => {

      //AHDA 19/06/2019: Added validation for external projects
      let auxProjects = this.state.projects;
      auxProjects = added ? auxProjects + 1 : auxProjects - 1;
      if (type === 101 && external) {
        this.setState({ projects: auxProjects });
        return false;
      }
  
      //AHDA 19/06/2019: Validation for only favorites, prospects, projects and searchs
      if (this.state.data.id_categoria === 100 || this.state.data.id_categoria === 101 || this.state.data.id_categoria === 102 || this.state.data.id_categoria === 103) {
        let auxData = [...this.state.data.obras];
        let auxFilteredData = [...this.state.filteredData.obras]
  
        const auxIdx = auxData.findIndex(w => w.id_obras === work.id_obras);
        const auxFilterIdx = auxFilteredData.findIndex(w => w.id_obras === work.id_obras);
  
        if (added) {
          if (auxIdx === -1) {
            auxData.splice(0, 0, work);
          }
  
          if (auxFilterIdx === -1) {
            auxFilteredData.splice(0, 0, work);
          }
        } else {
          if (auxIdx > -1) {
            auxData.splice(auxIdx, 1);
          }
  
          if (auxFilterIdx > -1) {
            auxFilteredData.splice(auxFilterIdx, 1);
          }
        }
  
        this.setState({
          data: { ...this.state.data, obras: auxData },
          category: { ...this.state.category, total: auxData.length },
          projects: auxProjects
        })
      }
  
    }

  componentDidMount() {
    
    let clasification = "none", production = 0; 

    //AHDA 24/04/2019
    //Added permissions
    if(ls.get("user_permissions")!= null){
      let config = ls.get("user_permissions").filter(x => x.menu_id === 1)[0];
      let chk = config ? JSON.parse(config.grant_configuration) : undefined;
      
      let configDetail = ls.get("user_permissions").filter(x => x.menu_id === 4)[0];
      let chkDetail = configDetail ? JSON.parse(configDetail.grant_configuration) : undefined;

      if(chk.chkProductionGeneralView && !chk.chkSalesGeneralView){
        production = 1;
      }
      if (ls.get("user_object").rdsUser.asigned_profile === "Productor") {
        production = 1;
      }
      
      this.setState({
          chkFavorite: chk ? chk.chkFavorite : false,
          chkProductionGeneralView: chk ? chk.chkProductionGeneralView : false,
          chkProspect: chk ? chk.chkProspect : false,
          chkSalesGeneralView: chk ? chk.chkSalesGeneralView : false,
          chkApplyForMaterial: chkDetail ? chkDetail.chkApplyForMaterial : false,
          chkAssignProject: chkDetail ? chkDetail.chkAssignProject : false,
          chkEndWork: chkDetail ? chkDetail.chkEndWork : false,
          chkShareMaterial: chkDetail ? chkDetail.chkShareMaterial : false,
          chkUnlockWork: chkDetail ? chkDetail.chkUnlockWork : false,
          chkViewWorkBlock: !chkDetail ? false : chkDetail.chkViewWorkBlock ? chkDetail.chkViewWorkBlock : false,
          chkViewScripts: chkDetail ? chkDetail.chkViewScripts : false,
          chkViewSinopsis: !chkDetail ? false : chkDetail.chkViewSinopsis ? chkDetail.chkViewSinopsis : false,
          chkViewPersonaje: !chkDetail ? false : chkDetail.chkViewPersonaje ? chkDetail.chkViewPersonaje : false,
          chkViewEval: !chkDetail ? false : chkDetail.chkViewEval ? chkDetail.chkViewEval : false,
          chkViewMaterial: !chkDetail ? false : chkDetail.chkViewMaterial ? chkDetail.chkViewMaterial : false,
          chkViewMoreInfo: !chkDetail ? false : chkDetail.chkViewMoreInfo ? chkDetail.chkViewMoreInfo : false,
          chkViewVigencia: !chkDetail ? false : chkDetail.chkViewVigencia ? chkDetail.chkViewVigencia : false,
          chkViewEquiposLit: !chkDetail ? false : chkDetail.chkViewEquiposLit ? chkDetail.chkViewEquiposLit : false,
          chkDownloadFT: !chkDetail ? false : chkDetail.chkDownloadFT ? chkDetail.chkDownloadFT : false,
          clasification,
          productor: production
      });    
    }else{
      this.setState({
        clasification: clasification
      });    
    }

    //Get Favorites
    this.handleRequest('/user/getFavorites', 'favorites', { user: returnCurrentUserId(), clasification, production});
  }

  //AHDA 22/02/2019
  handleRequest = (path, section, body) => {
    if (this.state[section].length <= 0) {
      this.setState({loading: true})
      apiPOST(path, body).then(
        response => {
          if (response.status === 200) {
            console.log(response.data)
            this.setState({[section] : response.data.works, loading: false}); 
          }else{
            this.setState({openError: true, messageError: 'errMsg', variantError: 'error', loading: false})
          }
        }
      ).catch(
        err => {
          this.setState({openError: true, messageError: 'errMsg', variantError: 'error', loading: false})
        }
      )
    }
  }

  render() {
    const title = brand.name + ' - Perfil';
    const description = brand.desc;
    const { 
      value, 
      expanded, 
      open, 
      chkProductionGeneralView, //AHDA 03/06/2019
      chkSalesGeneralView //AHDA 03/06/2019
    } = this.state;
    const { classes, } = this.props;
    const user = returnCurrentUser();

    return (
      <div>
        <LanguageConsumer>
          {
            ({ lang }) =>
              <React.Fragment>
                <Helmet>
                  <title>{title}</title>
                  <meta name="description" content={description} />
                  <meta property="og:title" content={title} />
                  <meta property="og:description" content={description} />
                  <meta property="twitter:title" content={title} />
                  <meta property="twitter:description" content={description} />
                </Helmet>

                {
                  this.state.detailWork !== 0 &&
                  <ProductDetail
                    open={open}
                    close={this.handleClose}
                    detailContent={this.state.selectedProduct}
                    productIndex={this.state.selected}
                    detailWork={this.state.detailWork}
                    handleDetailOpen={this.handleDetailOpen}
                    loading={this.state.loading}
                    canFavorite={this.state.chkFavorite}
                    canAddProspect={this.state.chkProspect}
                    canApplyForMaterial={this.state.chkApplyForMaterial}
                    canAssignProyect={this.state.chkAssignProject}
                    canEndWork={this.state.chkEndWork}
                    canShareMaterial={this.state.chkShareMaterial}
                    canUnlockWork={this.state.chkUnlockWork}
                    canViewWorkBlock={this.state.chkViewWorkBlock}
                    chkViewScripts={this.state.chkViewScripts}
                    chkViewSinopsis={this.state.chkViewSinopsis}
                    chkViewPersonaje={this.state.chkViewPersonaje}
                    chkViewEval={this.state.chkViewEval}
                    chkViewMaterial={this.state.chkViewMaterial}
                    chkViewMoreInfo={this.state.chkViewMoreInfo}
                    chkViewVigencia={this.state.chkViewVigencia}
                    chkViewEquiposLit={this.state.chkViewEquiposLit}
                    chkDownloadFT={this.state.chkDownloadFT}
                    chkProductionGeneralView={this.state.chkProductionGeneralView}
                    chkSalesGeneralView={this.state.chkSalesGeneralView}
                    productor={this.state.productor}
                    screen={"profile"}
                    addToList={this.handleAddTo}
                  />
                }
                
                {/* CH 10/05/2019
                Se oculta la portada de perfil */}
                {/* <Cover
                  avatar={!user ? undefined : !user.portrait ? undefined : user.portrait  }
                  name={`${user ? user.nombre : ""} ${user ? user.apellidos : ""}`}
                /> */}
                <AppBar className="marginTopLogo" position="static" color="default">
                  <Hidden mdUp>
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleChange}
                      fullWidth
                      centered
                      indicatorColor="primary"
                      textColor="primary"
                      className={classes.tabsContainer}
                    >
                      <Tab icon={<Favorite />} />
                      <Tab icon={<Collections />} />
                      <Tab icon={<PermMedia />} />
                    </Tabs>
                  </Hidden>
                  <Hidden smDown>
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleChange}
                      fullWidth
                      centered
                      indicatorColor="primary"
                      textColor="primary"
                      className={classes.tabsContainer}
                    >
                      <Tab icon={<Favorite />} label="FAVORITOS" />
                      <Tab icon={<Collections />} label="MIS PROSPECTOS" />
                      <Tab icon={<PermMedia />} label="MIS PROYECTOS" />
                    </Tabs>
                  </Hidden>
                </AppBar>
                {value === 0 &&
                  <TabContainer>
                    <Typography variant="headline" component="h2" className={classes.title}>
                      Favoritos<small> ( {this.state.favorites.length} titulos)</small>
                    </Typography>
                    <div className={classes.tabContentContainer}>
                      {/*AHDA 03/06/2019: Added chkProductionGeneralView, chkSalesGeneralView*/}
                      {
                        this.state.loading ? <Loader text=""/> :
                        this.state.favorites.length > 0 ? 
                        <PaginationCard 
                        works={this.state.favorites} 
                        paginate={true}
                        handleNotifications={this.handleNotifications}
                        handleDetailOpen={this.handleDetailOpen}
                        canFavorite={this.state.chkFavorite}
                        imagenLarge={true}
                        canAddProspect={this.state.chkProspect}
                        canViewWorkBlock={this.state.chkViewWorkBlock}
                        chkProductionGeneralView={chkProductionGeneralView}
                        chkSalesGeneralView={chkSalesGeneralView} /> : 
                        <Typography className={classes.info}>No hay ninguna obra en esta sección</Typography>
                      }
                    </div>
                  </TabContainer>}
                {value === 1 &&
                  <TabContainer>
                    <Typography variant="headline" component="h2" className={classes.title}>
                      Mis Prospectos<small> ({this.state.prospects.length} títulos)</small>
                    </Typography>
                    <div className={classes.tabContentContainer}>
                      {/*AHDA 03/06/2019: Added chkProductionGeneralView, chkSalesGeneralView*/}
                    {
                      this.state.loading ? <Loader text=""/> :
                      this.state.prospects.length > 0 ? 
                      <PaginationCard works={this.state.prospects} paginate={false}
                        handleNotifications={this.handleNotifications}
                        handleDetailOpen={this.handleDetailOpen} 
                        canFavorite={this.state.chkFavorite}
                        canAddProspect={this.state.chkProspect}
                        imagenLarge={true}
                        canViewWorkBlock={this.state.chkViewWorkBlock}
                        chkProductionGeneralView={chkProductionGeneralView}
                        chkSalesGeneralView={chkSalesGeneralView}/> :
                        <Typography className={classes.info}>No hay ninguna obra en esta sección</Typography>
                    }
                    </div>
                  </TabContainer>}
                {value === 2 &&
                  <TabContainer>
                    <div className={classes.root}>
                      {/* NO funciona el defaultExpanded */}
                      <ExpansionPanel defaultExpanded expanded={expanded === 'panel1'} onChange={this.handleChangeExpand('panel1')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.heading}>Proyecto(s) Actual(es)</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div className={classes.tabContentContainer}>
                          {/*AHDA 03/06/2019: Added chkProductionGeneralView, chkSalesGeneralView*/}
                          {
                            this.state.loading ? <Loader text=""/> :
                            this.state.projects.length > 0 ? 
                            <PaginationCard 
                              works={this.state.projects} 
                              paginate={false}
                            // list={true}
                              handleNotifications={this.handleNotifications}
                              //CH 21/06/2019: Added Imagen Large
                              imagenLarge={true}
                              handleDetailOpen={this.handleDetailOpen} 
                              canFavorite={this.state.chkFavorite}
                              canAddProspect={this.state.chkProspect}
                              canViewWorkBlock={this.state.chkViewWorkBlock}
                              chkProductionGeneralView={chkProductionGeneralView}
                              chkSalesGeneralView={chkSalesGeneralView}/> : 
                              <Typography className={classes.info}>No hay ninguna obra en esta sección</Typography>
                          }
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      <ExpansionPanel expanded={expanded === 'panel2'} onChange={this.handleChangeExpand('panel2')}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                          <Typography className={classes.heading}>Historial de Mis Proyectos</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          {/*AHDA 03/06/2019: Added chkProductionGeneralView, chkSalesGeneralView*/}
                          <div className={classes.tabContentContainer}>
                          {
                            this.state.loading ? <Loader text=""/> :
                            this.state.historicProjects.length > 0 ? 
                            <PaginationCard
                            works={this.state.historicProjects} 
                            paginate={true} 
                            contentsPerPage={4}
                            handleNotifications={this.handleNotifications}
                            handleDetailOpen={this.handleDetailOpen}
                            showDate={true}
                            //CH 21/06/2019: Added Imagen Large
                            imagenLarge={true}
                            canFavorite={this.state.chkFavorite}
                            canAddProspect={this.state.chkProspect}
                            canViewWorkBlock={this.state.chkViewWorkBlock}
                            chkProductionGeneralView={chkProductionGeneralView}
                            chkSalesGeneralView={chkSalesGeneralView} /> : 
                            <Typography className={classes.info}>No hay ninguna obra en esta sección</Typography>
                          }
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  </TabContainer>}

                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleCloseNotification}>
                  <CustomNotification
                    onClose={this.handleCloseNotification}
                    variant={this.state.variantError}
                    message={lang.common[`${this.state.messageError}`]} />
                </Snackbar>
              </React.Fragment>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

UserProfile.propTypes = {
  dataProps: PropTypes.object,
  fetchData: PropTypes.func,
  imagenLarge: PropTypes.bool
};

export default withStyles(styles)(UserProfile);
