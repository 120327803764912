import PropTypes from 'prop-types';
import React from 'react';
import { default as MaterialButton } from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { style } from './style';
import classNames from 'classnames';

const Button = (props) => {
    const { className, size, children, href, classes, block, onClick, variant, color, disabled, rounded } = props;
    return <React.Fragment>
        <MaterialButton
            disabled={disabled}
            href={href}
            onClick={onClick}
            className={classNames(classes.button, className, rounded && classes.rounded)}
            fullWidth={block}
            variant={variant || 'contained'}
            color={color || 'primary'}
            size={size || 'medium'}>
            {children}
        </MaterialButton>
    </React.Fragment>;
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    block: PropTypes.bool,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    rounded: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    size: PropTypes.oneOf('small', 'medium', 'large'),
    variant: PropTypes.oneOf('text', 'flat', 'outlined', 'contained', 'raised', 'fab', 'extendedFab'),
    color: PropTypes.oneOf('inherit', 'primary', 'secondary', 'default')
};

export default withStyles(style, { withTheme: true })(Button);
