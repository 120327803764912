//Module by AHDA 19/02/2019
import React from 'react';
//import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from './../styles-jss'
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Loader from '../../../../components/Loader/Loader';
import { withUIContext } from '../../../../context';

//import MaskedInput from 'react-text-mask';
//Table
import AddCatalogue from '../../../../components/Catalogue/AddCatalogue';
import Header from '../../../../components/Catalogue/Header';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';

//Form
import { TextValidator } from 'react-material-ui-form-validator';

//Services
import { apiElasticSearch, apiPOST } from '../../../../services/service_base';
import { getObjectById, getfilteredDataAllKeys } from '../../../../helpers/general';

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

class Catalogo extends React.Component {
    state = {
        //Open form
        openForm: false,
        //Table
        headers: ["ID", "Nombre Completo", "Nombre artístico", "Nacionalidad", "Correo electrónico", "Celular", "Domicilio", "Estatus", "Opciones"],
        rawData: [],
        data: [],
        //Selected catalogue item
        selected: null,
        proNombre: "",
        proSegundoNombre: "",
        proApellidoP: "",
        proApellidoM: "",
        proNombreArtistico: "",
        proNacionalidad: "",
        proCorreo: "",
        proCelular: "",
        proDomicilio: "",
        matchValue: "",
        //Error 
        openError: false,
        errType: "error", //AHDA 31/05/2019
        errMsg: "Ocurrió un error, intenta de nuevo más tarde", //AHDA 31/05/2019
        isLoading: false,
        chkDeleteCatalog: false,
        chkEditCatalog: false,
        chkNewCatalog: false
    };


    //Open and close form
    handledOpenForm = (item) => {
        this.handleClear();
        if (item !== undefined) {
            let auxItem = this.state.rawData.find(it => it.id === item.id);
            this.setState({
                openForm: true,
                proNombre: auxItem.primer_nombre,
                proSegundoNombre: auxItem.segundo_nombre,
                proApellidoP: auxItem.apellido_paterno,
                proApellidoM: auxItem.apellido_materno,
                proNombreArtistico: auxItem.nombre_artistico,
                proNacionalidad: auxItem.nacionalidad,
                proCorreo: auxItem.correo_electronico,
                proCelular: auxItem.celular,
                proDomicilio: auxItem.domicilio,
                matchValue: [auxItem.primer_nombre, auxItem.segundo_nombre, auxItem.apellido_paterno, auxItem.apellido_materno].filter(e => e != null).join(" "),
                selected: auxItem
            });
        }
        else {
            this.setState({
                openForm: true,
                proNombre: "",
                proSegundoNombre: "",
                proApellidoP: "",
                proApellidoM: "",
                proNombreArtistico: "",
                proNacionalidad: "",
                proCorreo: "",
                proCelular: "",
                proDomicilio: "",
                selected: null
            });
        }
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    //Input
    handleChange = (event) => {
        //AHDA 04/06/2019
        if (event.target.value.indexOf('\'') <= -1) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleClear = (event) => {
        this.setState({
            proNombre: "",
            proSegundoNombre: "",
            proApellidoP: "",
            proApellidoM: "",
            proNombreArtistico: "",
            proNacionalidad: "",
            proCorreo: "",
            proCelular: "",
            proDomicilio: "",
        });
    }

    //Notifications
    handleClose = () => {
        this.setState({ openError: false });
    }

    //Catalogue CRUD
    handleBodyRequest = (item, status) => {
        let auxItem = getObjectById(this.state.rawData, item);
        if (auxItem) {
            return {
                idCatalog: 17,
                id_record: auxItem.id,
                new_first_name: auxItem.primer_nombre,
                new_second_name: auxItem.segundo_nombre,
                new_last_name: auxItem.apellido_paterno,
                new_mothers_last_name: auxItem.apellido_materno,
                new_stage_name: auxItem.nombre_artistico,
                new_nationality: auxItem.nacionalidad,
                new_address: auxItem.domicilio,
                new_email: auxItem.correo_electronico,
                new_mobile: auxItem.celular,
                new_status: status
            }
        } else {
            return {
                idCatalog: 17,
                id_record: item.id,
                new_first_name: "",
                new_second_name: "",
                new_last_name: "",
                new_mothers_last_name: "",
                new_stage_name: "",
                new_nationality: "",
                new_address: "",
                new_email: "",
                new_mobile: "",
                new_status: status
            }
        }
    }

    handleDeleteItem = (item) => {

        const body = this.handleBodyRequest(item, 2);

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {
                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id),
                        auxRawIndex = this.state.data.findIndex(itm => itm.id === item.id);

                    let auxData = [...this.state.data], auxRawData = [...this.state.rawData];

                    auxData.splice(auxIndex, 1);
                    auxRawData.splice(auxRawIndex, 1);

                    this.setState({ data: auxData, selected: undefined, openForm: false, isLoading: false });
                }
                else {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    handleUpdateStatus = (data, item) => {

        const body = this.handleBodyRequest(item, item.estatus === 1 ? 0 : 1);

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {
                    let auxData = [...this.state.data], auxRawData = [...this.state.rawData];
                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id), auxRawIndex = this.state.rawData.findIndex(itm => itm.id === item.id);

                    auxData[auxIndex].estatus = response.data[0].estatus;
                    auxRawData[auxRawIndex].estatus = response.data[0].estatus;

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                }
                else {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    //Catalogue CRUD
    handleSubmit = (event) => {
        event.preventDefault();

        let body = {
            idCatalog: 17,
            "id_record": this.state.selected ? this.state.selected.id : 0,
            "new_first_name": this.state.proNombre.trim(),
            "new_second_name": this.state.proSegundoNombre.trim(),
            "new_last_name": this.state.proApellidoP.trim(),
            "new_mothers_last_name": this.state.proApellidoM.trim(),
            "new_stage_name": this.state.proNombreArtistico,
            "new_nationality": this.state.proNacionalidad,
            "new_email": this.state.proCorreo,
            "new_mobile": this.state.proCelular,
            "new_address": this.state.proDomicilio,
            "new_status": this.state.selected ? this.state.selected.estatus : 1
        }

        //Call to lambda
        let auxData = [...this.state.data];
        let auxRawData = [...this.state.rawData];

        if (this.state.selected) {
            this.setState({ isLoading: true });
            apiPOST('/catalogue/update', body).then(
                response => {
                    if (response.status === 200) {
                        apiElasticSearch('/catalog/update', { catalogName: "autores", matchValue: this.state.matchValue, newValue: [body.new_first_name, body.new_second_name,body.new_last_name,body.new_mothers_last_name].filter(e => e != null).join(" ") })
                            .finally(() => {
                                //console.log(response); 
                                let auxItem = response.data[0];
                                let auxIndex = this.state.data.findIndex(itm => itm.id === this.state.selected.id);

                                auxRawData[auxIndex] = auxItem;

                                //AHDA 22/05/2019: Validation for not required fields 
                                auxItem = {
                                    "id": auxItem.id,
                                    "nombre_completo": `${auxItem.primer_nombre} ${auxItem.segundo_nombre ? auxItem.segundo_nombre : ""} ${auxItem.apellido_paterno} ${auxItem.apellido_materno ? auxItem.apellido_materno : ""}`,
                                    "nombre_artistico": auxItem.nombre_artistico,
                                    "nacionalidad": auxItem.nacionalidad,
                                    "correo_electronico": auxItem.correo_electronico,
                                    "celular": auxItem.celular,
                                    "domicilio": auxItem.domicilio,
                                    "estatus": auxItem.estatus
                                }
                                auxData[auxIndex] = auxItem;

                                this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                            })
                    }
                    else {
                        //console.log(response)
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //.log(err); 
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        } else {

            //AHDA 31/05/2019: Existing data validation
            const existsItem = auxRawData.find(i => {
                const auxItemSN = i.segundo_nombre ? i.segundo_nombre.toUpperCase() : "",
                    auxStateSN = this.state.proSegundoNombre ? this.state.proSegundoNombre.toUpperCase() : "",
                    auxItemAM = i.apellido_materno ? i.apellido_materno.toUpperCase() : "",
                    auxStateAM = this.state.proApellidoM ? this.state.proApellidoM.toUpperCase() : "";

                if (
                    i.primer_nombre.toUpperCase() === this.state.proNombre.toUpperCase() &&
                    auxItemSN === auxStateSN &&
                    i.apellido_paterno.toUpperCase() === this.state.proApellidoP.toUpperCase() &&
                    auxItemAM === auxStateAM
                ) {
                    return i;
                }
            });

            if (existsItem) {
                this.setState({ openError: true, errType: "warning", errMsg: "Ya existe una opción con este nombre, favor de validar" });
                return false;
            }

            this.setState({ isLoading: true });

            apiPOST('/catalogue/add', body).then(
                response => {
                    if (response.status === 200) {
                        let auxItem = response.data[0];
                        auxRawData.push(auxItem);

                        //AHDA 22/05/2019: Validation for not required fields 
                        auxItem = {
                            "id": auxItem.id,
                            "nombre_completo": `${auxItem.primer_nombre} ${auxItem.segundo_nombre ? auxItem.segundo_nombre : ""} ${auxItem.apellido_paterno} ${auxItem.apellido_materno ? auxItem.apellido_materno : ""}`,
                            "nombre_artistico": auxItem.nombre_artistico,
                            "nacionalidad": auxItem.nacionalidad,
                            "correo_electronico": auxItem.correo_electronico,
                            "celular": auxItem.celular,
                            "domicilio": auxItem.domicilio,
                            "estatus": auxItem.estatus
                        }

                        auxData.push(auxItem);

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    } else {
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        }
    }

    //AHDA 22/05/2019
    //------------------------------- Search
    handleSearch = (search) => {
        let key = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        //Get all
        if (key === "") {
            this.setState({ data: this.getTableData([...this.state.rawData]) });
            return false;
        }

        let auxData = [...this.state.rawData];

        auxData = getfilteredDataAllKeys(auxData, key);
        this.setState({ data: this.getTableData(auxData) });
    }
    //------------------------------- End Search

    //AHDA 22/05/2019
    //------------------------------- Helpers
    getTableData = (data) => {
        let auxData = [];
        if (data.length > 0) {
            for (const x in data) {
                let author = data[x];
                author = {
                    "id": author.id,
                    "nombre_completo": `${author.primer_nombre ? author.primer_nombre : ''} ${author.segundo_nombre ? author.segundo_nombre : ''} ${author.apellido_paterno ? author.apellido_paterno : ''} ${author.apellido_materno ? author.apellido_materno : ''}`,
                    "nombre_artistico": author.nombre_artistico ? author.nombre_artistico : '',
                    "nacionalidad": author.nacionalidad ? author.nacionalidad : '',
                    "correo_electronico": author.correo_electronico ? author.correo_electronico : '',
                    "celular": author.celular ? author.celular : '',
                    "domicilio": author.domicilio ? author.domicilio : '',
                    "estatus": author.estatus
                }
                auxData.push(author);
            }
        }

        return auxData;
    }
    //------------------------------- End Helpers

    componentDidMount() {
        //Set loading 
        let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 6)[0];
        let configPermission = JSON.parse(config.grant_configuration);

        this.setState({
            isLoading: true,
            chkDeleteCatalog: configPermission.chkDeleteCatalog,
            chkEditCatalog: configPermission.chkEditCatalog,
            chkNewCatalog: configPermission.chkNewCatalog
        });

        apiPOST('/catalogue/getComplete', { idCatalog: 17 }).then(
            response => {
                if (response.status === 200) {
                    //AHDA 22/05/2019: Adjustments for search
                    this.setState({ isLoading: false, rawData: response.data, data: this.getTableData(response.data) });
                } else {
                    this.setState({ isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", });
                }
            }
        ).catch(
            err => {
                this.setState({ isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", });
            }
        )
    }

    render() {
        const { classes, uicontext } = this.props;

        return (
            <div className="marginTopLogo">

                <Paper className={classes.root}>
                    <Header
                        title="Autores/Adaptadores"
                        tooltip="Nuevo Autor/Adaptador"
                        textBtn="Nuevo"
                        openForm={() => this.handledOpenForm(undefined)}
                        canAdd={this.state.chkNewCatalog}
                        search={this.handleSearch}
                        catalogue={17}
                    />
                    {
                        this.state.isLoading ?
                            <Loader text={"Cargando"} /> :
                            <TableCatalogue
                                headers={this.state.headers}
                                data={this.state.data}
                                openForm={this.handledOpenForm}
                                deleteItem={this.handleDeleteItem}
                                updateStatus={this.handleUpdateStatus}
                                canEdit={this.state.chkEditCatalog}
                                canDelete={this.state.chkDeleteCatalog}
                            />
                    }
                </Paper>
                {
                    uicontext.state.isCatalogEdit ? null :
                        <AddCatalogue
                            openForm={this.state.openForm}
                            closeForm={this.handledCloseForm}
                            classes={classes}
                            submit={this.handleSubmit}
                            clearForm={this.handleClear}
                            title="Autor/Adaptador"
                            catalogue={17}
                        >

                            <div>
                                <Grid container spacing={24}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Primer Nombre*"
                                            placeholder="Ej. María"
                                            id="proNombre"
                                            name="proNombre"
                                            value={this.state.proNombre}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['required', 'isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['Campo requerido', 'Campo requerido', 'No se permiten comillas simples']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Segundo Nombre"
                                            placeholder="Ej. Magdalena"
                                            id="proSegundoNombre"
                                            name="proSegundoNombre"
                                            value={this.state.proSegundoNombre}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['No se permiten campos vacíos', 'No se permiten comillas simples']}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Apellido Paterno*"
                                            placeholder="Ej. Rodriguez"
                                            id="proApellidoP"
                                            name="proApellidoP"
                                            value={this.state.proApellidoP}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['required', 'isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['Campo requerido', 'Campo requerido', 'No se permiten comillas simples']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Apellido Materno"
                                            placeholder="Ej. Pérez"
                                            id="proApellidoM"
                                            name="proApellidoM"
                                            value={this.state.proApellidoM}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['No se permiten campos vacíos', 'No se permiten comillas simples']}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Nombre Artistico"
                                            placeholder="Ej. La Chona"
                                            id="proNombreArtistico"
                                            name="proNombreArtistico"
                                            value={this.state.proNombreArtistico}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['No se permiten campos vacíos', 'No se permiten comillas simples']}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Nacionalidad*"
                                            placeholder="Ej. Mexicana"
                                            id="proNacionalidad"
                                            name="proNacionalidad"
                                            value={this.state.proNacionalidad}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['required', 'isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['Campo requerido', 'Campo requerido', 'No se permiten comillas simples']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 20 }
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Correo electrónico"
                                            placeholder="Ej. ejemplo@ejemplo.com"
                                            id="proCorreo"
                                            name="proCorreo"
                                            value={this.state.proCorreo}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isEmail']}
                                            errorMessages={['Correo electrónico invalido']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    // JCMY 04/07/2019 incidencia 604
                                                    inputProps: { maxLength: 50 }
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {/*AHDA 29/04/2019: Added custom validation and InputProps*/}
                                        <TextValidator
                                            label="Número celular"
                                            placeholder="Ej. 4771586568"
                                            id="proCelular"
                                            name="proCelular"
                                            value={this.state.proCelular}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isCell', 'isSpaceEmpty']}
                                            errorMessages={['El número debe ser de 10 0 13 dígitos', 'No se permiten campos vacíos']}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 13 }
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {/*AHDA 02/04/2019
                            Empty string validation*/}
                                        <TextValidator
                                            label="Domicilio"
                                            placeholder="Ej. Valle de Bravo 215"
                                            id="proDomicilio"
                                            name="proDomicilio"
                                            value={this.state.proDomicilio}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChange}
                                            validators={['isSpaceEmpty', 'hasQuote']}
                                            errorMessages={['No se permiten campos vacíos', 'No se permiten comillas simples']}
                                            InputProps={
                                                {
                                                    inputProps: { maxLength: 100 }
                                                }
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </AddCatalogue>
                }
                {/*For notifications*/}
                {/*AHDA 31/05/2019: changed variant and message*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <CustomNotification
                        onClose={this.handleClose}
                        variant={this.state.errType}
                        message={this.state.errMsg} />
                </Snackbar>
            </div>
        )
    }
}

export default withUIContext(withStyles(styles)(Catalogo));