import {
    Collapse,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography,
    withStyles,
} from '@material-ui/core';
import { MultiDataList, MultiList } from '@appbaseio/reactivesearch';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { default as React, useState, useEffect } from 'react';
import ls from 'local-storage';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { UIContextActions, withUIContext } from '../../context';
import styles from './search-jss';
import './style.css';
import { apiPOST } from '../../services/service_base';
import { gaEvents } from '../../services/gaEvents';
import { filterByAcervoActives } from '../../utils/validation';

const SearchDrawer = ({ uicontext, classes, history, filter }) => {
    const [catalogs, setCatalogs] = useState(ls.get("catalogue_list"));
    const [authors, setAuthors] = useState([])

    useEffect(() => {

        setCatalogs(ls.get("catalogue_list"));

        apiPOST('/catalogue/get', { idCatalogo: 24 }, true).then(
            response => {
                if (response.status === 200) {
                    setAuthors(response.data.map(
                        item => {
                            const value = item.label.split(" ").filter(e => e !== "")
                            return {
                                label: item.label,
                                value: item.label
                            }
                        }
                    ))
                }
            }
        )
    }, []);

    const handleClick = (key, value) => {
        gaEvents.eventFilter(key, value.join(","))
        if (history) {
            history.push(`/acervo/search-result?filter=${key}_${value.join(",")}_`);
        }
    }

    return (
        <>
            <Drawer
                anchor="right"
                variant="persistent"
                open={uicontext.state.searchDrawer}
                onClose={() => UIContextActions.toggleDrawer(uicontext)}
                className={classes.drawer}>
                <div className={classes.fixedTitle}>
                    <Tooltip title="Ocultar Filtros">
                        <IconButton onClick={() => UIContextActions.toggleDrawer(uicontext)}>
                            {<ChevronRightIcon />}
                        </IconButton>
                    </Tooltip>
                    <Typography variant="title" component="h5" className={classes.title}>
                        Filtros
                    </Typography>
                </div>
                <div className={classes.filterContainer}>
                    {catalogs &&
                        <div className={classes.filterForm}>
                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 5).cat_name}`}>
                                <MultiList
                                    componentId="GenreSensor"
                                    dataField="genero.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 5).cat_name}`}
                                    onValueChange={(e) => handleClick('genero', e)}
                                    defaultValue={(filter && filter.keyword === "genero") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 6).cat_name}`}>
                                <MultiList
                                    componentId="SubgenreSensor"
                                    dataField="subgenero.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 6).cat_name}`}
                                    onValueChange={(e) => handleClick('subgenero', e)}
                                    defaultValue={(filter && filter.keyword === "subgenero") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 22).cat_name}`}>
                                <MultiList
                                    componentId="HorarioSugSensor"
                                    dataField="horario_sugerido.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 22).cat_name}`}
                                    onValueChange={(e) => handleClick('horario_sugerido', e)}
                                    defaultValue={(filter && filter.keyword === "horario_sugerido") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 10).cat_name}`}>
                                <MultiList
                                    componentId="AudienceSensor"
                                    dataField="audiencia.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 10).cat_name}`}
                                    onValueChange={(e) => handleClick('audiencia', e)}
                                    defaultValue={(filter && filter.keyword === "audiencia") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 1).cat_name}`}>
                                <MultiList
                                    componentId="CountrySensor"
                                    dataField="pais.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 1).cat_name}`}
                                    onValueChange={(e) => handleClick('pais', e)}
                                    defaultValue={(filter && filter.keyword === "pais") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 17).cat_name}`}>
                                <MultiList
                                    componentId="AuthorSugSensor"
                                    dataField="autores.keyword"
                                    data={authors}
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 17).cat_name}`}
                                    onValueChange={(e) => handleClick('autores', e)}
                                    defaultValue={(filter && filter.keyword === "autores") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    loader="Cargando..."
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`${catalogs.find(({ id_catalogue }) => id_catalogue === 8).cat_name}`}>
                                <MultiList
                                    componentId="FormatSensor"
                                    dataField="formato.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel={`${catalogs.find(({ id_catalogue }) => id_catalogue === 8).cat_name}`}
                                    onValueChange={(e) => handleClick('formato', e)}
                                    defaultValue={(filter && filter.keyword === "formato") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>

                            <CollapseWrapper title={`Tipo de Obra`}>
                                <MultiList
                                    componentId="TypeSensor"
                                    dataField="tipo_obra.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel="Tipo de Obra"
                                    onValueChange={(e) => handleClick('tipo-obra', e)}
                                    defaultValue={(filter && filter.keyword === "tipo-obra") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>
                            <CollapseWrapper title={`Producción`}>
                                <MultiList
                                    componentId="ProductionSensor"
                                    dataField="produccion.keyword"
                                    showSearch={false}
                                    size={100}
                                    innerClass={{
                                        count: 'count',
                                        checkbox: 'checkFilter-input',
                                        label: 'checkFilter-label'
                                    }}
                                    filterLabel="Producción"
                                    onValueChange={(e) => handleClick('produccion', e)}
                                    defaultValue={(filter && filter.keyword === "produccion") ? [filter.value] : undefined}
                                    selectAllLabel='Seleccionar Todo'
                                    defaultQuery={() => filterByAcervoActives}
                                />
                            </CollapseWrapper>
                        </div>
                    }
                </div>
            </Drawer>
        </>
    );
};

export default withUIContext(withStyles(styles)(SearchDrawer));

const CollapseWrapperComponent = ({ children, title, classes }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <List className={classes.drawerList}>
                <ListItem
                    button
                    onClick={handleClick}
                    className={classes.drawerItem}
                    dense
                >
                    <ListItemText className={classes.drawerItemStrong} primary={title} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            </List>
            <Collapse in={open}>
                {children}
            </Collapse>
        </>
    );
};

const CollapseWrapper = withStyles(styles)(CollapseWrapperComponent);
