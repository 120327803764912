import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { ReactiveBase, DataSearch } from '@appbaseio/reactivesearch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FilterList } from '@material-ui/icons';
import ls from 'local-storage';

import { LanguageConsumer } from './../../language/LanguageContext';
import styles from './search-jss';
import './style.css';

import { Button } from '../../atoms';
import SearchDrawer from './SearchDrawer';

//Services 
import { apiGET } from '../../services/service_base';
import { UIContextActions, withUIContext } from '../../context';
import {  Radio, RadioGroup } from '@material-ui/core';
import { filterByAcervoActives } from '../../utils/validation';

class SearchProduct extends React.Component {
  state = {
    anchorEl: null,
    checkedA: ls.get('productionView') ? false : true,
    checkedB: ls.get('productionView') ? true : false,
    search: '',
    production: 0,
    total: 0
  };


  handleChange = event => {
    this.setState({ checkedA: false, checkedB: false })
    this.setState({ [event.target.value]: true });
    ls.set('productionView', event.target.value === "checkedB" ? true : false)
    setTimeout(() => {
      this.props.handleFilters({ ventas: this.state.checkedA, produccion: this.state.checkedB });
    }, 200);
  };

  handleChangeSearch = value => {
    this.setState({ search: value })
  }

  handleGoToSearch = () => {

    if (this.state.search === "" || /^\s+$/i.test(this.state.search)) {
      this.props.onEmptySearch();
    } else {
      setTimeout(() => this.props.history.push(`/acervo/search-result?search=${this.state.search}`), 200);
    }

  }



  componentDidMount() {
    if (ls.get('productionView')) {
      this.setState({ checkedB: true, checkedA: false });
      setTimeout(() => {
        this.props.handleFilters({ ventas: this.state.checkedA, produccion: this.state.checkedB });
      }, 200);
    }
    apiGET('/obras/getcounter').then(
      response => {
        if (response.status === 200) {
          this.setState({
            total: response.data.total,
            production: response.data.production
          });
        } else {
          this.setState({ production: 0, total: 0 });
        }
      }
    ).catch(
      err => {
        this.setState({ production: 0, total: 0 });
      }
    )
  }

  render() {
    const {
      classes,
      canViewProduction,
      canViewSales,
      uicontext,
    } = this.props;

    const getLabel = (production, sales) => {
      if (production && sales) {
        return (
          <div className={classes.itemTotal}>
            <div className={classes.totalBox}>
              <h2>Total de obras en el portal: <span>{this.state.total <= 0 ? '-' : this.state.total}</span></h2>
            </div>
            <div className={classes.totalBox}>
              <h2>Asignadas a producción: <span>{this.state.production <= 0 ? '-' : this.state.production}</span></h2>
            </div>
          </div>
        )
      }
      else if (production) {
        return (
          <div className={classes.itemTotal}>
            <div className={classes.totalBox}>
              <h2>Total de obras para Producción: <span>{this.state.production <= 0 ? '-' : this.state.production}</span></h2>
            </div>
          </div>
        )
      }
    }


    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.root}>
              <Grid item container md={12} alignItems="center" justify="flex-end" direction="row" >
                <FormGroup row className={classes.textRight}>
                  {
                    (canViewProduction && canViewSales && ls.get("user_object").rdsUser.asigned_profile !== "Productor") &&
                    <RadioGroup
                      row
                      aria-labelledby="radio-catalogs"
                      name="radio-catalogs-group"
                      onChange={this.handleChange}
                      value={this.state.checkedA ? 'checkedA' : 'checkedB'}
                    >
                      <FormControlLabel
                        value="checkedB"
                        control={<Radio className={classes.labelWhite} />}
                        label={lang.searchs.production}
                      />
                      <FormControlLabel
                        value="checkedA"
                        control={<Radio className={classes.labelWhite} />}
                        label={lang.searchs.sales}
                      />
                      
                    </RadioGroup>
                  }

                </FormGroup>

              </Grid>

              <ReactiveBase
                url={process.env.REACT_APP_ELASTICBEHAVIOR}
                app={process.env.REACT_APP_ELASTICINDEX}
              >


                <div className={classes.searchWrapper}>
                  <div className={classes.searchBox}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <DataSearch
                      showIcon={false}
                      filterLabel="Buscar"
                      className={classes.searchInput}
                      dataField={["titulo_original", "titulo_ingles", "titulo_otro_idioma", "genero", "subgenero", "horario_sugerido", "audiencia", "pais", "autores", "formato", "tipo_obra", "produccion", "logline", "categoria", "fuente_data", "ventana", "tags", "obra_vigencia"]}
                      componentId="SearchSensor"
                      innerClass={{
                        input: 'search-input'
                      }}
                      placeholder="Buscar"
                      onValueSelected={this.handleGoToSearch}
                      onValueChange={this.handleChangeSearch}
                      defaultQuery={() => filterByAcervoActives}
                    />
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => UIContextActions.toggleDrawer(uicontext)}
                        className={classes.filtroBtn}>
                        Filtros <FilterList />
                      </Button>
                    </div>
                  </div>
                </div>
                <Grid container justify="flex-end" direction="row">
                  <Grid item sm={12} md={12}>
                    {
                      getLabel(canViewProduction, canViewSales)
                    }
                  </Grid>
                </Grid>
                <SearchDrawer history={this.props.history} />

              </ReactiveBase>
            </div>
        }
      </LanguageConsumer>
    );
  }
}

//AHDA 14/05/2019
//Added onEmptySearch
SearchProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  canViewSales: PropTypes.bool,
  canViewProduction: PropTypes.bool,
  onEmptySearch: PropTypes.func.isRequired
};

//AHDA 22/04/2019
//Added default props
SearchProduct.defaultProps = {
  canViewSales: false,
  canViewProduction: false
}

export default withUIContext(withRouter(withStyles(styles)(SearchProduct)));
