import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import Loader from '../../../../components/Loader/Loader';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { textLabelsEs } from '../../../../utils/tableTexts';
import {
  LanguageConsumer,
  LanguageCxt,
} from './../../../../language/LanguageContext';
import {
  getSuggestions,
  renderInput,
  renderSuggestion,
} from './../autocomplete';
import styles from './../reportes-jss';

import IconButton from '@material-ui/core/IconButton';

import { apiPOST } from '../../../../services/service_base';
import { FormControl, Input, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Dialog from './Dialog';
import classNames from 'classnames';

class ControlMaterialesReporte extends React.Component {

  state = {
    fechaInicio: null,
    //Table
    columns: [],
    data: [],
    fullData: [],
    //Catalogues
    users: [],
    productores: [],
    productor: null,
    actions: [],
    results: 0,
    isLoading: true,
    generos: [],
    genero: null,
    obras: [],
    obra: null,
    selectDetail: null
  };

  handleStateChange = (changes, name) => {
    if (name === "date") {
      this.setState({ fechaInicio: changes });
    } else if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ [name]: changes.selectedItem });
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ [name]: changes.inputValue });
    } else if (name === "comment") {
      const temp = this.state.selectDetail;
      temp.comment_material = changes.target.value
      this.setState({ selectDetail: temp })
    }
  };


  handleClearFilters = () => {
    this.setState({
      fechaInicio: null,
      genero: null,
      productor: null,
      obra: null
    });

    const request = {
      start_date: 0,
      genero: 0,
      productor: 0,
      titulo: 0
    }

    this.handleFetch(request);
  };

  searchs = () => {
    this.setState({ isLoading: true })
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);


    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      genero: this.state.genero === "" ? "0" : this.state.genero === "TODOS" ? "0" : this.state.genero,
      productor: this.state.productor === "" ? "0" : this.state.productor === "TODOS" ? "0" : this.state.productor,
      titulo: this.state.obra === "" ? "0" : this.state.obra === "TODOS" ? "0" : this.state.obra,
    }

    this.handleFetch(request);
  };

  componentDidMount() {
    //Catalogo usuarios
    const { lang } = this.context;
    const { classes } = this.props;

    this.setState({
      columns: [
        lang.reports.obraId,
        lang.reports.obraNombre,
        lang.reports.obraProductor,
        lang.reports.obraGenero,
        lang.reports.obraFechaEntrega,
        lang.reports.obraTipoMaterial,
        lang.reports.englishTitle, 
        lang.reports.otherTitle,
        {
          name: lang.reports.obraObservaciones,
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div className={classes.comment}>
                  <span className={classes.input}>{value}</span>
                  <IconButton
                    className={classes.smallbutton}
                    aria-label="Editar"
                    onClick={() => this.handleOpenDetail(tableMeta.rowIndex)}
                  >
                    <Edit className={classes.iconxs} />
                  </IconButton>
                </div>
              );
            }
          }
        }
      ],
    });

    apiPOST('/catalogue/get', { idCatalogo: 4 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ generos: response.data })
        } else {
          console.log("Error in catalogue generos: ", response);
        }
      }).catch(
        error => {
          console.log("Error in catalogue generos: ", error);
        });
    apiPOST('/catalogue/get', { idCatalogo: 31 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ productores: response.data })
        } else {
          console.log("Error in catalogue productores: ", response);
        }
      }).catch(
        error => {
          console.log("Error in catalogue productores: ", error);
        });
    apiPOST('/catalogue/get', { idCatalogo: 16 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ obras: response.data })
        } else {
          console.log("Error in catalogue obras: ", response);
        }
      }).catch(
        error => {
          console.log("Error in catalogue obras: ", error);
        });

    const request = {
      start_date: 0,
      genero: 0,
      productor: 0,
      titulo: 0
    }

    this.handleFetch(request);
  }

  handleFetch = (request) => {

    apiPOST('/reports/getmaterials', request).then(
      response => {
        this.setState({ isLoading: true })

        if (response.status === 200) {

          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].id_obras);
            obj.push(response.data[x].titulo_original);
            obj.push(response.data[x].producer);
            obj.push(response.data[x].descripcion);
            obj.push(response.data[x].date_);
            obj.push(response.data[x].tipo_archivo)
            obj.push(response.data[x].english_title)
            obj.push(response.data[x].other_title)
            obj.push(response.data[x].comment_material)
            result.push(obj);
          }
          this.setState({ data: result, fullData: response.data, isLoading: false, results: response.data.length })

        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        }
      );
  }

  handleOpenDetail = (index) => {
    const temp = this.state.fullData[index];
    if (temp)
      this.setState({ selectDetail: temp })
  }

  handleCloseDetail = () => {
    this.setState({ selectDetail: null });
  }

  handleSubmitComment = () => {
    this.setState({ isLoading: true });
    apiPOST('/reports/updatematerialcomment', {
      id_work: this.state.selectDetail.id_obras,
      id_material: this.state.selectDetail.id_material_multimedia,
      action: this.state.selectDetail.id_control_material_comentario ? "edit" : "add",
      comment: this.state.selectDetail.comment_material
    }).then(
      response => {
        if (response.status === 200) {
          this.searchs();
          this.setState({ selectDetail: null });
        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        }
      );
  }

  render() {
    const { classes } = this.props;
    const { columns, data, selectDetail } = this.state;

    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: true,
      downloadOptions: {
        filename: `${this.context.lang.reports.controlMateriales}.csv`,
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs
    };

    return (
      <LanguageConsumer>
        {({ lang }) => (
          <div className="marginTopLogo">
            <PapperBlock title={lang.reports.reports} whiteBg desc="">
              {/* Inicio Filtros */}
              <form className={classes.container}>
                <Grid
                  container
                  spacing={24}
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={3} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        keyboard={false}
                        helperText={lang.reports.obraFechaEntrega}
                        className={classes.formControl}
                        format="DD/MM/YYYY"
                        mask={[
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={this.state.fechaInicio}
                        onChange={(e) => this.handleStateChange(e, 'date')}
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        disableFuture={true}
                        clearable={true}
                        cancelLabel={lang.common.cancel}
                        clearLabel={lang.common.clear}
                        placeholder="DD/MM/AAAA"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.positionRelative}>
                    <div className={classes.root}>
                      <Downshift
                        selectedItem={this.state.genero}
                        onStateChange={(e) => this.handleStateChange(e, 'genero')}
                      >
                        {({
                          getInputProps,
                          getItemProps,
                          isOpen,
                          inputValue,
                          selectedItem,
                          highlightedIndex,
                        }) => (
                          <div className={classes.formControl}>
                            {renderInput({
                              fullWidth: true,
                              classes,
                              helperText: lang.reports.obraGenero,
                              InputProps: getInputProps({
                                placeholder: lang.reports.obraGenero,
                              }),
                            })}
                            {isOpen ? (
                              <Paper className={classNames(classes.paper, classes.list)} square>
                                {getSuggestions(
                                  inputValue,
                                  this.state.generos,
                                ).map((suggestion, index) => {
                                  suggestion.label = suggestion.data
                                  return renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion.label,
                                    }),
                                    highlightedIndex,
                                    selectedItem,
                                  })
                                }

                                )}
                              </Paper>
                            ) : null}
                          </div>
                        )}
                      </Downshift>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.positionRelative}>
                    <div className={classes.root}>
                      <Downshift
                        selectedItem={this.state.productor}
                        onStateChange={(e) => this.handleStateChange(e, 'productor')}
                      >
                        {({
                          getInputProps,
                          getItemProps,
                          isOpen,
                          inputValue,
                          selectedItem,
                          highlightedIndex,
                        }) => (
                          <div className={classes.formControl}>
                            {renderInput({
                              fullWidth: true,
                              classes,
                              helperText: lang.reports.obraProductor,
                              InputProps: getInputProps({
                                placeholder: lang.reports.obraProductor,
                              }),
                            })}
                            {isOpen ? (
                              <Paper className={classNames(classes.paper, classes.list)} square>
                                {getSuggestions(
                                  inputValue,
                                  this.state.productores,
                                ).map((suggestion, index) => {
                                  suggestion.label = suggestion.data;
                                  return renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion.label,
                                    }),
                                    highlightedIndex,
                                    selectedItem,
                                  })
                                },
                                )}
                              </Paper>
                            ) : null}
                          </div>
                        )}
                      </Downshift>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} className={classes.positionRelative}>
                    <div className={classes.root}>
                      <Downshift
                        selectedItem={this.state.obra}
                        onStateChange={(e) => this.handleStateChange(e, 'obra')}
                      >
                        {({
                          getInputProps,
                          getItemProps,
                          isOpen,
                          inputValue,
                          selectedItem,
                          highlightedIndex,
                        }) => (
                          <div className={classes.formControl}>
                            {renderInput({
                              fullWidth: true,
                              classes,
                              helperText: lang.reports.obraObra,
                              InputProps: getInputProps({
                                placeholder: lang.reports.obraObra,
                              }),
                            })}
                            {isOpen ? (
                              <Paper className={classNames(classes.paper, classes.list)} square>
                                {getSuggestions(
                                  inputValue,
                                  this.state.obras,
                                ).map((suggestion, index) =>
                                  renderSuggestion({
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({
                                      item: suggestion.label,
                                    }),
                                    highlightedIndex,
                                    selectedItem,
                                  }),
                                )}
                              </Paper>
                            ) : null}
                          </div>
                        )}
                      </Downshift>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={24}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Button
                      onClick={this.searchs}
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {lang.searchs.search}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleClearFilters}
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>
              {/* Fin Filtros */}

              <div className={classes.bitacoraMov}>
                {this.state.isLoading ? (
                  <Loader text={'Cargando'} />
                ) : (
                  <MUIDataTable
                    title={`${lang.reports.controlMateriales} (${this.state.results} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )}
              </div>
            </PapperBlock>
            <Dialog
              open={selectDetail != null}
              onClose={this.handleCloseDetail}
              title={lang.reports.obraAddComment}
              onSave={this.handleSubmitComment}
              labelCancel={lang.common.cancel}
              labelSave={lang.common.add}
            >
              <div>
                <div className={classes.mb4}>
                  <Typography variant="title" gutterBottom>
                    <span className={classes.fw300}>{'Título de la obra: '}</span>
                    {!!selectDetail && selectDetail.titulo_original && (
                      <>{selectDetail.titulo_original}</>
                    )}
                  </Typography>
                </div>
                <FormControl fullWidth>
                  <Input
                    label="Observación"
                    fullWidth
                    multiline
                    rows="2"
                    rowsMax="4"
                    value={selectDetail ? (selectDetail.comment_material || "") : ""}
                    onChange={(e) => e.target.value.length <= 200 ? this.handleStateChange(e, "comment") : e.preventDefault()}
                    margin="normal"
                  />
                </FormControl>
              </div>
            </Dialog>
          </div>
        )}
      </LanguageConsumer>
    );
  }
}

ControlMaterialesReporte.contextType = LanguageCxt;

export default withStyles(styles)(ControlMaterialesReporte);
