import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Help from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import styles from './user-jss';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//Dummy
import dummy from '../../utils/dummyContents';
import avatarApi from '../../utils/avatars';

// validation functions
//const required = value => (value == null ? 'Required' : undefined);

class LockForm extends React.Component {
  state = {
    anchorEl: null,
  };

  handleShowHint = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleSubmit = (event) =>{
    event.preventDefault(); 
  }

  render() {
    const {
      classes
    } = this.props;
    const { anchorEl } = this.state;
    return (
      <div className={classes.formWrap} >
        <Paper className={classes.lockWrap}>
          <form onSubmit={this.handleSubmit}>
            <Avatar alt="John Doe" src={avatarApi[6]} className={classes.avatar} />
            <Typography className={classes.userName} variant="display1">{dummy.user.name}</Typography>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="password">Correo electrónico</InputLabel>
                <Input
                  id="password"
                  name="password"
                  value= {this.state.password}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  required
                  className={classes.field}
                  endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                    aria-label="Helper Hint"
                    onClick={this.handleShowHint}
                    >
                      <Help />
                    </IconButton>
                  </InputAdornment>
                  }
                />
              </FormControl>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={classes.hint}>Hint: Type anything to unlock!</Typography>
              </Popover>
            </div>
            <div className={classes.btnArea}>
              <Button fullWidth variant="contained" color="primary" type="submit">
                Continue
                <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

LockForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LockForm);
