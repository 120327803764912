import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, Chip, Grid, InputLabel, Typography, withStyles } from '@material-ui/core'
import styles from './product-jss'
import classNames from 'classnames'

const TabEquiposLiterarios = ({ classes, data }) => {
    return (
        <Card>
            <CardHeader subheader="Equipos Literarios" className={classes.header} />
            <CardContent>
                {data ?
                    <Grid container spacing={32}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} className={classes.optionContainer}>
                                    <InputLabel className={classes.optionItem}>Obras de Adaptación </InputLabel>
                                    <InputLabel className={classes.optionDescription}>Categoría </InputLabel>
                                </Grid>
                                {
                                    data && data.filter(e => e.type === "version").map((item, key) => {
                                        return <Grid key={key} item xs={12} sm={12} className={classes.optionContainer}>
                                            <Typography variant='body1' component="span" className={classes.optionItem}>{item.version_equipo === "-1" ? item.description : item.version_equipo}</Typography>

                                            <div className={classes.optionDescription}>
                                                <Chip label={item.value} className={getChipClassNames(classes, item.value)} />
                                            </div>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} className={classes.optionContainer}>
                                    <InputLabel className={classes.optionItem}>Equipo Literario </InputLabel>
                                    <InputLabel className={classes.optionDescription}>Rol </InputLabel>
                                </Grid>
                                {
                                    data && data.filter(e => e.type === "equipo").map((item) => {
                                        return <Grid item xs={12} sm={12} className={classes.optionContainer}>
                                            <Typography variant='body1' component="span" className={classes.optionItem}>{item.version_equipo}</Typography>
                                            <Typography variant='body1' component="span" className={classes.optionDescription}>{item.value}</Typography>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    : <InputLabel className={classes.optionItem}>No hay información disponible</InputLabel>}
            </CardContent>
        </Card>
    )
}

TabEquiposLiterarios.propTypes = {
    data: PropTypes.object
}

export default withStyles(styles)(TabEquiposLiterarios);


/**
 * Returns the corresponding color for a `Clasificacion` value.
 *  adptation -> adaptacion,
 *  original -> primigenia,
 * 
 * @param {ClassNameMap<string>>} classes 
 * @param {string} clasificacion 
 * @returns {string} Corresponding color for the `clasificacion`.
 */
function getChipClassNames(classes, clasificacion) {

    let color = null;
    let base = classes.chipBase;

    switch (clasificacion) {
        case 'Primigenia':
            base = [classes.chipTipo];
            color = classes.chipPrimigenia
            break;
        case 'Adaptación':
            base = [classes.chipTipo];
            color = classes.chipAdaptacion
            break;
        default:
            color = classes.chipDefault;
            break;
    }

    return classNames(
        base,
        color
    );
}