import React from 'react';
import { Helmet } from 'react-helmet';
import brand from '../../../utils/brand';
import { withStyles } from '@material-ui/core/styles';
import ls from 'local-storage';
import update from 'react-addons-update';

//Components
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import SearchProduct from './../../../components/Search/SearchProduct';
import ProductGallery from './../../../components/Gallery/ProductGallery';
import Loader from '../../../components/Loader/Loader';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';
import { LanguageConsumer } from '../../../language/LanguageContext'

import { apiPOST, returnCurrentUserId } from '../../../services/service_base';

//AHDA 25/04/2019
//Imported auxiliar method 
import { getRedirectUrl } from '../../../helpers/general';

const styles = theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
      transition: theme.transitions.create('', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }
  }
});

class Home extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      //data
      data: [],
      filteredData: [],
      //notifications
      openError: false,
      messageError: 'errMsg',
      variantError: 'error',
      //Loading
      isLoading: true,
      chkFavorite: false,
      chkProductionGeneralView: false,
      chkSalesGeneralView: false,
      chkApplyForMaterial: false,
      chkShareMaterial: false,
      chkViewWorkBlock: false,
      chkViewScripts: false,
      chkViewSinopsis: false,
      chkViewPersonaje: false,
      chkViewEval: false,
      chkViewMaterial: false,
      chkViewMoreInfo: false,
      chkViewVigencia: false,
      chkViewEquiposLit: false,
      chkDownloadFT: false,
      //Force changes
      favorites: 0, //AHDA 19/06/2019
      prospects: 0, //AHDA 19/06/2019
      projects: 0, //AHDA 19/06/2019
      productor: false
    };
  }

  //Notifications
  handleCloseNotification = () => {
    this.setState({ openError: false });
  }

  //FOR PROSPECTS
  handleNotifications = () => {
    this.setState({ openError: true, messageError: 'errProspects', variantError: 'info' });
  }

  //AHDA 14/05/2019
  //Added event for empty search
  handleEmptySearch = () => {
    this.setState({ openError: true, messageError: 'errEmptySearch', variantError: 'info' });
  }

  //For filters 
  handleFilters = (filters) => {
    //let auxData = [];

    let body = {
      user: returnCurrentUserId(),
      clasification: "none",
      production: 0
    }

    if (filters.produccion & !filters.ventas) {
      body.production = 1;
    }

    this.setState({ isLoading: true });
    apiPOST('/obras/getAll', body).then(response => {
      if (this._isMounted) {

        if (response.status === 200) {
          //AHDA 17/04/2019
          //Added validations for undefined permissions
          this.setState({
            data: response.data,
            isLoading: false,
            filteredData: response.data,
            productor: body.production === 1 ? true : false
          });
        } else {
          this.setState({ openError: true, messageError: 'errMsg', variantError: 'error', isLoading: false });
        }
      }
    }).catch(error => {
      this.setState({ openError: true, messageError: 'errMsg', variantError: 'error', isLoading: false });
    });
  }

  filterData = (filter) => {
    let auxData = [];
    for (let i = 0; i < this.state.data.length; i++) {
      const auxCategory = update(this.state.data, {
        [i]: {
          $apply: function (category) {
            let auxCounter = 0;
            category.obras = category.obras.map(work => {
              if (work.clasificacion === filter) {
                auxCounter++;
                return work;
              }
              else
                return undefined;
            })
            //category.total = auxCounter; 
            return category;
          }
        }
      });
      auxData.push(auxCategory[i]);
    }

    return auxData;
  }

  componentDidMount() {
    this._isMounted = true;
    apiPOST("/roles/getmenuaction", {
      rolId: ls.get("user_object").rdsUser.id_rol
    }).then(response => {
      let menuPermissions = response.data;
      let config = menuPermissions.filter(x => x.menu_id === 1)[0];
      let configDetailWork = menuPermissions.filter(x => x.menu_id === 4)[0];

      if (config != undefined) {
        let _configDetailWorkGrant = configDetailWork ? JSON.parse(configDetailWork.grant_configuration) : undefined;
        this._loadInformation(JSON.parse(config.grant_configuration), _configDetailWorkGrant, menuPermissions);
      } else {
        this.setState({ openError: true, messageError: 'errMsg', variantError: 'error', isLoading: false });
      }
    });
  }

  _loadInformation = (permissions, permissionsDetail, config) => {
    if (!permissions.chkProductionGeneralView && !permissions.chkSalesGeneralView) {
      console.log("Permisos: ", config);
      this.setState({
        data: [],
        isLoading: false,
        filteredData: []
      });

      const auxConfig = config.filter(c => c.grant_configuration.indexOf("true") > -1);

      if (auxConfig.length > 0) {
        let redirect = getRedirectUrl(auxConfig);
        console.log(redirect);
        if (redirect) {
          window.location.href = redirect;
        }
      }

      return false;
    }
 
    let clasification = "none", production = 0;

    if (permissions.chkProductionGeneralView && !permissions.chkSalesGeneralView) {
      production = 1;
    }
    if (ls.get("user_object").rdsUser.asigned_profile === "Productor" || ls.get('productionView')) {
      production = 1;
    }
    var data = {
      user: returnCurrentUserId(),
      clasification,
      production
    };

    apiPOST('/obras/getAll', data).then(response => {
      if (this._isMounted) {

        if (response.status === 200) {

          this.setState({
            data: response.data,
            isLoading: false,
            filteredData: response.data,
            chkFavorite: permissions.chkFavorite,
            chkProductionGeneralView: permissions.chkProductionGeneralView,
            chkSalesGeneralView: permissions.chkSalesGeneralView,
            chkApplyForMaterial: permissionsDetail ? permissionsDetail.chkApplyForMaterial : false,
            chkShareMaterial: permissionsDetail ? permissionsDetail.chkShareMaterial : false,
            chkViewWorkBlock: permissionsDetail ? permissionsDetail.chkViewWorkBlock : false,
            chkViewScripts: !permissionsDetail ? false : permissionsDetail.chkViewScripts ? permissionsDetail.chkViewScripts : false,
            chkViewSinopsis: !permissionsDetail ? false : permissionsDetail.chkViewSinopsis ? permissionsDetail.chkViewSinopsis : false,
            chkViewPersonaje: !permissionsDetail ? false : permissionsDetail.chkViewPersonaje ? permissionsDetail.chkViewPersonaje : false,
            chkViewEval: !permissionsDetail ? false : permissionsDetail.chkViewEval ? permissionsDetail.chkViewEval : false,
            chkViewMaterial: !permissionsDetail ? false : permissionsDetail.chkViewMaterial ? permissionsDetail.chkViewMaterial : false,
            chkViewMoreInfo: !permissionsDetail ? false : permissionsDetail.chkViewMoreInfo ? permissionsDetail.chkViewMoreInfo : false,
            chkViewVigencia: !permissionsDetail ? false : permissionsDetail.chkViewVigencia ? permissionsDetail.chkViewVigencia : false,
            chkViewEquiposLit: !permissionsDetail ? false : permissionsDetail.chkViewEquiposLit ? permissionsDetail.chkViewEquiposLit : false,
            chkDownloadFT: !permissionsDetail ? false : permissionsDetail.chkDownloadFT ? permissionsDetail.chkDownloadFT : false,
            productor: production === 1 ? true : false
          });
        } else {
          this.setState({ openError: true, messageError: 'errMsg', variantError: 'error', isLoading: false });
        }
      }
    }).catch(error => {
      this.setState({ openError: true, messageError: 'errMsg', variantError: 'error', isLoading: false });
    });
  }

  handleAddTo = (work, type, added, external) => {
    let auxData = [...this.state.data];
    let auxFilteredData = [...this.state.filteredData];

    //AHDA 19/06/2019: Force favorites, prospects, projects changes 
    let auxFavorites = this.state.favorites, auxProspects = this.state.prospects, auxProjects = this.state.projects;

    const auxIdx = auxData.findIndex(c => c.id_categoria === type);
    const auxFilterIdx = auxFilteredData.findIndex(c => c.id_categoria === type);

    //AHDA 19/06/2019
    switch (type) {
      //Favorites 
      case 100:
        auxFavorites = added ? auxFavorites + 1 : auxFavorites - 1;
        break;
      //Prospects 
      case 103:
        auxProspects = added ? auxProspects + 1 : auxProspects - 1;
        break;
      //Projects
      case 101:
        auxProjects = added ? auxProjects + 1 : auxProjects - 1;
        break;
      default:
        break;
    }

    //AHDA 19/06/2019: Validation for external projects
    if (type === 101 && external) {
      this.setState({ projects: auxProjects })
      return false;
    }

    if (added) {
      if (auxIdx > -1) {
        if (type === 102) {
          const auxWorkIdx = auxData[auxIdx].obras.findIndex(w => w.id_obras === work.id_obras);
          if (auxWorkIdx > -1) {
            auxData[auxIdx].obras.splice(auxWorkIdx, 1);
          } else {
            auxData[auxIdx].obras.splice(auxData[auxIdx].obras.length - 1, 1);
          }
          auxData[auxIdx].obras.splice(0, 0, work);
          auxData[auxIdx].total = auxData[auxIdx].obras.length;
        } else {
          let exists = auxData[auxIdx].obras.findIndex(x => x.id_obras === work.id_obras);
          if (exists == -1) {
            // JCMY 10/07/2019 incidencia 689
            if (auxIdx == 6) {
              auxData[auxIdx].obras.splice(0, 0, work);
              if (auxData[auxIdx].obras.length > 10) {
                auxData[auxIdx].obras = (auxData[auxIdx].obras).slice(0, 10);
              }
              auxData[auxIdx].total = auxData[auxIdx].obras.length;
            } else {
              auxData[auxIdx].obras.splice(0, 0, work);
              auxData[auxIdx].total = auxData[auxIdx].obras.length;
            }
          }
        }
      }

      /*if(auxFilterIdx > -1){
        auxFilteredData[auxFilterIdx].obras.splice(0,0,work); 
        console.log(auxFilteredData[auxFilterIdx].obras); 
      }*/
    }
    else {
      if (auxIdx > -1) {
        let auxWorkIdx = auxData[auxIdx].obras.findIndex(w => w.id_obras === work.id_obras);
        auxData[auxIdx].obras.splice(auxWorkIdx, 1);
        auxData[auxIdx].total = auxData[auxIdx].obras.length;
      }
    }

    this.setState({
      data: auxData, filteredData: auxFilteredData,
      favorites: auxFavorites, prospects: auxProspects, projects: auxProjects //AHDA 19/06/2019
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const title = brand.name + ' - Inicio';
    const description = brand.desc;
    const { classes } = this.props;

    const linkStyle = {
      textDecoration: 'none'
    }

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
              </Helmet>
              <SearchProduct
                handleFilters={this.handleFilters}
                canViewSales={this.state.chkSalesGeneralView}
                canViewProduction={this.state.chkProductionGeneralView}
                onEmptySearch={this.handleEmptySearch}
                productor={this.state.productor}
              />
              {this.state.isLoading ?
                <Loader text={lang.common.loading} />
                :
                this.state.filteredData.map((item, index) => {
                  if (item.total > 0)
                    return <div className="container" key={index.toString()}>
                      <Link style={linkStyle} to={`/acervo/search-category?category=${item.id_categoria}${this.state.productor ? '&view=productor' : ''}`}>
                        <Typography variant="headline" component="h2" className={classes.title} >
                          {item.categoria} <small>({item.total} {lang.searchs.titles})</small>
                        </Typography>
                      </Link>
                      <ProductGallery
                        dataProduct={item.obras}
                        handleNotifications={() => this.handleNotifications()}
                        screen={'home'}
                        canFavorite={this.state.chkFavorite}
                        canApplyForMaterial={this.state.chkApplyForMaterial}
                        canShareMaterial={this.state.chkShareMaterial}
                        canViewWorkBlock={this.state.chkViewWorkBlock}
                        addToList={this.handleAddTo}
                        chkProductionGeneralView={this.state.chkProductionGeneralView}
                        chkSalesGeneralView={this.state.chkSalesGeneralView}
                        productor={this.state.productor}
                        chkViewScripts={this.state.chkViewScripts}
                        chkViewSinopsis={this.state.chkViewSinopsis}
                        chkViewPersonaje={this.state.chkViewPersonaje}
                        chkViewEval={this.state.chkViewEval}
                        chkViewMaterial={this.state.chkViewMaterial}
                        chkViewMoreInfo={this.state.chkViewMoreInfo}
                        chkViewVigencia={this.state.chkViewVigencia}
                        chkViewEquiposLit={this.state.chkViewEquiposLit}
                        chkDownloadFT={this.state.chkDownloadFT}
                        favorites={this.state.favorites}
                        prospects={this.state.prospects}
                        projects={this.state.projects}
                      />
                    </div>
                  else
                    return null;
                })
              }
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.openError}
                autoHideDuration={6000}
                onClose={this.handleCloseNotification}>
                <CustomNotification
                  onClose={this.handleCloseNotification}
                  variant={this.state.variantError}
                  message={lang.common[`${this.state.messageError}`]} />
              </Snackbar>
            </div>
        }
      </LanguageConsumer>
    );
  }
}

export default withStyles(styles)(Home);

