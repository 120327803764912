import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles-jss'

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch'


class TableCatalogue extends React.Component {
    state = {
        show: false,
        selected: null
    };

    handleOpenSwal = (dat) => {
        this.setState({ show: true, selected: dat });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.deleteItem(this.state.selected);
    }

    render() {
        const { headers, classes, data, openForm, updateStatus, showDelete } = this.props;
        return (
            <div className={classes.ajusteTabla}>
                <Table className={classNames(classes.table, classes.stripped)}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, idx) =>
                                (
                                    <TableCell key={`header_${idx}`}>{header}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((dat, idx) => (
                            <TableRow key={`row_${idx}`}>
                                <TableCell key={`cell_${1}`}>{dat.notificationId}</TableCell>
                                <TableCell key={`cell_${2}`}>{dat.notification}</TableCell>

                                {dat.send_mail == 0 ?
                                    <TableCell key={`cell_${3}`}>{dat.send_mail_usr == 0 ? <Switch checked={false} disabled onClick={(event) => updateStatus(dat, dat, event)} id='check_send_mail_usr' value="checkedA" color="primary" /> : <Switch checked={true} disabled  onClick={(event) => updateStatus(dat, dat, event)} id='check_send_mail_usr' value="checkedA" color="primary" />}</TableCell>
                                    :
                                    <TableCell key={`cell_${3}`}>{dat.send_mail_usr == 0 ? <Switch checked={false} onClick={(event) => updateStatus(dat, dat, event)} id='check_send_mail_usr' value="checkedA" color="primary" /> : <Switch checked={true} onClick={(event) => updateStatus(dat, dat, event)} id='check_send_mail_usr' value="checkedA" color="primary" />}</TableCell>
                                }

                                {dat.send_web == 0 ?
                                    <TableCell key={`cell_${4}`}>{dat.send_web_usr == 0 ? <Switch checked={false} disabled onClick={(event) => updateStatus(dat, dat, event)} id='check_send_web_usr' value="checkedA" color="primary" /> : <Switch checked={true} disabled onClick={(event) => updateStatus(dat, dat, event)} id='check_send_web_usr' value="checkedA" color="primary" />}</TableCell>
                                    :
                                    <TableCell key={`cell_${4}`}>{dat.send_web_usr == 0 ? <Switch checked={false} onClick={(event) => updateStatus(dat, dat, event)} id='check_send_web_usr' value="checkedA" color="primary" /> : <Switch checked={true} onClick={(event) => updateStatus(dat, dat, event)} id='check_send_web_usr' value="checkedA" color="primary" />}</TableCell>
                                }

                            </TableRow>
                        ))
                        }
                    </TableBody>

                </Table>
            </div>
        )
    }
}


TableCatalogue.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    openForm: PropTypes.func,
    deleteItem: PropTypes.func,
    updateStatus: PropTypes.func,
    showDelete: PropTypes.bool
};

TableCatalogue.defaultProps = {
    openForm: () => { },
    deleteItem: () => { },
    updateStatus: () => { },
    showDelete: true
}

export default withStyles(styles)(TableCatalogue);