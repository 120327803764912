import React, { createContext, useReducer } from 'react';

/**
 * Initial status for the context.
 */
export const initialUIContextValue = { searchDrawer: false, isCatalogEdit: false };

/**
 * Context reducer.
 * @param {object} payload
 * @param {string} action
 * @returns
 */
function reducer(state, payload) {
    switch (payload.type) {
        case 'toggleSearchDrawer':
            return { ...state, searchDrawer: payload.value };
        case 'isCatalogEdit':
            return { ...state, isCatalogEdit: payload.value };
    }
}

/**
 * Create a context objet for UI
 */
export const UIContext = createContext();

/**
 * UI context wrapper component.
 */
export const UIContextWrapper = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialUIContextValue);

    return (
        <>
            <UIContext.Provider value={{ state, dispatch }}>
                {children}
            </UIContext.Provider>
        </>
    );
};

/**
 * UI context HOC wrapper.
 */
export function withUIContext(BaseComponent) {
    return (props) => (
        <UIContext.Consumer>
            {(uicontext) => <BaseComponent {...{ uicontext, ...props }} />}
        </UIContext.Consumer>
    );
}
