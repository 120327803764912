import {apiPOST} from './service_base'; 

export const sendNotification = (type, bodyEmail, bodyNotification) => {
    const bodyGet = {
        portal: "acervo",
        notificationId: type
    }

    apiPOST('/notifications/getemailanduser', bodyGet).then(
        response => {
            if(response.status === 200){

                console.log(response); 

                let emails = response.data[0]; 
                let users = response.data[1];

                emails = emails.map(e => { return e.usuario_cognito});
                users = users.map(e => { return e.id_usuario});

                if(emails.length <= 0 && users.length <= 0){
                    return false;
                }
                
                /**
                 * emails, user, type, work, portal
                 */

                //Set emials and portal
                bodyEmail.emails = emails; 
                bodyEmail.portal = "acervo"; 

                apiPOST('/notifications/sendemail', bodyEmail).then().catch().finally(() => {
                    /**
                     * notification_type,
                        id_user,
                        id_work,
                        portal,
                        specifiedProductor,
                        id_notification_type, 
                        users
                     */

                    //Set users
                    console.log(users); 
                    bodyNotification.users = users; 
                    bodyNotification.portal = "acervo"; 

                    apiPOST('/notifications/add', bodyNotification).then(response => {
                        return true;
                    }).catch(err => {
                        return false; 
                    }); 
                });
            }else{
                return false; 
            }
        }
    ).catch(err => {
        console.log(err); 
        return false; 
    });
}

export const sendNotificationToSpecific = (type, bodyEmail, bodyNotification, specifiedUser) => {
    const bodyGet = {
        portal: "acervo",
        notificationId: type, 
        specifiedUser: specifiedUser
    }

    apiPOST('/notifications/getemailanduser', bodyGet).then(
        response => {
             
            if(response.status === 200){

                let emails = response.data[0]; 
                let users = response.data[1];
                let productor = response.data[2];  

                if(emails.length <= 0 && users.length <= 0 && productor.length <= 0){
                    return false;
                }
                
                let auxProducer = {}
                if(productor.length > 0){
                    auxProducer = productor[0]; 
                    //Map only users that are not producers 
                    emails = emails.filter(m => m.rolAAplica !== auxProducer.id_rol_acervo).map(m => {
                        return m.usuario_cognito; 
                    });

                    users = users.filter(m => m.rolAAplica !== auxProducer.id_rol_acervo).map(m => {
                        return m.id_usuario; 
                    });

                    if(auxProducer.send_mail_usr === 1); 
                        emails.push(auxProducer.usuario_cognito);
                    if(auxProducer.send_web_usr === 1); 
                        users.push(auxProducer.id_usuario);
 
                }else{
                    emails = emails.map(e => { return e.usuario_cognito});
                    users = users.map(e => { return e.id_usuario});
                }
                
                /**
                 * emails, user, type, work, portal
                 */

                //Set emails and portal
                bodyEmail.emails = emails; 
                bodyEmail.portal = "acervo"; 

                apiPOST('/notifications/sendemail', bodyEmail).then().catch().finally(() => {
                    //Set users
                    bodyNotification.users = users; 
                    bodyNotification.portal = "acervo"; 

                    apiPOST('/notifications/add', bodyNotification).then(response => {
                        return true;
                    }).catch(err => {
                        return false; 
                    }); 
                });
            }else{
                return false; 
            }
        }
    ).catch(err => {
        console.log(err); 
        return false; 
    });
}

export const sendEmail = (body) => {

    //Send notification to specific and roles
    apiPOST('/notifications/sendemail', body).then(
    response => {
        if (response.status == 200) {
            return true; 
        }
        else {
            return false; 
        }
    }).catch(error => {
        console.log(error);
        return false; 
    });
}