export function toggleDrawer(uicontext) {
    const { state, dispatch } = uicontext;
    dispatch({
        type: 'toggleSearchDrawer',
        value: !state.searchDrawer,
    });
}

export function catalogEdit(uicontext) {
    const { state, dispatch } = uicontext;
    dispatch({
        type: 'isCatalogEdit',
        value: !state.isCatalogEdit,
    });
}

export function showDrawer(uicontext) {
    const { dispatch } = uicontext;
    dispatch({
        type: 'toggleSearchDrawer',
        value: true,
    });
}

export function hideDrawer(uicontext) {
    const { dispatch } = uicontext;
    dispatch({
        type: 'toggleSearchDrawer',
        value: false,
    });
}
