import React from 'react';

import './Loader.css'; 

class Loader extends React.Component{
    render(){
        return(
            <div className="loaderContainer">
                <div className="loaderA">
                    <div></div>
                    <div></div>
                    <span>{this.props.text}</span>
                </div>
            </div>
        )
    }
}

export default Loader; 