import { unset } from 'lodash';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 200,
  },
  tableHead: {
    background: '#f5f5f5'
  },
  inputMini: {
  },
  inputSelect: {
    maxWidth: '80%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  buttonAdd: {
    marginLeft: theme.spacing.unit,
    flexShrink: 0,
    position: 'relative',
  },
  buttonAction: {
    margin: `0 ${theme.spacing.unit}px 0`,
    flexShrink: 0,
  },
  buttonActionDelete: {
    '& svg': {
      height: '0.85em',
      width: '0.85em',
    }
  },
  tableFormControl: {
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  tableRowNoBorderBottom: {
    borderBottom: 'unset',
    '& td': {
      borderBottom: 'unset',
    }
  },
  actionCell: {
    maxWidth: 250 
  },
  cellHeading: {
    width: 250 
  },
  actionCellHeading: {
    width: 150 
  },
  descriptionCell: {
  },
  alignTop: {
    paddingBottom: theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit,
    verticalAlign: 'top',
  },
  actionCellPadding: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 100
  },
  arrayRowItem: {
    minHeight: theme.spacing.unit * 5,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      width: '100%'
    }
  }
});

export default styles;