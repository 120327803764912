export const notifTypes = [
    { type: "newWorkRegister", notification_id: 1 },
    { type: "workUpdate", notification_id: 8 },
    { type: "evaluationAssessment", notification_id: 9 }, //JCMY 09/07/2019 incidecnia 1051
    { type: "scriptsAccessRequest", notification_id: 10 },
    { type: "scriptsAccess", notification_id: 11 },
    { type: "dueTimeProspect", notification_id: 12 },
    { type: "workAssigned", notification_id: 13 },
    { type: "workProspectUser", notification_id: 24 },
    { type: "aditionalMaterialRegister", notification_id: 14 },
    { type: "unlockWorkPropspect", notification_id: 15 },
    { type: "unlockWorkPropspectUser", notification_id: 18 },
  ];