import pink from '@material-ui/core/colors/pink';
import lightGreen from '@material-ui/core/colors/lightGreen';
//import dark from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  dividerCard: {
    //margin: '0 auto',
    height: 1,
    width: 'calc (100% - 48px)',
    backgroundColor: '#dbdbdb',
    margin: '0 24px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc (100% - 32px)',
    },
  },
  card: {
    minWidth: 275,
  },
  liked: {
    color: pink[500]
  },
  shared: {
    color: lightGreen[500]
  },
  num: {
    fontSize: 14,
    marginLeft: 5
  },
  rightIcon: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginRight: theme.spacing.unit
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardPlayer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
    height: 150,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  cardSocmed: {
    minWidth: 275,
  },
  cardNotify: {
    minWidth: 200,
    '& div:first-child': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& div:nth-child(3)': {
      paddingTop: 10,
      paddingBottom: 10
    },
    '& div:first-child div:nth-child(2)': {
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      '& span:first-child': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'auto',
        minWidth: 50,
      }
    }
  },
  cardProduct: {
    position: 'relative',
    transition: theme.transitions.create('', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& img': {
      objectFit: 'cover',
      height: 165,
      width: '100%',
      '&:hover': {
        transform: 'scale(1.15)',
        transition: theme.transitions.create('', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen * 8,
        }),
      }
    },
    '&:hover': {
      boxShadow: theme.shadows[4],
      transform: 'scale(1.03)',
      transition: theme.transitions.create('', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  cardProductLarge: {
    position: 'relative',
    transition: theme.transitions.create('', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& img': {
      objectFit: 'cover',
      objectPosition: 'top',
      height: 200,
      width: '100%',
      '&:hover': {
        transform: 'scale(1.15)',
        transition: theme.transitions.create('', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen * 8,
        }),
      }
    },
    '&:hover': {
      boxShadow: theme.shadows[4],
      transform: 'scale(1.03)',
      transition: theme.transitions.create('', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  cardMargin: {
     marginInline: '6px',
  },
  mediaProduct: {
    height: 0,
    paddingTop: '60.25%', // 16:9
    cursor: 'pointer',
    display: 'initial',
    '& img': {
      border: 'none !important',
      display: 'block'
    }
  },
  mediaProductEmpty: {
    height: 0,
    paddingTop: '60.25%', // 16:9
    cursor: 'default',
    display: 'initial',
    '& img': {
      borderColor: theme.palette.primary.main,
      borderWidth: '3px !important',
      borderStyle: 'solid',
      display: 'block'
    },
  },
  pais: {
    color: theme.palette.primary.main,
    lineHeight: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // paddingBottom: theme.spacing.unit,
    whiteSpace: 'nowrap',
    width: 'auto',
    '& svg': {
      marginBottom: -6,
    }
  },
  rightAction: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0px !important',
  },
  btnSmall: {
    padding: '2px 6px',
    fontSize: '0.7rem',
  },
  floatingButtonWrap: {
    position: 'relative',
    padding: '30px 10px 15px 10px',
    minHeight: 160,
    backgroundColor: 'white'
  },
  buttonAdd: {
    position: 'absolute',
    right: 20,
    top: -20,
  },
  contentButt: {
    position: 'absolute',
    right: 20,
    top: -20,
    '& button': {
      width: 34,
      height: 34,
    },
    '& svg': {
      fontSize: 22,
      height: 25,
      marginTop: 4,
    }
  },
  buttonAddList: {
    display: 'inline-block',
    marginLeft: 10
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    height: 20,
  },
  autor: {
    fontSize: 12,
    color: theme.palette.primary.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ratting: {
    display: 'inline-block',
    margin: '10px 0 0 0',
    '& button': {
      width: 24,
      height: 24
    }
  },
  status: {
    zIndex: '9',
    position: 'absolute',
    left: 0,
    top: 0,
    padding: 8,
    '& > *': {
      // margin: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px`
    }
  },
  desc: {
    height: 60,
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateFinish: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 500,
    padding: '0.5rem 0',
    textAlign: 'left',
    '& span': {
      fontWeight: 600,
    }
  },
  chipBase: {
    color: '#fff',
    height: 24,
    fontSize: 12,
    textTransform: 'capitalize',
    width: '100%',

    '& span': {
      paddingLeft: 8,
      paddingRight: 8
    }
  },
  chipTipo: {
    color: '#fff',
    fontSize: 12,
    height: 24,
    textAlign: 'center',
    textTransform: 'capitalize',
    width:'100%',

    '& span': {
      paddingLeft: 8,
      paddingRight: 8,
      whiteSpace: 'break-spaces'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
    }
  },
  chipTipoBorder: {
    background: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    color: 'inherit',
    height: 'auto',
    minHeight: '24px',
  },
  chipPrimary: {
    // #f12065
    background: theme.palette.primary.main,
  },
  chipDefault: {
    // #fe5503
    background: theme.palette.warning.main,
  },
  chipVigente: {
    // #f12065
    background: theme.palette.primary.main,
  },
  chipPorVencer: {
    // #fcca15
    background: theme.palette.secondary.main,
  },
  chipVencida: {
    // #5b6066
    background: theme.palette.graydefault,
  },
  chipPrimigenia: {
    // #5b6066
    background: theme.palette.warning.main,
  },
  chipAdaptacion: {
    // #5b6066
    background: '#f68e5c',
  },
  chipProduccionTelevisa: {
    borderColor: '#fa6a0c',
  },
  chipProduccionExtranjera: {
    borderColor: '#e05d07',
  },
  chipNoProducida: {
    borderColor: '#fcca15',
  },
  chipInedita: {
    borderColor: '#c89e08',
  },
  chipSpacing: {
    '&:nth-child(n+2)': {
      marginTop: `${theme.spacing.unit * 1.25}px !important`
    }
  },
  border: {
    border: '2px solid red'
  },
  chipActionSpacing: {
  },
  contentProfle: {
    flex: '1 0 auto',
    textAlign: 'center'
  },
  mediaProfile: {
    height: 0,
    paddingTop: '36.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatarBig: {
    width: 80,
    height: 80,
    margin: '-56px auto 10px',
    background: theme.palette.secondary.dark
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProfile: {
    margin: 20
  },
  bottomLink: {
    width: '100%',
  },
  price: {
    paddingBottom: 5,
    paddingTop: 0,
  },
  pilot: {
    display: 'inline-block !important',
    border: '0 !important',
    margin: '0 !important',
    width: 92,
    marginLeft: '5px !important',
  },
  contentProfile: {
    textAlign: 'center'
  },
  verified: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  mediaicons: {
    // display: 'inline-block',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '50%',
    '& img': {
      maxWidth: '60%',
      height: 'auto',
    },
    '& svg': {
      fontSize: 25,
      marginLeft: 5,
    },
  },
  cardList: {
    display: 'flex',
    justifyContent: 'space-between',
    '& $buttonAddList': {
      display: 'inline-block'
    },
    '& $floatingButtonWrap': {
      flex: 1,
    },
    '& $buttonAdd': {
      display: 'none'
    },
    '& $status': {
      right: 'auto',
      left: 0,
    },
    '& $mediaProduct': {
      width: 300,
      paddingTop: '21.25%'
    },
    '& $price': {
      flexDirection: 'column',
      justifyContent: 'center',
      '& button': {
        marginTop: 20
      }
    }
  },
  modalSlider: {
    padding: '0 40px'
  },
  // Colores Botones Redondos
  desactivado: {
    backgroundColor: '#fff',
    color: '#fff',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '& svg': {
      stroke: theme.palette.primary.main
    },
    // '& :hover svg': {
    //   stroke: 'none',
    // }
  },
  disableds: {
    // display: 'none',
    backgroundColor: '#fff',
    color: '#fff',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    cursor: 'default',
    '& svg': {
      stroke: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#fff !important',
      '& svg': {
        stroke: theme.palette.primary.main
      }
    }
  },
  activadoFav: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  activadoBlock: {
    backgroundColor: theme.palette.primary.main,
    cursor: 'default',
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  activadoAdd: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  activado: {
    backgroundColor: theme.palette.primary.main
  },
  overlay: {
    position: 'absolute',
    top: '43px',
    color: 'black',
    backgroundColor: 'transparent',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
    '& img': {
      height: 80,
      width: 80,
      border: 'none !important'
    }
  },
  cardModal: {
    width: '60%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& button': {
      marginRight: '-14px',
      marginTop: 15,
      //position: 'absolute',
      //right: 10,
      //top: 10
    }
  },
  blockedCard: {
    position: 'absolute',
    background: 'linear-gradient(0deg, #000000 30%, rgba(255,255,255,0) 90%)',
    minWidth: '100%',
    height: 'calc(100% - 145px)',
    zIndex: 1,
    bottom: 0,
    //top:'38%',
    opacity: '.4',
  },
  btnTop: {
    zIndex: 2
  },
  zIndexBtn: {
    zIndex: '999',
  },
  thumbVideo: {
    '& > div': {
      height: '165px',
      backgroundColor: '#0072b5'
    },
    '& video': {
      display: 'none'
    }
  },
  thumbVideoProfile: {
    '& > div': {
      height: '200px',
      backgroundColor: '#0072b5'
    },
    '& video': {
      display: 'none'
    }
  },
  actions: {
    display: 'flex',
    flexFlow: 'column nowrap',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  alertDialog: {
    "& .confirm": {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    }
  }
});

export default styles;