import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Add from '@material-ui/icons/AddCircle';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import {LanguageConsumer} from '../../language/LanguageContext';

//styles 
import styles from './treetable-jss'; 

class RenderRow extends React.Component {

  constructor(props){
    super(props); 

    this.state = {
      open:false
    }
  }

  //Method to open and close branches 
  toggleTree = (id) => {
    //Search for id 
    if( this.state[`${id}`] === undefined)
      this.setState({[id] : true}); 
    else
      this.setState({[id] : !this.state[`${id}`]}); 
  }

  //improvements:  
  //Rigth now it only supports one node ( parent => child )
  //Hide id 
  //ID must be the FIRST thing in every object and child the LAST, for it to work ok
  render(){
    const {item, branch, classes} = this.props; 

    const renderCell = (item, parent) => {
      //Convert object to string array
      let dataBodyVal = Object.values(item);
      //render parent cells
      if(parent){
        return dataBodyVal.map(
          (cell, index) => {
            if(index > 0 && index < dataBodyVal.length - 1){
              return <TableCell key={`cell${item.id}_${index}`}><span>{cell}</span></TableCell>
            }
            else
              return null; 
          })
      }
      //Render child cells 
      else{
        return dataBodyVal.map(
          (cell, index) => {
            return (<TableCell key={`cell${item.id}_${index}`}> {index <= 0? <span>{cell}</span> : cell} </TableCell>)
          })
      }
    }
    
    //Return rows 
    return(
      <React.Fragment>
      {
        item.child !== undefined ? 
        <React.Fragment>
          <TableRow onClick={() => this.toggleTree(`${branch + item.id}`)}>
            {/**Show icon */}
            <TableCell className={classes.tablecellIcon}>
              {
                this.state[`${branch + item.id}`] === undefined? <Add/> : 
                this.state[`${branch + item.id}`] ? <Add /> : <Remove/> 
              }
            </TableCell>
            {renderCell(item, true)}
          </TableRow>
          {
            /**Render child
             * Show or hide child depends on className*/
            item.child.map((child, index) => 
              <TableRow key={`${branch + item.id}_${index}`} className={
                this.state[`${branch + item.id}`] === undefined ? classes.hideRow :
                this.state[`${branch + item.id}`] ? classes.hideRow : classes.anchor
              }>
                {renderCell(child, false)}
              </TableRow>
            )
          }
        </React.Fragment> :
        <TableRow>
          {/*render cell without children*/ renderCell(item, false)}
        </TableRow>
      }
      </React.Fragment>
    )
  }
}

RenderRow = withStyles(styles)(RenderRow);

class TreeTable extends React.Component {
  
  state={
    page: 0, 
    rowsPerPage: 10
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  
  render() {
    const {
      classes,
      dataTable,
      branch
    } = this.props;
    
    //Return body, recieves array with body
    const getData = (dataArray) => dataArray.map((item, index) => {
      return (
        <RenderRow
          item={item}
          key={index.toString()}
          branch={branch}
          parent={false}
        />
      );
    });

    //Get table headers
    const getHead = dataArray => dataArray.map((item, index) =>
      <TableCell padding="dense" key={index.toString()}>{item.label}</TableCell>
    );

    const {page, rowsPerPage} = this.state; 

    return (
      <div className={classes.tableWrapper}>
         <Table className={classes.table}>
          <TableHead>
            <TableRow>
              { getHead(dataTable.head) }
            </TableRow>
          </TableHead>
          <TableBody>
            { getData(dataTable.body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)) }
          </TableBody>
          
        </Table>
        <LanguageConsumer>
          {
            ({lang}) => 
            <TablePagination
            rowsPerPageOptions={[10, 15, 100]}
            component="div"
            count={dataTable.body.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage = {lang.treeTable.rowsPerPage}
            />
          }
        </LanguageConsumer>
       
      </div>
     
    );
  }
}

TreeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dataTable: PropTypes.object.isRequired,
  branch: PropTypes.string.isRequired
};

export default withStyles(styles)(TreeTable);