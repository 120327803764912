import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

//Styles
import styles from './nuevaObra-jss';

import SweetAlert from 'sweetalert-react';
import '../../styles/components/vendors/sweetalert-react/sweetalert.css';

class CharacterPaper extends React.Component {

    state = {
        selected: null
    };

    handleOpenSwal = (character) => {
        this.setState({ show: true, selected: character });
    }

    handleConfirmSwal = () => {
        this.setState({ show: false });
        this.props.onDelete(this.state.selected);
    }

    render() {
        // isanchez 07.05.2019 add permisions 
        const { classes, onDelete, onEdit, character, canEdit, canDelete, canViewPersonaje } = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center">
                <Grid item xs={12} md={12} >
                    <Paper className={classes.paper}>
                        {/* GEO Cambios en estructura para adaptación de Drag and drop */}
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                            <Grid item xs={12} sm={8} md={8} >
                                <div className={classes.contentFlex}>
                                    <div>
                                        <Typography component="p" className={classes.labelTitle}>
                                            Nombre:
                                        </Typography>
                                        {character.nombre}
                                    </div>
                                    <div>
                                        <Typography component="p" className={classes.labelTitle}>
                                            Edad:
                                        </Typography>
                                        {character.edad}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} >
                                <div className={classes.rightAction}>
                                    {/* GEO 05/06/19 Se agregan tooltips a iconos y se les quita el color azul */}
                                    {
                                        canDelete ?
                                            <Tooltip title="Eliminar">
                                                <IconButton disabled={!canViewPersonaje} className={classes.button} aria-label="Delete" onClick={() => this.handleOpenSwal(character)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            : ''
                                    }
                                    {
                                        canEdit ?
                                            <Tooltip title="Editar">
                                                <IconButton disabled={!canViewPersonaje} className={classes.button} aria-label="Delete" onClick={() => onEdit(character)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            : ''
                                    }
                                </div>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                            <Grid item xs={12} md={12} >
                                <div className="espaciadoP">
                                    <Typography component="p" className={classNames(classes.labelTitle, classes.pt2)}>
                                        Psicología del personaje:
                                    </Typography>
                                    <Typography component="p" className="textJustify">
                                        {character.psicologia}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        {/* /GEO Cambios en estructura para adaptación de Drag and drop */}
                    </Paper>
                    <SweetAlert
                        show={this.state.show}
                        customClass={classes.alertDialog}
                        title="¿Estás seguro?"
                        text="Una vez que elimines este elemento, no se podrá recuperar"
                        showCancelButton
                        onConfirm={() => {
                            this.handleConfirmSwal();
                        }}
                        onCancel={() => {
                            this.setState({ show: false, selected: null });
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

CharacterPaper.propTypes = {
    classes: PropTypes.object,
    character: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    canEdit: PropTypes.bool,
    canDelete: PropTypes.bool
}

CharacterPaper.defaultProps = {
    onDelete: () => { },
    onEdit: () => { },
    canEdit: true,
    canDelete: true
}

export default withStyles(styles)(CharacterPaper); 