import ls from 'local-storage'; 
import { Auth } from 'aws-amplify'; 
import { apiPOST } from './service_base';  
 
//Error handling  
let error = { 
    status: 500,  
    message: '',  
    userAttributes: null 
} 
 
/** 
 * Login to portal  
 * @param {*} user : must contain username and password 
 */ 
export const userLogin = (user) => { 
    return new Promise( 
        function(resolve, reject){ 
 
            Auth.signIn({username: user.username, password: user.password}).then( 
                user => { 
                    if(user.challengeName){ 
                        switch(user.challengeName){ 
                            case "NEW_PASSWORD_REQUIRED": 
                            //const { family_name, name } = user.challengeParam.userAttributes; 
                            error.status = 403;  
                            error.message = 'New Password Required'; 
                            error.userAttributes = user;  
                            reject(error); 
                            break;  
                            default: 
                            break;  
                        } 
                    } 
                    else{  
                        resolve(user); 
                    } 
                } 
            ).catch( 
                err => { 
                    console.log(err); 
                    error.message = err.message  
                    //AHDA 16/05/2019
                    if(err.code.indexOf('NotAuthorizedException') > -1 ||
                    err.code.indexOf('UserNotFoundException') > -1) 
                        error.status = 401;  
                    else 
                        error.status = 500;  
                    reject(error);  
                } 
            ) 
        } 
    );  
} 

export const afterInternalLogin = (user, user_auth) => {
    let decoded = user.signInUserSession.idToken.payload;  
    
    return new Promise(function(resolve, reject){
        apiPOST('/user/get', {cognitoUser: user_auth.username, portal: 1}).then( 
            response => { 
                if(response.status === 200){ 
                    if(decoded["cognito:groups"] !== undefined && decoded["cognito:groups"][0] !== "External_Users") 
                    {    
                        if(response.data.user.estatus === 0){ 
                            signOutPromise().then().catch().finally( 
                                () => { 
                                    error.message = 'User blocked access'; 
                                    error.status = 404; 
                                    reject(error);  
                                } 
                            ) 
                        } 
                        else{ 
                            //Save session 
                            decoded.rdsUser = response.data.user;  
                            ls.set("user_object", decoded); 
                            ls.set("favorites", response.data.favorites);  
                            ls.set("prospects", response.data.prospects);  
                            //AHDA 16/05/2019: Added valdation for refreshing
                            apiPOST("/roles/getmenuaction",{ 
                                rolId : ls.get("user_object").rdsUser.id_rol 
                              }, true).then(response => { 
                                ls.set("user_permissions", response.data); 
                                ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
                                resolve(decoded);                                                 
                              }).catch(err => console.log(err)); 
                        } 
                    } 
                    else{ 
                        error.message = 'User not authorized or not in a group'; 
                        error.status = 500; 
                        reject(error);  
                    }  
                }else{ 
                    signOutPromise().then().catch().finally( 
                        () => { 
                            error.message = response.message; 
                            if(response.message.indexOf('User not allowed in portal') > -1){ 
                                error.status = 404; 
                            } 
                            else 
                                error.status = 500; 
                            reject(error);  
                        } 
                    );  
                } 
            } 
        ).catch( 
            err => { 
                signOutPromise().then().catch().finally( 
                    () => { 
                        error.message = err.message;  
                        error.status = 500; 
                        reject(error);  
                    } 
                );   
            } 
        )
    }); 
}

/** 
 * Change password when user signs in the first time after register  
 * @param {*} userAttributes : the user attributes of the user  
 * @param {*} user : must contain username and password  
 */ 
export const changePasswordRequired = (user, newPassword) => { 
    return new Promise( 
        function(resolve, reject){ 
 
            Auth.signIn({username: user.username, password: user.password}).then( 
                responseAuth => { 
                    if(responseAuth.challengeName && responseAuth.challengeName === "NEW_PASSWORD_REQUIRED"){ 
                         
                        let attributesData = { 
                            name: 'Televisa', family_name : 'User' 
                        } 
                         
                        Auth.completeNewPassword(responseAuth, newPassword, attributesData).then( 
                            res => { 
                                apiPOST('/user/get', {cognitoUser: user.username, portal: 1}).then( 
                                    response => { 
                                        if(response.status === 200){ 
                                             
                                            if(response.data.user.estatus === 0){ 
                                                signOutPromise().then().catch().finally( 
                                                    () => { 
                                                        error.message = 'User blocked access'; 
                                                        error.status = 404; 
                                                        reject(error);  
                                                    } 
                                                ) 
                                            }else{ 
                                                let decoded = responseAuth.signInUserSession.idToken.payload;  
 
                                                if(decoded["cognito:groups"] !== undefined && decoded["cognito:groups"][0] !== "External_Users") 
                                                {     
                                                    //Save session 
                                                    decoded.rdsUser = response.data.user;  
                                                    ls.set("user_object", decoded); 
                                                    ls.set("favorites", response.data.favorites);  
                                                    ls.set("prospects", response.data.prospects);  
                                                    //AHDA 16/05/2019: Added valdation for refreshing
                                                    apiPOST("/roles/getmenuaction",{ 
                                                        rolId : ls.get("user_object").rdsUser.id_rol 
                                                    }, true).then(response => { 
                                                        ls.set("user_permissions", response.data); 
                                                        ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019
                                                        resolve(decoded);                                                 
                                                    }).catch(err => console.log(err)); 
                                                } 
                                                else{ 
                                                    error.message = 'User not authorized or not in a group'; 
                                                    error.status = 500; 
                                                    reject(error);  
                                                }  
                                            } 
                                             
                                        }else{ 
                                            error.message = response.message; 
                                            error.status = 500; 
                                            reject(error);  
                                        } 
                                    } 
                                ).catch( 
                                    err => { 
                                        error.message = err.message; 
                                        error.status = 500; 
                                        reject(error);  
                                    } 
                                ) 
                            } 
                        ).catch( 
                            err => { 
                                console.log(err);  
                                error.message = err.message;  
                                         
                                if(error.message.indexOf('Password does not conform to policy') > -1  
                                || error.message.indexOf('Password not long enough') > -1) 
                                    error.status = 403; 
                                else 
                                    error.status = 500; 
                                reject(error);  
                            } 
                        ) 
                    } 
                } 
            ).catch( 
                err => { 
                    console.log(err); 
                    error.message = err.message  
                    error.status = 500;  
                    reject(error);  
                } 
            ) 
        } 
    );  
} 
 
/** 
 * Logout  
 */ 
export function logOut(){ 
    Auth.signOut().then( 
        data => { 
            ls.clear(); 
            window.location.href = '/' 
        } 
    ).catch( 
        err => { 
            ls.clear(); 
            window.location.href = '/' 
        } 
    ); 
} 
 
//AHDA 30/04/2019 
//Function for login out with promise 
export function logOutPromise(){ 
    return new Promise( 
        function(resolve, reject){ 
            Auth.signOut().then( 
                data => { 
                    localStorage.clear();  
                    ls.clear(); //AHDA 17/05/2019
                    resolve(data);  
                } 
            ).catch( 
                err => { 
                    localStorage.clear();  
                    ls.clear(); //AHDA 17/05/2019
                    reject(err);  
                } 
            ); 
        } 
    ) 
} 

export function checkSession(redirect){ 
    //const user = localStorage.getItem('current_user');  
    //Check for valid token  
    //const user = ls.get("user_object");  
    Auth.currentSession().then( 
        current => { 
            console.log(current);  
            const user = ls.get("user_object");  
            if(user === null){ 
                //NOT SURE YOU HAVE TO SIGN OUT EVERY TIME 
                //AHDA 12/04/2019 
                //Send redirect param to method 
                if(redirect) 
                    signOut(true, redirect); 
                else 
                    signOut(true); 
            }else{ 
                //AHDA 16/05/2019: Added valdation for refreshing
                apiPOST('/obras/getBlocked', {idUser: user.rdsUser.id_usuario}, true).then( 
                    response => { 
                        if (response.status === 200) { 
                            ls.set("blocked", response.data);  
                            ls.set("blockedProjects", response.data.projects);  
                            ls.set("prospects", response.data.myprospects); 
                        } 
                    } 
                ).catch( 
                    err => { 
                        console.log(err);  
                    } 
                );  
            } 
        } 
    ).catch( 
        err => { 
            //AHDA 12/04/2019 
            //Send redirect param to method  
            if(redirect) 
                signOut(true, redirect);  
            else 
                signOut(true); 
        } 
    ) 
} 
 
export function checkHasSession(){ 
    //Check for valid token  
    Auth.currentSession().then( 
        response => { 
            const user = ls.get("user_object");  
            if(user != null){ 
                window.location.href= '/acervo';  
            } 
        } 
    ).catch( 
        err => { 
            //signOut(false);  
        } 
    );  
} 
 
//Helpers 
function signOut(sendToLogin, redirect){ 
    //AHDA 12/04/2019 
    //Added redirect param to login url  
    Auth.signOut().then( 
        data => { 
            ls.clear(); 
            if(sendToLogin){ 
                if(redirect) 
                    window.location.href = `/?redirect=${encodeURIComponent(redirect)}` 
                else 
                    window.location.href = '/' 
            } 
        } 
    ).catch( 
        err => {  
            ls.clear(); 
            if(sendToLogin){ 
                if(redirect) 
                    window.location.href = `/?redirect=${encodeURIComponent(redirect)}` 
                else 
                    window.location.href = '/' 
            } 
        } 
    ); 
} 
 
function signOutPromise(){ 
    return new Promise( 
        function(resolve, reject){ 
            Auth.signOut().then( 
                data => { 
                    resolve(data);  
                } 
            ).catch( 
                err => {  
                    reject(err);  
                } 
            ); 
        } 
    );  
} 
 
//AHDA 30/04/2019 
//Login for ADFS Auth  
export const getCurrentAuthenticated = () => {
    return new Promise(function(resolve, reject){
        //AHDA 14/05/2019
        Auth.currentAuthenticatedUser().then(currentAuth => {
            resolve(currentAuth)
        }).catch(err => {
            reject(err); 
        })
    })
}
 
export const getCurrentUserInfo = () => {
    return new Promise(function(resolve, reject){
        //AHDA 14/05/2019
        Auth.currentUserInfo().then(cuurentUser =>{
            console.log("Current User: ", cuurentUser); 
            resolve(cuurentUser); 
        }).catch(err => {
            console.log("Current User: ", err); 
            reject(err); 
        })
    })
}
 
//AHDA 30/04/2019 
//Method for actions after login 
export const afterADFSLogin = (body) => { 
     
    let decoded = {};  
     
    return new Promise( 
        function(resolve, reject) { 
            //Call to API  
            apiPOST('/user/get', body).then( 
                response => { 
                    if(response.status === 200){ 
                        if(response.data.user.estatus === 0){ 
                            signOutPromise().then().catch().finally( 
                                () => { 
                                    error.message = 'User blocked access'; 
                                    error.status = 404; 
                                    reject(error);  
                                } 
                            ) 
                        } 
                        else{ 
                            //Save session 
                            decoded.rdsUser = response.data.user;  
                            ls.set("user_object", decoded); 
                            ls.set("favorites", response.data.favorites);  
                            ls.set("prospects", response.data.prospects);  
                            
                            //AHDA 16/05/2019: Added valdation for refreshing
                            apiPOST("/roles/getmenuaction", { rolId : decoded.rdsUser.id_rol }, true).then( 
                                response => { 
                                    ls.set("user_permissions", response.data);        
                                    ls.set("last_activity", new Date().getTime()); //AHDA 16/05/2019                                     
                                } 
                            ).catch(err => console.log(err)).finally(() => { 
                                resolve(decoded);     
                            }) 
                        } 
                    }  
                    else { 
                        //AHDA 15/05/2019
                        error.message = response.message; 
                        if(response.message.indexOf('User not allowed in portal') > -1){ 
                            error.status = 404; 
                        } 
                        else 
                            error.status = 500; 
                        reject(error);  
                    } 
                } 
            ).catch( 
                err => { 
                    //AHDA 15/05/2019
                    error.message = err.message;  
                    error.status = 500; 
                    reject(error);  
                } 
            ); 
        } 
    );  
} 