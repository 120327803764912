import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { S3Image } from 'aws-amplify-react';
import { withStyles } from '@material-ui/core/styles';
import styles from './cover-jss';
import fondo from '../../../static/images/material_bg.svg'
import classNames from 'classnames';
//import avatar from '../../../static/images/avatars/girl1.jpg';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
//import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import Info from '@material-ui/icons/Info';
//import MoreVertIcon from '@material-ui/icons/MoreVert';

/*const optionsOpt = [
  'Editar Perfil',
  'Cambiar portada',
];

const ITEM_HEIGHT = 48;*/

class Cover extends React.Component {
  state = {
    anchorElOpt: null,
  };

  handleClickOpt = event => {
    this.setState({ anchorElOpt: event.currentTarget });
  };

  handleCloseOpt = () => {
    this.setState({ anchorElOpt: null });
  };

  render() {
    const {
      classes,
      name,
      desc,
      avatar,
      //coverImg,
    } = this.props;
    //const { anchorElOpt } = this.state;
    return (
      <div className={classes.cover} style={{ backgroundImage: `url(${fondo})` }}>
        {
          /*
          <div className={classes.opt}>
          <IconButton className={classes.button} aria-label="Delete">
            <Info />
          </IconButton>
          <IconButton
            aria-label="More"
            aria-owns={anchorElOpt ? 'long-menu' : null}
            aria-haspopup="true"
            className={classes.button}
            onClick={this.handleClickOpt}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorElOpt}
            open={Boolean(anchorElOpt)}
            onClose={this.handleCloseOpt}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {optionsOpt.map(option => (
              <MenuItem key={option} selected={option === 'Edit Profile'} onClick={this.handleCloseOpt}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div> */
        }
        <div className={classes.content}>
          <Avatar alt={name} src={avatar ? "" : "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png"} className={classNames(classes.avatar, classes.unsetFlex)} >
          {
            avatar ? <S3Image theme={{ photoImg: { width: '120px', height: '120px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={avatar} /> : ""
          }
          </Avatar>
          <Typography variant="display1" className={classes.name} gutterBottom>
            {name}
            <VerifiedUser className={classes.verified} />
          </Typography>
          <Typography className={classes.subheading} gutterBottom>
            {desc}
          </Typography>
          {/* <Button className={classes.button} size="large" variant="contained" color="secondary">
            Add to Connection
          </Button> */}
        </div>
      </div>
    );
  }
}

Cover.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string,
  desc: PropTypes.string,
  coverImg: PropTypes.string,
};

export default withStyles(styles)(Cover);
