import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class SimpleTable extends React.Component {

  render() {
    const {
      dataHead,
      dataRow,
      style
    } = this.props;
    return (
      <Paper elevation={0} style={{overflow: 'auto'}}>
        <Table aria-label="simple table" style={style}>
          <TableHead>
            <TableRow>
              {dataHead.map((head) => (
                <TableCell key={head}>{head}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

SimpleTable.propTypes = {
  dataHead: PropTypes.object.isRequired,
  dataRow: PropTypes.object.isRequired,
  style: PropTypes.object
};

export default SimpleTable;
