import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles-jss'
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//AHDA 21/05/2019: Added search dependencies
//Search 
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { ButtonAction } from '../../atoms'
import { AddCircleOutline, Edit, Save, Sync } from '@material-ui/icons';
import { Input } from '@material-ui/core';
import { apiPOST } from '../../services/service_base';
import ls from 'local-storage';
import { UIContextActions, withUIContext } from '../../context';

class Header extends React.Component {

    state = {
        isSearch: false,
        mSearch: "",
        isEdit: false,
        title: this.props.catalogue ? ls.get("catalogue_list").find(e => e.id_catalogue == this.props.catalogue).cat_name : this.props.title
    };

    //AHDA 21/05/2019
    handleShowSearch = (show) => {
        this.setState({ isSearch: show });

        if (!show) {
            this.props.search("");
            this.setState({ mSearch: "" });
        }
    }

    handleSearch = (event) => {
        this.setState({ mSearch: event.target.value });
        this.props.search(event.target.value);
    }

    handleChange = (event) => {
        this.setState({ title: event.target.value });

    }

    handleEdit = () => {
        this.setState({ isEdit: true })
        UIContextActions.catalogEdit(this.props.uicontext)
    }

    handleSubmit = () => {

        let body = {
            idCatalog: this.props.catalogue,
            menu_name: this.state.title
        }
        this.setState({ isEdit: false })
        UIContextActions.catalogEdit(this.props.uicontext)
        apiPOST('/catalogue/metadata', body).then(
            response => {
                if (response.status === 200) {
                    const list = ls.get("catalogue_list");
                    console.log("list local", list);
                    list.find(e => e.id_catalogue == this.props.catalogue).cat_name = this.state.title;

                    console.log("list after", list);
                    ls.set("catalogue_list", list);
                }
                else {
                    console.log(response);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    render() {

        const { classes, tooltip, openForm, textBtn, canAdd, showSearch, ellipsis, openSync, canSync } = this.props;
        const { isSearch, isEdit, title } = this.state;

        return (
            <Toolbar className={classes.toolbar}>
                {/*CH 04/06/2019: Se agrega ellipsis*/}
                <div className={classNames(classes.title, ellipsis && classes.ellipsis)}>
                    {!isSearch && <>
                        <div className={classes.catalogTitleContainer}>
                            {!isEdit && <>
                                {this.props.catalogue && <>
                                    <ButtonAction color="none" className={classes.catalogTitleIcon} onClick={this.handleEdit}>
                                        <Edit />
                                    </ButtonAction>
                                </>}

                                {title}
                            </>}
                            {isEdit && <FormControl>
                                <Input
                                    // id=""
                                    className={classes.titleField}
                                    type="text"
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Guardar" onClick={this.handleSubmit}>
                                                <Save />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>}
                        </div>
                    </>}
                    {
                        showSearch && isSearch &&
                        <div>
                            {/*CH 21/05/2019: Se aplican estilos al Search*/}
                            {/*AHDA 21/05/2019: Se agrega onChange y value*/}
                            <FormControl>
                                <TextField
                                    label=""
                                    id="searchParam"
                                    name="searchParam"
                                    className={classes.searchWhite}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton aria-label="Search" onClick={() => this.handleShowSearch(false)}>
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    value={this.state.mSearch}
                                    onChange={this.handleSearch}
                                    autoFocus={true}
                                />
                            </FormControl>
                        </div>
                    }
                </div>
                {/* <div className={classes.spacer} /> */}
                <div className={classes.actions}>
                    {/*AHDA 21/05/2019: Added button for search*/}
                    {
                        showSearch &&
                        <Tooltip title="Buscar">
                            <IconButton aria-label="Search" onClick={() => this.handleShowSearch(!isSearch)}>
                                <Search />
                            </IconButton>
                        </Tooltip>
                    }

                    {/* CH 22/04/2019 Se elimina Tooltip */}
                    {/* isanchez 7.05.2019 si no tiene permisos, no muestra el botón */}
                    {
                        canAdd ?
                            <span>
                                <Hidden smUp>
                                    <Tooltip title={tooltip}>
                                        <Button variant="fab" mini disabled={!canAdd} onClick={openForm} color="secondary" className={classes.button}>
                                            <AddCircleOutline className={classes.addBtn} />
                                        </Button>
                                    </Tooltip>
                                </Hidden>
                                <Hidden xsDown>
                                    <Button disabled={!canAdd} variant="contained" onClick={openForm} color="secondary" className={classes.button}>
                                        <AddCircleOutline className={classNames(classes.addBtn, classes.leftIcon, classes.iconSmall)} /> {textBtn}
                                    </Button>
                                </Hidden>
                            </span> : ''

                    }
                    {
                        canSync ?
                        <Tooltip title="Sincronizar dashboard">
                            <Button variant="fab" mini disabled={!canSync} onClick={openSync} color="secondary" className={classes.button}>
                                <Sync className={classes.addBtn} />
                            </Button>
                        </Tooltip>
                        :""
                    }
                </div>
            </Toolbar>
        )
    }
}


Header.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    openForm: PropTypes.func,
    canAdd: PropTypes.bool,
    showSearch: PropTypes.bool, //AHDA 21/05/2019 
    search: PropTypes.func, //AHDA 21/05/2019
    showEditTitleIcon: PropTypes.bool
};

Header.defaultProps = {
    canAdd: true,
    showSearch: true, //AHDA 21/05/2019 
    search: () => { }, //AHDA 21/05/2019 
    showEditTitleIcon: false,
};

export default withUIContext(withStyles(styles)(Header));