import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { red } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    '& [class*="indicatorSeparator"]': {
      display: "none",
    },
    '&:has(input:focus) > [class*="focusedLabel"]': {
      color: theme.palette.primary.dark,
    },
  },
  indicatorContainer: {
    display: "flex",
    alignItems: "center",
    opacity: 0.54,
  },
  indicatorIcon: {
    fontSize: 24,
  },
  selectText: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "11px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    minHeight: "29px",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  noOptionsContent: {
    padding: 0,
    margin: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
    minHeight: "184px",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    textAlign: "center",
  },
  singleValue: {
    fontSize: 16,
  },
  disabledValue: {  
    fontSize: 16,
    color: "rgba(0, 0, 0, 0.54)",
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  focusedLabel: {
    padding: 0,
  },
  errorLabel: {
    color: red["500"],
  },
  paper: {
    position: "absolute",
    zIndex: 9999,
    width: "100%",
    paddingTop: `${theme.spacing.unit * 0.5}px !important`,
    paddingBottom: `${theme.spacing.unit * 0.5}px !important`,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    height: "200px",
    minHeight: "200px",
    color: theme.palette.text.secondary,
    marginBottom: 15,
    "& > div": {
      height: "100%",
    },
    "& div:last-child": {
      marginBottom: 0,
    },
  },
});

function DropdownIndicator(props) {
  return (
    <div className={props.selectProps.classes.indicatorContainer}>
      {props.children}
      <ArrowDropDownIcon className={props.selectProps.classes.indicatorIcon} />
    </div>
  );
}

function NoOptionsMessage(props) {
  return (
    <div className={props.selectProps.classes.noOptionsContent}>
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    </div>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props) {
  return (
    <>
    {props.name == "version_equipo" && <MenuItem value={"-1"}>Otra</MenuItem>}
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
    </>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.isDisabled ? props.selectProps.classes.disabledValue :props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  MultiValue,
  DropdownIndicator,
};

class IntegrationReactSelect extends React.Component {
  constructor(props) {
    super(props);
    let valuesReal = [];
    if (props.isMulti && props.options && props.options.length > 0) {
      valuesReal = props.options.filter((value) =>
        props.value.some((element) => value.value === element)
      );
    }
    this.state = {
      options: props.options || [],
      internalValue: valuesReal || null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options || prevProps.value !== this.props.value) {
      let valuesReal = [];
      if (this.props.isMulti && this.props.options && this.props.options.length > 0) {
        valuesReal = this.props.options.filter((value) =>
          this.props.value.some((element) => value.value === element)
        );
      }
      this.setState({
        options: this.props.options || [],
        internalValue: valuesReal || null,
      });
    }
  }

  computeInternalValue(props) {
    const { isMulti, options, value } = props;

    if (isMulti) {
      return options.filter((option) => value.includes(option.value));
    }

    if (this.props.name != "version_equipo") {
      return options.find(({ value: optValue }) => optValue === value) || null;
    }

    if (this.props.name === "version_equipo" && value == "-1") {
      return options.find(({ value: optValu }) => optValu === value) || null;
    }

    return options.find(({ label }) => label === value) || null;
  }

  handleChange = (e) => {
    if (e !== this.state.internalValue) {
      this.setState({ internalValue: e });
      this.props.onChange(e);
    }
  };
  

  Control = (props) => {
    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
        error={this.props.error}
      />
    );
  };

  render() {
    const { classes, theme, error } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: "black",
        "& input": {
          font: "inherit",
        },
      }),
    };

    return (
      <>
        {this.props.options.length > 0 && (
          <div className={classes.root}>
            <label
              className={classNames(
                classes.selectText,
                classes.focusedLabel,
                error && classes.errorLabel
              )}
              htmlFor={this.props.name}
            >
              {this.props.label}
            </label>
            <NoSsr>
              <Select
                isClearable={this.props.isClearable}
                classes={classes}
                styles={selectStyles}
                options={
                  this.props.options && this.props.options.length > 0
                    ? this.props.options.map((company, idx) => {
                        company.label = company.data || company.label;
                        company.value = company.value;
                        return company;
                      })
                    : []
                }
                components={{ ...components, Control: this.Control }}
                value={this.computeInternalValue(this.props)}
                onChange={
                  this.props.isMulti ? this.handleChange : this.props.onChange
                }
                placeholder={this.props.placeholder}
                isMulti={this.props.isMulti}
                menuPosition="fixed"
                noOptionsMessage={() => "No hay opciones disponibles"}
                isDisabled={this.props.isDisabled}
              />
            </NoSsr>
          </div>
        )}
      </>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntegrationReactSelect);
