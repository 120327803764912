import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
//import { withStyles } from '@material-ui/core/styles';

//Pagination
import Pagination from '../../../../components/Pagination/Pagination';

// notificaciones
import NotifyCard from '../../../../components/CardPaper/NotifyCard'; 

class PaginationNotification extends React.Component {
    constructor(props){
        super(props); 
        
        this.state = {
            page: 1, 
            contentsPerPage: this.props.contentsPerPage
        }
    }

    //Pagination
    onPageChange = (page) => {
        this.setState({ page: page });
    }

    onPrev = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page -= 1 });
        } else {
            this.setState({ page: 1 });
        }
    }
    
    onNext = (totalPages) => {
        if (this.state.page < totalPages) {
            this.setState({ page: this.state.page += 1 });
        } else {
            this.setState({ page: totalPages });
        }
    }
    
    onGoFirst = () => {
        this.setState({ page: 1 });
    }

    onGoLast = (totalPages) => {
        this.setState({ page: totalPages });
    }
    
    render(){
        const { page,  contentsPerPage } = this.state;
        //AHDA 04/04/2019
        //Change of props added handleClickOpen, canDelete, options and canPickScripts
        const { 
            type, 
            content, 
            handleDeleteItem, 
            handleClickOpen, 
            icon, 
            canDelete, 
            options, 
            canPickScripts 
        } = this.props; 
        const indexOfLastWork = page * contentsPerPage;
        const indexOfFirstWork = indexOfLastWork - contentsPerPage;
        const currentContent = content.slice(indexOfFirstWork, indexOfLastWork);
        const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(content.length / contentsPerPage); i += 1) {
            pageNumbers.push(i);
        };
        
        const getMessage = (type, notification) => {
            switch(type){
                case "newWorkRegister":
                    return `Se ha registrado la obra '${notification.titulo_original}' en el portal de ACERVO`;
                case "workUpdate":
                    return `Se ha modificado la información de la obra '${notification.titulo_original}'`;
                case "evaluationAssessment":
                    return `Se ha realizado la carga del dictamen para la obra '${notification.titulo_original}'`;
                case "scriptsAccessRequest":
                    return `El usuario '${notification.productorAcervo}' está solicitando acceso a los libretos de la obra '${notification.titulo_original}'`;
                case "scriptsAccess":
                    return `Se ha autorizado el acceso a los libretos al usuario '${notification.productorAcervo}'  para la obra '${notification.titulo_original}'`;
                case "dueTimeProspect":
                    return `Falta 1 día para finalizar el tiempo de la obra '${notification.titulo_original}'`;
                case "workAssigned":
                    return `Se ha asignado como proyecto la obra '${notification.titulo_original}' al usuario '${notification.productorAcervo}'`;
                case "workProspectUser":
                    return `Se te ha asignado la obra '${notification.titulo_original}' para tu revisión`;
                case "aditionalMaterialRegister":
                return `Se ha cargado material adicional a la obra '${notification.titulo_original}'`;
                case "unlockWorkPropspect":
                return `La obra '${notification.titulo_original}' ha sido liberada por que expiró su tiempo`;
                //AHDA 27/05/2019: Added unlockWorkPropspectUser
                case "unlockWorkPropspectUser":
                return `La obra '${notification.titulo_original}' ha sido liberada por el usuario ${notification.productorAcervo}`;
                default: 
                return '';
            }
        }; 

        const getName = (type) => {
            switch(type){
                case "newWorkRegister":
                    return `Nueva Obra`;
                case "workUpdate":
                    return `Obra actualizada`;
                case "evaluationAssessment":
                    return `Carga de dictamen`;
                case "scriptsAccessRequest":
                    return `Solicitud de Acceso`;
                case "scriptsAccess":
                    return `Acceso a libreto`;
                case "dueTimeProspect":
                    return `Tiempo restante`;
                case "workAssigned":
                    return `Asignación de obra`;
                case "workProspectUser":
                    return `Se te ha asignado una obra para tu revisión`;
                case "aditionalMaterialRegister":
                    return `Material adicional`;
                case "unlockWorkPropspect":
                    return `Obra Liberada`;
                default: 
                return '';
            }
        }; 

        /*getIcon = (type) => {
            switch(type){
                case "newWorkRegister":
                    return <LibraryAdd />;
                case "workEvaluationAssign":
                    return <LocalLibrary />;
                case "additionalMaterialRequest":
                    return <NoteAdd />;
                case "additionalMaterialReception":
                    return <PlaylistAddCheck />;
                case "dueTime":
                    return <Alarm />;
                case "evaluationAssessment":
                    return <Check />;
                case "purchaseAuthorization":
                    return <AttachMoney />;
                default: 
                return '';
            }
        };*/

        return(
            <div>
                {/*AHDA 04/04/2019: Change of props, added canPickScripts and options*/}
                <Grid container spacing={16}>
                    {
                        currentContent.map((notification, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`notification_${type}_${index}`}>
                                <NotifyCard 
                                icon={icon} 
                                deleteItem={handleDeleteItem} 
                                handleClickOpen={handleClickOpen}
                                notification={notification} 
                                canDelete={canDelete}
                                canPickScripts={canPickScripts}
                                content={getMessage(notification.type, notification)} name={getName(type)}
                                options={options} />
                            </Grid> 
                        ))
                    }
                </Grid>

               <Pagination
                    curpage={page}
                    totpages={pageNumbers.length}
                    boundaryPagesRange={1}
                    onChange={(page) => this.onPageChange(page)}
                    siblingPagesRange={1}
                    hideEllipsis={false}
                    onPrev={this.onPrev}
                    onNext={() => this.onNext(pageNumbers.length)}
                    onGoFirst={this.onGoFirst}
                    onGoLast={() => this.onGoLast(pageNumbers.length)}
                />
                
            </div>
           
        )
    }
}

//AHDA 04/04/2019
//Change of props, added options and canPickScripts
PaginationNotification.propTypes = {
    classes: PropTypes.object.isRequired,
    contentsPerPage: PropTypes.number, 
    type: PropTypes.string, 
    content: PropTypes.array, 
    icon: PropTypes.any,
    handleDeleteItem: PropTypes.func.isRequired, 
    handleClickOpen: PropTypes.func.isRequired,
    canDelete:PropTypes.bool, 
    canPickScripts: PropTypes.bool, 
    options: PropTypes.array.isRequired
}

//AHDA 04/04/2019
//Change of props 
PaginationNotification.defaultProps = {
    worcontentks: [], 
    paginate: true, 
    list: false, 
    contentsPerPage: 9, //JCMY 09/07/2019 incidencia 1050
    canDelete: true, 
    canPickScripts: false, 
}
export default PaginationNotification;