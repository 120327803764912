import { Card, CardContent, Checkbox, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, withStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import styles from './nuevaObra-jss'
import PropTypes from 'prop-types'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { LanguageCxt } from '../../language/LanguageContext';

const validities = {
    VALID: "Vigente",
    NOCOPYRIGHTS: "Sin Derechos",
    EXPIRING: "Por Vencer",
    EXPIRED: "Vencida",
    HOLDBACK: "Hold Back",
}

const StepVigencias = ({ classes, updateValues, territories, data, idObra, titleTerritories, canViewVigencia }) => {
    const { lang } = useContext(LanguageCxt);

    const toggle = (panel) => {
        if (canViewVigencia)
            if(data && data.type === panel) {
                updateValues({id_obra: idObra, type: null, date: null, options: {}, comment: "" })
            } else {
                updateValues({id_obra: idObra, type: panel, date: null, options: {}, comment: "" })
            }
    }

    const handleDate = (e) => {
        const temp = data;
        temp.date = new Date(e);
        updateValues(temp)
    }

    const handleOptions = (e, name) => {
        const temp = data;
        if (name === "year") {
            temp.options[name] = new Date(e).getFullYear().toString()
        } else {
            temp.options[e.target.name] = e.target.value;
        }
        updateValues(temp)
    }

    const handleChange = (e) => {
        const temp = data;
        temp.comment = e.target.value;
        updateValues(temp)
    }

    const handleCustomRadio = (e) => {
        const { name, value } = e.target;
        const temp = data;

        const options = {
            pup: false,
            perpetuo: false,
            none: false,
            date: null,
        }
        temp.date= null;

        switch (e.target.value) {
            case "1":
                options.pup = true;
                break;
            case "2":
                options.perpetuo = true;
                break;
            case "3":
                options.none = true;
                break;
            default:
                options.date = temp.date;
                break;
        }
        temp.options = {...options, [name]: value, last_production: temp.options.last_production ? temp.options.last_production : null};
        updateValues(temp)
    }

    return <section className={classes.bodyForm}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ExpansionPanel expanded={(data && data.type) === validities.VALID} onChange={() => toggle(validities.VALID)}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === validities.VALID} disabled={!canViewVigencia} />
                    <Typography className={classes.stepHeadingExpand}>{lang.tabValidity.title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === validities.VALID && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={4} >
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia || data.options.perpetuo || data.options.pup || data.options.none }
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField
                                                {...params}
                                                label={lang.tabValidity.yearMonth}
                                                placeholder=''
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} >
                                <FormLabel id="validity-radio-buttons-group-label">{lang.tabValidity.lastProduction}</FormLabel>
                                <RadioGroup
                                    aria-labelledby="validity-radio-buttons-group-label"
                                    value={data.options.last_production || 'none'}
                                    onChange={handleOptions}
                                    disabled={!canViewVigencia}
                                    name="last_production"
                                    className={classes.alignRadioBtnRow}
                                >
                                    <FormControlLabel value="+10" control={<Radio className={classes.primaryRadio} />} label={lang.tabValidity.plus10Years} />
                                    <FormControlLabel value="-10" control={<Radio className={classes.primaryRadio} />} label={lang.tabValidity.minus10Years} />
                                    <FormControlLabel value="none" control={<Radio className={classes.primaryRadio} />} label={lang.tabValidity.noProduction} />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        aria-labelledby="validity-radio-buttons-group-label"
                                        value={data.options.new_vigence || null}
                                        onChange={handleCustomRadio}
                                        disabled={!canViewVigencia}
                                        name="new_vigence"
                                        className={classes.alignRadioBtn}>
                                            <FormControlLabel value={"1"} control={<Radio className={classes.primaryRadio} />} label={lang.tabValidity.PUP} />
                                            {
                                                data.options.pup 
                                                && <FormControl fullWidth>
                                                    <DatePicker
                                                        id="year"
                                                        name='year'
                                                        keyboard={false}
                                                        openTo="year"
                                                        views={["year"]}
                                                        disableOpenOnEnter
                                                        animateYearScrolling={false}
                                                        value={data.options.year}
                                                        onChange={(e) => handleOptions(e, "year")}
                                                        disabled={!canViewVigencia}
                                                        fullWidth
                                                        TextFieldComponent={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={lang.tabValidity.year}
                                                                placeholder=''
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            }
                                            <FormControlLabel value={"2"} control={<Radio className={classes.primaryRadio} />} label={lang.tabValidity.perpetual} />
                                            <FormControlLabel value={"3"} control={<Radio className={classes.primaryRadio} />} label={"Ninguna"} />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === validities.NOCOPYRIGHTS} onChange={() => toggle(validities.NOCOPYRIGHTS)}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === validities.NOCOPYRIGHTS} disabled={!canViewVigencia} />
                    <Typography className={classes.stepHeadingExpand}>{lang.tabValidity.noCopyright}</Typography>
                </ExpansionPanelSummary>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === validities.EXPIRING} onChange={() => toggle(validities.EXPIRING)}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === validities.EXPIRING} disabled={!canViewVigencia} />
                    <Typography className={classes.stepHeadingExpand}>{lang.tabValidity.expiring}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === validities.EXPIRING && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4} >
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia}
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField
                                                {...params}
                                                label={lang.tabValidity.yearMonth}
                                                placeholder=''
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === validities.EXPIRED} onChange={() => toggle(validities.EXPIRED)}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === validities.EXPIRED} disabled={!canViewVigencia} />
                    <Typography className={classes.stepHeadingExpand}>{lang.tabValidity.expired}</Typography>
                </ExpansionPanelSummary>
            </ExpansionPanel>

            <ExpansionPanel expanded={(data && data.type) === validities.HOLDBACK} onChange={() => toggle(validities.HOLDBACK)}>
                <ExpansionPanelSummary>
                    <Checkbox className={classes.stepHeadingCheckbox} checked={(data && data.type) === validities.HOLDBACK} disabled={!canViewVigencia} />
                    <Typography className={classes.stepHeadingExpand}>{lang.tabValidity.holdback}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {(data && data.type) === validities.HOLDBACK && <div className={classes.root}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <DatePicker
                                        id="date"
                                        name='date'
                                        keyboard={false}
                                        openTo="year"
                                        views={["year", "month"]}
                                        disableOpenOnEnter
                                        animateYearScrolling={false}
                                        value={data.date}
                                        onChange={handleDate}
                                        disabled={!canViewVigencia}
                                        fullWidth
                                        TextFieldComponent={(params) => (
                                            <TextField
                                                {...params}
                                                label={lang.tabValidity.yearMonth}
                                                placeholder=''
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor='hold-back-territorio'>
                                        {titleTerritories}
                                    </InputLabel>
                                    <Select
                                        id="territorio"
                                        name="territorio"
                                        value={data.options.territorio || ""}
                                        onChange={handleOptions}
                                        className={classes.selectEmpty}
                                        displayEmpty
                                        disabled={!canViewVigencia}
                                    >
                                        {
                                            territories.map((title) => (
                                                <MenuItem key={`titles_${title.value}`} value={title.data}> {title.data} </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </MuiPickersUtilsProvider>

        <Card className={classes.comentariosBox}>
            <CardContent>
                <TextField
                    label={lang.tabValidity.comment}
                    fullWidth
                    multiline
                    rows="4"
                    margin="normal"
                    disabled={!canViewVigencia}
                    value={data ? data.comment : ""}
                    onChange={handleChange}
                    className={classes.comentariosInput}
                />
            </CardContent>
        </Card>
    </section>
}

StepVigencias.propTypes = {
    updateValues: PropTypes.func.isRequired
}

export default withStyles(styles)(StepVigencias);