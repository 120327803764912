export const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        zIndex: 1,
        position: 'relative'
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            overflow: 'hidden'
        },
    },
    loginWrap: {
        [theme.breakpoints.up('md')]: {
            width: 860
        },
    },
    welcomeWrap: {
        position: 'relative'
    },
    welcome: {
        background: `rgba(255, 255, 255, 0.6) no-repeat center`,
        position: 'absolute',
        width: '100%',
        height: 'calc(100% + 30px)',
        padding: '20px 50px',
        top: -15,
        boxShadow: theme.shadows[5],
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
    }
});

