import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ProductCard from './../CardPaper/ProductCard';
import Slider from 'react-slick';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  ml: {
    color: theme.palette.primary.main,
    // marginLeft: theme.spacing.unit
  }
});

class RelatedWorks extends React.Component {

  render() {
    const settings = {
      dots: false,
      infinite: false,
      centerMode: false,
      speed: 500,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      autoplay: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      cssEase: 'ease-out',
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 959,
          settings: {
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 620,
          settings: {
            arrows: true,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 400,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const { dataProduct, handleDetailOpen, handleNotifications, screen, type,
      canFavorite,
      canAddProspect,
      canApplyForMaterial,
      canAssignProyect,
      canEndWork,
      canShareMaterial,
      canUnlockWork,
      canViewWorkBlock,
      classes,
      chkProductionGeneralView,
      chkSalesGeneralView,
      productor
    } = this.props;

    return (
      <div className="detalleSlider">
        <Typography variant="headline" className={classes.ml} component="h2" >
          {type} <small>({dataProduct.length})</small>
        </Typography>
        <Slider {...settings}>
          {
            dataProduct.map((product, index) => {
              if (product === undefined) {
                return null;
              }
              return (
                <div>
                    {/*AHDA 03/05/2019: Added canViewProduction, canViewSales*/}
                    <ProductCard
                      list={false}
                      detailOpen={() => handleDetailOpen(product)}
                      product={product}
                      handleNotifications={() => handleNotifications()}
                      screen={screen}
                      canFavorite={canFavorite}
                      canAddProspect={canAddProspect}
                      canApplyForMaterial={canApplyForMaterial}
                      canAssignProyect={canAssignProyect}
                      canEndWork={canEndWork}
                      canShareMaterial={canShareMaterial}
                      canUnlockWork={canUnlockWork}
                      canViewWorkBlock={canViewWorkBlock}
                      canViewProduction={chkProductionGeneralView}
                      canViewSales={chkSalesGeneralView}
                      cardMargin
                      productor={productor}
                    />
                </div>
              );

            })
          }
        </Slider>
      </div>)
  }
}

RelatedWorks.propTypes = {
  classes: PropTypes.object.isRequired,
  dataProduct: PropTypes.array.isRequired,
  handleDetailOpen: PropTypes.func,
  handleNotifications: PropTypes.func,
  canFavorite: PropTypes.bool,
  canAddProspect: PropTypes.bool,
  canApplyForMaterial: PropTypes.bool,
  canAssignProyect: PropTypes.bool,
  canEndWork: PropTypes.bool,
  canShareMaterial: PropTypes.bool,
  canUnlockWork: PropTypes.bool,
  canViewWorkBlock: PropTypes.bool,
  chkProductionGeneralView: PropTypes.bool, //AHDA 03/05/2019
  chkSalesGeneralView: PropTypes.bool //AHDA 03/05/2019
}

RelatedWorks.defaultProps = {
  handleDetailOpen: () => (false),
  handleNotifications: () => (false),
  chkProductionGeneralView: true, //AHDA 03/05/2019
  chkSalesGeneralView: true //AHDA 03/05/2019
}

export default withStyles(styles)(RelatedWorks);