import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import styles from './../reportes-jss'
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

//Filtros
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import enLocale from 'moment/locale/en-au';
import esLocale from 'moment/locale/es';
import Loader from '../../../../components/Loader/Loader';
import Grid from '@material-ui/core/Grid';

//Servicios
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import { textLabelsEs } from '../../../../utils/tableTexts';

import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

import Tooltip from '@material-ui/core/Tooltip';
import { Save } from '@material-ui/icons';
import { InputAdornment } from '@material-ui/core';
import { Input } from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';

const locale = {
  en: enLocale,
  es: esLocale
}

class BitacoraMovimientos extends React.Component {
  state = {
    //filters
    action: 0,
    fechaInicio: new Date(),
    fechaFin: new Date(),
    user: "",
    //Table
    columns: [],
    data: [],
    //Catalogues
    users: [],
    actions: [],
    results: 0,
    isLoading: true
  }

  //Dates
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ user: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ user: changes.inputValue })
    }
  }

  //AHDA 18/02/2019
  handleClearFilters = () => {
    this.setState({
      action: 0,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      user: "",
      isLoading: true
    })

    const request = {
      start_date: 0,
      end_date: 0,
      action: 0,
      user: "0",
      portal: "acervo"
    }

    this.handleFetch(request);
  }

  //Download csv
  handleDownload = (data) => {

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'acervo',
      screen: 'reporteBitacoraMovimientos'
    }
    apiPOST('/audit/add', body);

    //AHDA 09/05/2019
    //Bus, Excel gap
    const dataCsv = [["Fecha", "Usuario", "Acción", "Descripción"]]

    for (const x in data) {
      const object = []
      object.push(data[x])
      //object.unshift(" ");
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Bitacorademovimientos.csv";
    download.click();
  }

  // JCMY 22/01/19
  //Llamada de busqueda
  searchs = () => {

    this.setState({ isLoading: true })
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      action: this.state.action,
      user: this.state.user === "" ? "0" : this.state.user === "TODOS" ? "0" : this.state.user,
      portal: "acervo"
    }

    this.handleFetch(request);
  }

  componentDidMount() {
    //Catalogo usuarios
    const { lang } = this.context;
    const { classes } = this.props;

    this.setState({
      columns: [
        {
          name: "dateFormat",
          label: lang.reports.date,
          options: {
            customBodyRender: value =>
              moment(new Date(value)).format("DD/MM/YYYY hh:mm:ss")
          }
        },
        lang.reports.user,
        lang.reports.action,
        lang.reports.description,
        lang.reports.employee,
        {
          label: lang.reports.observation,
          name: 'observation',
          sort: false,
          options: {
            customBodyRender: (value, tableMeta, updateValue) => <>
              <FormControl>
                <Input
                    className={classes.titleField}
                    placeholder="Observación"
                    type="text"
                    multiline
                    name={"observationField"+ tableMeta.rowData[6]}
                    id={"observationField"+ tableMeta.rowData[6]}
                    rows="2"
                    rowsMax="4"
                    value={tableMeta.rowData[5]?value : ''}
                    onChange={(e) => {
                      this.handleUpdateValue(e, tableMeta);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                          <IconButton aria-label="Guardar" onClick={(e) => {this.handleSaveObservation(value, tableMeta)}}>
                              <Save />
                          </IconButton>
                      </InputAdornment>
                    }
                />
              </FormControl>  
            </>,
          }
        },
        {
          name: "id",
          label: "Id",
          options: {
            display: false
          }
        }
      ]
    })

    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 14 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ users: response.data })
        } else {
          //AHDA 29/04/2019
          //Changed error handling 
          console.log("Error in catalogue users: ", response);
        }
      }).catch(
        error => {
          //AHDA 29/04/2019
          //Changed error handling 
          console.log("Error in catalogue users: ", error);
        });

    //Catalogo acciones
    //AHDA 16/05/2019: Added valdation for refreshing
    apiPOST('/catalogue/get', { idCatalogo: 15 }, true).then(
      response => {
        if (response.status === 200) {
          //AHDA 29/04/2019
          //Order catalogue functionality
          const orderKeys = Object.keys(lang.actionTypesCatalogue);
          let auxData = [];

          if (orderKeys.length > 0) {
            for (let x in orderKeys) {
              response.data.filter(item => {
                if (item.data === orderKeys[x]) {
                  auxData.push(item);
                  return true;
                } else {
                  return false;
                }
              })
            }

          } else {
            auxData = response.data;
          }

          this.setState({ actions: auxData });
        } else {
          //AHDA 29/04/2019
          //Changed error handling 
          console.log("Error in catalogue actions: ", response);
        }
      }).catch(
        error => {
          //AHDA 29/04/2019
          //Changed error handling 
          console.log("Error in catalogue actions: ", error);
        });

    //Carga inicial
    const initDate= moment().format('DD/MM/YYYY')
    const request = {
      start_date:initDate,
      end_date: initDate,
      action: 0,
      user: "0",
      portal: "acervo"
    }

    this.handleFetch(request);
  };

  //AHDA 18/02/2018
  handleFetch = (request) => {
    //Get language context
    const { lang } = this.context;

    apiPOST('/reports/getLog', request).then(
      response => {
        this.setState({ isLoading: true })

        if (response.status === 200) {

          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].date);
            obj.push(response.data[x].user);
            obj.push(lang.actionTypesCatalogue[response.data[x].action] ? lang.actionTypesCatalogue[response.data[x].action] : '-');
            //AHDA 09/05/2019
            //Added validation
            const description = `Pantalla: ${lang.screens[response.data[x].screen] ? lang.screens[response.data[x].screen] : " - "}; Acción: ${lang.actionTypesCatalogue[response.data[x].action] ? lang.actionTypesCatalogue[response.data[x].action] : '-'}; Fecha y hora: ${moment(new Date(response.data[x].date)).format("DD/MM/YYYY hh:mm:ss")}`;
            obj.push(description);
            obj.push(response.data[x].id_empleado);
            obj.push(response.data[x].observation);
            obj.push(response.data[x].id);
            result.push(obj);
          }
          this.setState({ data: result, isLoading: false, results: response.data.length })

        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        }
      );
  }

  handleSaveObservation = (value, meta) => {
    let observation = this.state['observation'+meta.rowData[6]];
    
    const request = {
      idLog: meta.rowData[6],
      observation: observation
    }

    this.setState({ isLoading: true })

    apiPOST('/reports/updatelog', request).then(
      response => {
        if (response.status === 200) {
          this.setState({ isLoading: false })
        } else {
          this.setState({ openError: true, isLoading: false });
        }
      }).catch(
        error => {
          this.setState({ openError: true, isLoading: false });
        }
      );
  }

  handleUpdateValue = (event, meta)=>{
    this.setState({ ['observation'+meta.rowData[6]]: event.target.value });
    let index = this.state.data.findIndex(item => item[6] == meta.rowData[6]);
    let data = this.state.data;
    data[index][5] = event.target.value;
    this.setState({data: data});
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    //AHDA 29/04/2019
    //Changed filterType
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.download} placement="bottom">
                <IconButton aria-label="download" onClick={() => this.handleDownload(this.state.data)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>

      }
    };

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid item xs={6} sm={3} md={3}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <DatePicker
                          keyboard={false}
                          // helperText={lang.reports.compDateEnter}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          minDate={this.state.fechaInicio}
                          disabled={this.state.fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item xs={12} sm={3} md={3} className={classes.positionRelative}>
                      <div className={classes.root}>
                        <Downshift selectedItem={this.state.user} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                            <div className={classes.formControl}>
                              {renderInput({
                                fullWidth: true,
                                classes,
                                helperText: lang.reports.user,
                                InputProps: getInputProps({
                                  placeholder: lang.reports.user,
                                  id: 'user',
                                  name: 'user',
                                }),
                              })}
                              {isOpen ? (
                                <Paper className={classNames(classes.paper, classes.list)} square>
                                  {getSuggestions(inputValue, this.state.users.filter(e=>e.label!=="")).map((suggestion, index) =>
                                    renderSuggestion({
                                      suggestion,
                                      index,
                                      itemProps: getItemProps({ item: suggestion.label }),
                                      highlightedIndex,
                                      selectedItem,
                                    }),
                                  )}
                                </Paper>
                              ) : null}
                            </div>
                          )}
                        </Downshift>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.action} onChange={this.handleChange} id="action" name="action" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.actions.map((action, idx) =>
                            (
                              <MenuItem value={action.value} key={`action${idx}`}>{lang.actionTypesCatalogue[action.data]}</MenuItem>)
                            )
                          }
                        </Select>
                        <FormHelperText>{lang.reports.action}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                    <Grid item xs={12}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.searchs.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}

                <div className={classes.bitacoraMov}>

                  {
                    this.state.isLoading ?
                      <Loader text={"Cargando"} /> :
                      <MUIDataTable 
                        title={`${lang.reports.repMovementLog} (${this.state.results} ${lang.reports.results})`} 
                        data={data} 
                        columns={columns} 
                        options={options} />
                  }
                </div>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>
    );
  }
}

BitacoraMovimientos.contextType = LanguageCxt;

export default withStyles(styles)(BitacoraMovimientos);