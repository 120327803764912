import React from "react";
import langstr from './lang'; 

const LanguageContext = React.createContext({
    lang: langstr
}); 

export const LanguageCxt = LanguageContext; 
export const LanguageProvider = LanguageContext.Provider;
export const LanguageConsumer = LanguageContext.Consumer;  
