import {
    Card,
    CardContent,
    CardHeader,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import GoogleDocsViewer from 'react-google-docs-viewer';
import { getFileTitle } from '../../helpers/general';
import { LanguageCxt } from '../../language/LanguageContext';
import { getFile } from '../../services/storage';
import styles from './product-jss';

const TabEvaluacion = ({ classes, state, data }) => {
    const { lang } = useContext(LanguageCxt);
    const [activePanel, setActivePanel] = useState(null);
    const [url, setUrl] = useState("");

    const toggle = (panel, auxUrl) => {
        if (activePanel === panel) {
            setActivePanel(null);
            return;
        }
        setUrl("")
        setActivePanel(panel);
        getFile(auxUrl, 'public').then(response => {
            console.log(response);
            auxUrl = encodeURIComponent(response);
            setUrl(auxUrl)
        }).catch(err => {
            console.error(err)
        });
    }

    return (
        <Card>
            <CardHeader subheader="Evaluaciones" className={classes.header} />
            <CardContent>
            {data && data.filter(e => e.ruta !== null).map((item, i) => (

                <ExpansionPanel key={i} className={classes.evaluacionPanel} expanded={activePanel === i} onChange={() => toggle(i, item.ruta)}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            className={classes.evaluacionHeading}
                            variant="body1"
                        >
                            {getFileTitle(item.ruta)}
                        </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                        className={classes.evaluacionDetails}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.evaluacionData}
                            >
                                <div className={classes.evaluacionData}>
                                    <Typography variant="body2">
                                        Fecha en la que se realizó el
                                        dictamen
                                    </Typography>
                                    <Typography variant="body1">
                                        {moment(item.fecha_evaluacion).format('L')}
                                    </Typography>
                                </div>

                                <div className={classes.evaluacionData}>
                                    <Typography variant="body2">
                                        Comentarios
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.comentarios}
                                    </Typography>
                                </div>
                            </Grid>

                            {/* <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className={classes.evaluacionData}
                                >
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            className={classes.evaluacionData}
                                        >
                                            <Typography variant="body2">
                                                {lang.playDetail.rating}
                                            </Typography>
                                            <Typography variant="body1">
                                                {detailWork.ev_rating}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            className={classes.evaluacionData}
                                        >
                                            <Typography variant="body2">
                                                {lang.playDetail.schedule}
                                            </Typography>
                                            <Typography variant="body1">
                                                {detailWork.horario != null
                                                    ? detailWork.horario +
                                                      ' hrs'
                                                    : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sm={4}
                                            className={classes.evaluacionData}
                                        >
                                            <Typography variant="body2">
                                                {lang.playDetail.share}
                                            </Typography>
                                            <Typography variant="body1">
                                                {detailWork.ev_share}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            className={classes.evaluacionData}
                                        >
                                            <Typography variant="body2">
                                                {lang.playDetail.comentaries}
                                            </Typography>
                                            <Typography variant="body1">
                                                {detailWork.ev_comments}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid> */}


                            {url !== "" ? <Grid
                                item
                                xs={12}
                                sm={12}
                                className={classes.evaluacionData}
                            >
                                <div
                                    className={classes.evaluacionFrame}
                                >
                                    <GoogleDocsViewer
                                        width="600px"
                                        height="780px"
                                        fileUrl={url}
                                    />
                                </div>
                            </Grid> : null}

                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            ))}
                    </CardContent>
        </Card>
    );
};

TabEvaluacion.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.array,
};

export default withStyles(styles)(TabEvaluacion);
